import './mapContentComponentStyle.css';
import 'mapbox-gl/dist/mapbox-gl.css';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

import * as turf from '@turf/turf'
import { useRef, useEffect } from 'react';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import _ from 'lodash';
import { IonButton, IonCard, IonIcon, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { add, closeCircle, create, funnel, refresh, remove, clipboard } from 'ionicons/icons';
import { ACCESS_CREDENTIALS } from '../../pages/Home';
import { mouseState } from '../../enums/enums';

export const BASE_URL = "https://resilience-secure.ssen.co.uk/";

mapboxgl.accessToken = 'pk.eyJ1Ijoib3BlbmdyaWQiLCJhIjoiY2xhczQ0ZnJxMGN5ejQwb3ZnZ3h5cGdweiJ9.6s4liW7r_H4vj6P-bfJ4tw';

type mapContentProps = {
	setUpdateNotes: any;


	lastBlocks: any
	setLastBlocks: any

	setMarkerClickedLocation: any;
	setpropertyNumbers: any;
	setCustomerNumbers: any;
	selectedArea: any;
	latLong: any;
	setMouseAppearance: any;

	setpropertyNumbersScenario: any;
	setCustomerNumbersScenario: any;

	scenarioDay: any;
	scenarioTime: any;
	scenarioStage: any;

	noteLocationSelection: any;

	noteType: any;
	setEnableButtons: any;

	overlayArray: any
	setOverlayArray: any;


	mapOrSidebarClick: any;
	setmapOrSidebarClick: any;

	setXCoordConfirmed: any;
	setYCoordConfirmed: any;
	setLatLong: any;
	setNoteLocationSelection: any;
	deleteButtonNumber: any;
	lastSelectedArea: any;
	sendScenarioDataFlag: any;
	setSendScenarioDataFlag: any;

	blockColourArray: any;

	markerArray: any;
	setMarkerArray: any;
	markerCancelArray: any;
	setMarkerCancelArray: any;
	resiliencePartner: any;
	noteCancelled: any;
	setNoteCancelled: any
	currentBoundaries: any;
	setCurrentBoundaries: any;
	setOutsideOrInsideBoundry: any;
	setNoteMarkerType: any;
	noteMarkerType: any
	deleteMarker: any;

	setDeleteMarker: any;

	tempMarkerStorage: any;
	setTempMarkerStorage: any;

	lastScenarioTime: any;
	setLastScenarioTime: any;

	boundsZoom: any;
	setBoundsZoom: any;

	setAddNoteAllLocation: any;

	addMarkerToArray: any;
	setAddMarkerToArray: any;

	setBoundariesArray: any;
	boundariesArray: any;
	userDistrictIdArray: any;
	userDistrictNameArray: any;

	noteToHighlight: any;
	setNoteToHighlight: any;

	setModalVisability: any;

	timeAndLevelButtons: any;
	setTimeAndLevelButtons: any

	clearScenario: any;
	setClearScenario: any;

	RLBToggle: any;
	setRLBToggle: any;
	previousRLBToggle: any;
	setPreviousRLBToggle: any

	noteToggle: any;
	setNoteToggle: any;
	previousNoteToggle: any;
	setPreviousNoteToggle: any;

	faultToggle: any;
	setFaultToggle: any;
	previousFaultToggle: any
	setPreviousFaultToggle: any

	initialFlag: any;
	setInitialFlag: any;

	faultMarkerArray: any;
	setFaultMarkerArray: any;

	visibleOrHiddenFilter: any;
	setVisibleOrHiddenFilter: any;

	newNoteVisability: any;
	setNewNoteVisability: any;

	copiedReference: any;
	setCopiedReference: any;

	setFaultReferenceToBeSaved: any;
	faultReferenceToBeSaved: any;

	publicUserNotes: any;
	setPublicUserNotes: any;
	partnersUserNotes: any;
	setPartnersUserNotes: any;
	SSENUserNotes: any;
	setSSENUserNotes: any;

	publicNonUserNotes: any;
	setPublicNonUserNotes: any;
	partnersNonUserNotes: any;
	setPartnersNonUserNotes: any;
	SSENNonUserNotes: any;
	setSSENNonUserNotes: any;

	noteMouseActive: any;
	setNoteMouseActive: any;
	highlightedNote: any;
	setHighlightedNote: any;

	updateMapNotes: any;
	setUpdateMapNotes: any;
	setfaultArrayForSave: any;

	mapTimer: any;
	setmapTimer: any;

	updateNotes: any;

	setRefreshButton: any;
}

const MapContent: React.FC<mapContentProps> = ({ setEnableButtons, setMouseAppearance, setOverlayArray, overlayArray, boundsZoom, setBoundsZoom, noteToHighlight, setNoteToHighlight, userDistrictIdArray, userDistrictNameArray,
	setAddNoteAllLocation, setBoundariesArray, boundariesArray, tempMarkerStorage, setTempMarkerStorage, setAddMarkerToArray, addMarkerToArray, setDeleteMarker, setNoteMarkerType, deleteMarker, setOutsideOrInsideBoundry, currentBoundaries, setCurrentBoundaries, setNoteCancelled, noteCancelled, markerCancelArray,
	setMarkerCancelArray, resiliencePartner, markerArray, setMarkerArray, lastSelectedArea, setpropertyNumbers, setCustomerNumbers, selectedArea, noteLocationSelection, latLong, setLatLong, setNoteLocationSelection, setXCoordConfirmed, setYCoordConfirmed, setMarkerClickedLocation, setModalVisability,
	setpropertyNumbersScenario, setCustomerNumbersScenario, scenarioDay, scenarioTime, scenarioStage, sendScenarioDataFlag, setSendScenarioDataFlag, noteMarkerType, mapOrSidebarClick, setmapOrSidebarClick, lastBlocks, setLastBlocks, lastScenarioTime, setLastScenarioTime, blockColourArray, timeAndLevelButtons, setTimeAndLevelButtons, clearScenario,
	setClearScenario, RLBToggle, setRLBToggle, noteToggle, setNoteToggle, faultToggle, setFaultToggle, previousNoteToggle, setPreviousNoteToggle, previousRLBToggle, setPreviousRLBToggle, previousFaultToggle, setPreviousFaultToggle, initialFlag, setInitialFlag, faultMarkerArray, setFaultMarkerArray, visibleOrHiddenFilter, setVisibleOrHiddenFilter,
	newNoteVisability, setNewNoteVisability, copiedReference, setCopiedReference, setFaultReferenceToBeSaved, faultReferenceToBeSaved,
	publicUserNotes,
	setPublicUserNotes,
	partnersUserNotes,
	setPartnersUserNotes, setHighlightedNote, highlightedNote, mapTimer, setmapTimer,
	SSENUserNotes,
	setSSENUserNotes,

	publicNonUserNotes,
	setPublicNonUserNotes,
	partnersNonUserNotes,
	setPartnersNonUserNotes,
	SSENNonUserNotes,
	setSSENNonUserNotes,

	noteMouseActive,
	setNoteMouseActive,
	updateMapNotes,
	setUpdateMapNotes,
	setfaultArrayForSave,
	updateNotes,
	setRefreshButton,
	setUpdateNotes

}) => {
	const mapboxContainer = useRef<any>(undefined);
	const map = useRef<any>(undefined);
	let mapMarkers: any[] = [];
	let faultMarkers: any[] = [];
	let mapCancelMarkers: any[] = [];
	let key_Container: any;
	let liveFaultsPath = "https://ssen-powertrack-api.opcld.com/gridiview/reporter/info/livefaults"



	useEffect(() => {
		setLastBlocks([])
		setRLBToggle("OFF");
		setNoteToggle("OFF");
		setFaultToggle("OFF");

		setVisibleOrHiddenFilter(1);
		map.current = new mapboxgl.Map({
			container: 'mapbox-Container',
			style: 'mapbox://styles/opengrid/cl3n12p66002l15nyllbssdhr',
			center: [-2.6743, 54.8235],
			zoom: 5,
			minZoom: 4,
			transformRequest: (url: String, resourceType: any) => {
				if (resourceType === 'Source' && url.startsWith(BASE_URL)) {
					return {
						url: url,
						headers: { 'Authorization': ACCESS_CREDENTIALS.basicAuthentication }
					}
				}

			}
		});

		map.current.dragRotate.disable();
		map.current.touchZoomRotate.disableRotation();

		const searchBar = new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl: mapboxgl,
			marker: false,
			placeholder: 'Search for your location',
			bbox: [-9, 50, 2, 61],
			proximity: {
				longitude: -1.27,
				latitude: 51.75
			},

			reverseGeocode: false  // for 'coordinates -> address' mappings
		});
		map.current.addControl(searchBar);

		var lastSearchTarget: any;

		searchBar.on('result', (e: any) => {
			lastSearchTarget = e.result.center;
			map.current.flyTo({
				zoom: 17,
				duration: 3000,
				center: e.result.center,
			})

		});


		const resizer = new ResizeObserver(_.debounce(() => map.current.resize(), 25));
		resizer.observe(mapboxContainer.current);

		return (() => {
			resizer.disconnect();
		});


	}, []);














	function filterClick(visibleOrHidden: number) {
		if (visibleOrHidden === 0) {
			filter_Container = document.getElementById('filter-Container');
			filter_Container.style.display = 'flex'
			filter_Button = document.getElementById('filter-Button')
			filter_Button.style.display = 'none'
			setVisibleOrHiddenFilter(0);
		} else if (visibleOrHidden === 1) {
			filter_Container = document.getElementById('filter-Container');
			filter_Container.style.display = 'none'
			filter_Button = document.getElementById('filter-Button')
			filter_Button.style.display = 'flex'
			setVisibleOrHiddenFilter(1);
		}

	}





	useEffect(() => {
		if (updateNotes === "Update") {
			updateMap(markerArray)
		}
	}, [updateNotes])





	useEffect(() => {
		if (noteToggle === "ON") {
			setPreviousNoteToggle("ON")
		} else if (noteToggle === "OFF") {
			if (previousNoteToggle === "ON") {
				markerArray.forEach((marker: { remove: () => any; }) => marker.remove())
				mapMarkers = []

			}
			setPreviousNoteToggle("OFF")
		}
	}, [noteToggle])



	useEffect(() => {
		if (faultToggle === "ON") {
			setPreviousFaultToggle("ON")
			//Show faults
			setUpdateNotes("Update");
			let currTime = new Date().getTime();
			let endpoint = "";
			endpoint = 'https://ssen-powertrack-content.opcld.com';
			let networks = ["com.sse.ssepd.sepd", "com.sse.ssepd.shepd"]
			let outageTypes = ["unplanned", "planned", "onsite", "resolved"]

			networks.forEach(network => {
				let netId = network.replace(/\./g, '-');
				outageTypes.forEach(type => {
					if (map.current.getSource('powertrack-faults-raster-' + netId + '-' + type)) {
						//map.removeSource('powertrack-faults-raster-' + netId + '-' + type);
						map.current.getSource('powertrack-faults-raster-' + netId + '-' + type).tiles = [endpoint + '/gridiview/reporter/info/faultareas/' + network + '/' + type + '/{z}/{x}/{y}.png?ts=' + currTime];
					}
					else {
						map.current.addSource('powertrack-faults-raster-' + netId + '-' + type, {
							type: 'raster',
							tiles: [endpoint + '/gridiview/reporter/info/faultareas/' + network + '/' + type + '/{z}/{x}/{y}.png?ts=' + currTime],
							tileSize: 512,
							maxzoom: 7,
							minzoom: 4
						});
					}

					if (!map.current.getLayer('powertrack-faults-areas-raster-' + netId + '-' + type)) {
						map.current.addLayer({
							'id': 'powertrack-faults-areas-raster-' + netId + '-' + type,
							'type': 'raster',
							'source': 'powertrack-faults-raster-' + netId + '-' + type,
							'minzoom': 0,
							'maxzoom': 8
						});
					}
				});
			});
			if (!map.current.getSource('powertrack-faults-vector')) {
				//     map.removeSource('powertrack-faults-vector');
				//     // console.log("firing vector source")
				//     //map.getSource('powertrack-faults-vector').tiles = [endpoint + '/gridiview/reporter/info/faultareas/{z}/{x}/{y}.pbf?ts=' + currTime];
				// }
				// else {
				map.current.addSource('powertrack-faults-vector', {
					type: 'vector',
					tiles: [endpoint + '/gridiview/reporter/info/faultareas/{z}/{x}/{y}.pbf?ts=' + currTime],
					tileSize: 512,
					maxzoom: 8,
					minzoom: 8
				});
			}

			networks.forEach(network => {
				let netId = network.replace(/\./g, '-');
				outageTypes.forEach(type => {

					let filter = "";
					let paintOptions = {};

					switch (type) {
						case "unplanned":
							paintOptions = {
								'fill-color': [
									'match',
									['concat',
										['get', 'network'],
										'-',
										['get', 'classification']
									],
									network + '-' + 'pending',
									'#ea4c42', // This means a fault but minimal reports
									network + '-' + 'min',
									'#f10001', // This means a fault with more reports
									network + '-' + 'low',
									'#df0001', // Additional reports
									network + '-' + 'med',
									'#b00002', // Very many
									network + '-' + 'high',
									'#8a0000', // Mass uptake!
									'#000000' // Default
								],
								'fill-opacity': [
									'match',
									['concat',
										['get', 'network'],
										'-',
										['get', 'classification']
									],
									network + '-' + 'pending',
									0.5, // This means a fault but minimal reports
									network + '-' + 'min',
									0.5, // This means a fault with more reports
									network + '-' + 'low',
									0.5, // Additional reports
									network + '-' + 'med',
									0.5, // Very many
									network + '-' + 'high',
									0.5, // Mass uptake!
									0 // Default
								],
							}
							break;
						case "planned":
							filter = network + '-' + 'planned';
							paintOptions = {
								'fill-color': [
									'match',
									['concat',
										['get', 'network'],
										'-',
										['get', 'classification']
									],
									filter,
									'#428aea', //  This means a planned outage
									'#000000' // Default
								],
								'fill-opacity': [
									'match',
									['concat',
										['get', 'network'],
										'-',
										['get', 'classification']
									],
									filter,
									0.5, //  This means a fault with staff on-site
									0 // Default
								],
							}
							break;
						case "onsite":
							filter = network + '-' + 'onsite';
							paintOptions = {
								'fill-color': [
									'match',
									['concat',
										['get', 'network'],
										'-',
										['get', 'classification']
									],
									filter,
									'#A677A6', //  This means a fault with staff on-site
									'#000000' // Default                                   
								],
								'fill-opacity': [
									'match',
									['concat',
										['get', 'network'],
										'-',
										['get', 'classification']
									],
									filter,
									0.5, //  This means a fault with staff on-site
									0 // Default
								],
							}
							break;
						default:
							paintOptions = {
								'fill-color': '#000000',
								'fill-opacity': 0,
							}
							break;
					}

					if (!map.current.getLayer('powertrack-faults-areas-vector-' + netId + '-' + type)) {
						map.current.addLayer({
							'id': 'powertrack-faults-areas-vector-' + netId + '-' + type,
							'type': 'fill',
							'source': 'powertrack-faults-vector',
							'source-layer': "postcodes",
							'paint': paintOptions
						});
					}
				});
			});




			fetch(liveFaultsPath, {})
				.then(function (response) { return response.json(); })
				.then(function (json) {

					for (let i = 0; i < json.Faults.length; i++) {

						const popup = new mapboxgl.Popup({ offset: 25, closeOnClick: true })
							.setHTML(
								`
						<h3>Fault Information</h3>
						
					
						<ion-card class='note-Card-Reference' onclick='window.open(&quot;https://powertrack.ssen.co.uk/powertrack#&quot; + &quot;${json.Faults[i].reference}&quot ,&quot;_blank&quot;)'>
						<h4>Reference</h4> <h4> - </h4> <h4>${json.Faults[i].reference}</h4>
						</ion-card>
						
						
						

						<ion-card class='note-Card-Fault'>
						<h4>Postcode Area</h4> <h4> - </h4> <h4>${json.Faults[i].name}</h4>
						</ion-card>

						<ion-card class='note-Card-Fault'>
						<h4>Estimated Customers Affected</h4> <h4> - </h4> <h4> ${json.Faults[i].affectedCustomerCount} </h4> 
						</ion-card>

						<ion-card class='note-Card-Fault'>
						<h4>Affected Postcodes</h4>  <h4> - </h4>  <h4> ${json.Faults[i].affectedAreas.length} </h4> 
						</ion-card>
						`
							)


						const el = document.createElement('div');
						const myArray = json.Faults[i].type;

						if (typeof json.Faults[i].estimatedArrivalOnSiteTime !== 'undefined') {

							const date = json.Faults[i].estimatedArrivalOnSiteTime.split("T");

							let time: any;
							let dateTimeTemp = date[1];

							if (dateTimeTemp.includes("+")) {
								time = dateTimeTemp.split("+")

							} else if (dateTimeTemp.includes("-")) {
								time = dateTimeTemp.split("-")
							}


							const YMD = date[0].split("-")
							const HMS = time[0].split(":")

							let dateTest: any;
							dateTest = new Date();

							let dText: any;
							dText = new Date(Number(YMD[0]), (Number(YMD[1]) - 1), Number(YMD[2]), Number(HMS[0]), Number(HMS[1]), Number(HMS[2]));


							if (dText.getTime() < dateTest.getTime()) {
								el.className = 'engineerOnSite';
							} else if (typeof json.Faults[i].engineerOnSiteTime !== 'undefined') {
								el.className = 'engineerOnSite';
							} else {
								if (json.Faults[i].type == "PSI") {
									el.className = 'plannedFaultMarker';
								} else if (json.Faults[i].type == "HV" || json.Faults[i].type == "LV") {
									el.className = 'unplannedFaultMarker';
								}
							}
						} else if (typeof json.Faults[i].engineerOnSiteTime !== 'undefined') {
							el.className = 'engineerOnSite';
						} else {
							if (json.Faults[i].type == "PSI") {
								el.className = 'plannedFaultMarker';
							} else if (json.Faults[i].type == "HV" || json.Faults[i].type == "LV") {
								el.className = 'unplannedFaultMarker';
							}
						}



						let lngLat: any;

						let faultMarkerVar: any;
						lngLat = [String(json.Faults[i].longitude), String(json.Faults[i].latitude)];
						faultMarkerVar = new mapboxgl.Marker({
							element: el,
							anchor: 'bottom'
						})
							.setLngLat(lngLat)
							.addTo(map.current)
							.setPopup(popup);
						faultMarkers.push(faultMarkerVar);
					}
					setFaultMarkerArray(faultMarkers);
				})


		} else if (faultToggle === "OFF") {
			if (previousFaultToggle === "ON") {
				//Get rid of faults
				let networks = ["com.sse.ssepd.sepd", "com.sse.ssepd.shepd"]
				let outageTypes = ["unplanned", "planned", "onsite", "resolved"]

				faultMarkerArray.forEach((marker: { remove: () => any; }) => marker.remove())

				networks.forEach(network => {
					let netId = network.replace(/\./g, '-');
					outageTypes.forEach(type => {
						map.current.removeLayer('powertrack-faults-areas-raster-' + netId + '-' + type);
						map.current.removeLayer('powertrack-faults-areas-vector-' + netId + '-' + type);
					})
				})
				networks.forEach(network => {
					let netId = network.replace(/\./g, '-');
					outageTypes.forEach(type => {
						map.current.removeSource('powertrack-faults-raster-' + netId + '-' + type);

					})
				})
				map.current.removeSource('powertrack-faults-vector');

			}
			setPreviousFaultToggle("OFF");
			setUpdateNotes("Update");
		}
	}, [faultToggle])


	useEffect(() => {
		if (RLBToggle === "ON") {
			setPreviousRLBToggle("ON")
			if (faultToggle === "ON") {
				let networks = ["com.sse.ssepd.sepd", "com.sse.ssepd.shepd"]
				let outageTypes = ["unplanned", "planned", "onsite", "resolved"]

				//	faultMarkerArray.forEach((marker: { remove: () => any; }) => marker.remove())

				networks.forEach(network => {
					let netId = network.replace(/\./g, '-');
					outageTypes.forEach(type => {
						map.current.removeLayer('powertrack-faults-areas-raster-' + netId + '-' + type);
						map.current.removeLayer('powertrack-faults-areas-vector-' + netId + '-' + type);
					})
				})
				networks.forEach(network => {
					let netId = network.replace(/\./g, '-');
					outageTypes.forEach(type => {
						map.current.removeSource('powertrack-faults-raster-' + netId + '-' + type);

					})
				})
				map.current.removeSource('powertrack-faults-vector');
			}

			key_Container = document.getElementById('key-Container');
			key_Container.style.display = "block";

			let networks = ["ssen-scotland", "ssen-southern"];
			let colours: any = {};
			let darkColours: any = {};
			let stops: Array<Array<string>> = [];
			let darkStops: Array<Array<string>> = [];
			let colorProps = { property: 'block', type: "categorical", stops: stops };
			let path2: any;
			path2 = BASE_URL;

			let blockSet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'];
			// let networks = ["murphypowerdistribution", "ssen-scotland","enw"];

			let blocks = blockSet.length;
			for (let i = 0; i < blocks; i++) {
				let block: string = blockSet[i];
				let hsl = "hsl(" + (256 * i / blocks).toFixed(0) + ",50%,50%)";
				let dhsl = "hsl(" + (256 * i / blocks).toFixed(0) + ",75%,25%)";
				colours[block] = hsl;
				darkColours[block] = dhsl;
				stops.push([block, hsl]);
				darkStops.push([block, dhsl]);
			}
			// Resize map to fit window


			var dno: string;

			for (let i = 0; i < networks.length; i++) {
				dno = networks[i];

				const loc = window.location;
				let path;
				if (loc.hostname == "localhost" || loc.hostname == "127.0.0.1") {
					// This needs to explicitly point to S3
					path = "https://ssen-myrotablock-test.opcld.com";
				} else {
					// Going to assume that it's always on default port for http/https as it should be in production
					path = loc.protocol + "//" + loc.hostname;
				}
				path += "/partners/tiles/vector/";
				map.current.addSource(dno + '-tiles', {
					'type': 'vector',
					'tiles': [
						path + dno + '/{z}/{x}/{y}.pbf'
					],
					'minzoom': 4,
					'maxzoom': 12
				});

				let areaPaint: any = {
					'fill-color': colorProps,
					'fill-opacity': [
						'interpolate',
						// Set the exponential rate of change to 0.5
						['exponential', 0.5],
						['zoom'],
						// When zoom is 10, buildings will be 100% transparent.
						9,
						0.95,
						// When zoom is 18 or higher, buildings will be 100% opaque.
						17,
						0.25
					]
				};

				map.current.addLayer(
					{
						'id': dno + '-vector-tiles', // Layer ID
						'type': 'fill',
						'source': dno + '-tiles', // ID of the tile source created above
						// Source has several layers. We visualize the one with name 'sequence'.
						'source-layer': 'rld-block',
						'layout': {},
						'paint': areaPaint
					}
				);
			}
			if (selectedArea === "All") {
				for (let i = 0; i < userDistrictIdArray.length; i++) {
					map.current.removeLayer('Layer-' + i);
					map.current.removeLayer('outline' + i);
					map.current.removeSource(userDistrictIdArray[i] + '-ID');

				}
			} else {
				map.current.removeLayer('Layer-' + selectedArea);
				map.current.removeLayer('outline');
				map.current.removeSource(selectedArea + '-ID');
			}
			if (selectedArea == 'All') {
				for (let i = 0; i < userDistrictIdArray.length; i++) {

					map.current.addSource(userDistrictIdArray[i] + '-ID', {
						'type': 'geojson',
						'data': BASE_URL + "/gridim/rotaload/partners/overlays?districts=" + userDistrictIdArray[i]
					});

					let areaPaint: any = {
						'fill-color': 'black',
						'fill-opacity': [
							'interpolate',
							['exponential', 0.5],
							['zoom'],
							7,
							0.25,
							17,
							0.25
						]
					};

					map.current.addLayer(
						{
							'id': 'Layer-' + i,
							'type': 'fill',
							'source': userDistrictIdArray[i] + '-ID',
							'layout': {},
							'paint': areaPaint
						}
					);

					map.current.addLayer({
						'id': 'outline' + i,
						'type': 'line',
						'source': userDistrictIdArray[i] + '-ID',
						'layout': {},
						'paint': {
							'line-color': 'white',
							'line-width': 2
						}
					});


				}
			} else {
				path2 += "/gridim/rotaload/partners/overlays?districts=" + selectedArea;
				map.current.addSource(selectedArea + '-ID', {
					'type': 'geojson',
					'data': path2
				});

				let areaPaint: any = {
					'fill-color': 'black',
					'fill-opacity': [
						'interpolate',
						['exponential', 0.5],
						['zoom'],
						7,
						0.25,
						17,
						0.25
					]
				};

				map.current.addLayer(
					{
						'id': 'Layer-' + selectedArea,
						'type': 'fill',
						'source': selectedArea + '-ID',
						'layout': {},
						'paint': areaPaint
					}
				);

				map.current.addLayer({
					'id': 'outline',
					'type': 'line',
					'source': selectedArea + '-ID',
					'layout': {},
					'paint': {
						'line-color': 'white',
						'line-width': 2
					}
				});
			}
			map.current.on('render', afterChangeComplete); // warning: this fires many times per second!
			let headers = new Headers();
			headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
			let tempBoundariesArray: any;
			tempBoundariesArray = [];
			if (selectedArea !== "All") {
				fetch(path2, { headers: headers })
					.then(function (response) { return response.json(); })
					.then(function (json) {
						const coordinates = json.features[0].geometry.coordinates;
						setOverlayArray(coordinates);

						let featureType = json.features[0].geometry.type.toLowerCase();

						if (featureType == "polygon") {


							var polygon = turf.polygon([coordinates[0]]);

							setCurrentBoundaries(polygon);
						} else if (featureType == "multipolygon") {

							for (let i = 0; i < coordinates.length; i++) {
								for (let j = 0; j < coordinates[i][0].length; j++) {
									llb.extend(coordinates[i][0][j]);
								}
							}
							map.current.fitBounds(llb);
							var multipolygon = turf.multiPolygon([coordinates]);
							setCurrentBoundaries(multipolygon);
						}


					})
			} else {

				const fetchData = async () => {
					for (let i = 0; i < userDistrictIdArray.length; i++) {
						let pathTest: any;
						pathTest = BASE_URL + "/gridim/rotaload/partners/overlays?districts=" + userDistrictIdArray[i];
						const response = await fetch(pathTest, { headers: headers }) as any;
						const json = await response.json();
						const coordinates = json.features[0].geometry.coordinates;
						let featureType = json.features[0].geometry.type.toLowerCase();

						if (featureType == "polygon") {


							var polygon = turf.polygon([coordinates[0]]);

							tempBoundariesArray.push(polygon);

						} else if (featureType == "multipolygon") {
							for (let i = 0; i < coordinates.length; i++) {
								for (let j = 0; j < coordinates[i][0].length; j++) {
									llb.extend(coordinates[i][0][j]);
								}
							}
							map.current.fitBounds(llb);

							var multipolygon = turf.multiPolygon([coordinates]);
							tempBoundariesArray.push(multipolygon);
						}

						if (i == (userDistrictIdArray.length - 1)) {
							setBoundariesArray(tempBoundariesArray);
						}
					}
				}

				fetchData();
			}
			if (faultToggle === "ON") {
				let currTime = new Date().getTime();
				let endpoint = "";
				endpoint = 'https://ssen-powertrack-content.opcld.com';
				let networks = ["com.sse.ssepd.sepd", "com.sse.ssepd.shepd"]
				let outageTypes = ["unplanned", "planned", "onsite", "resolved"]

				networks.forEach(network => {
					let netId = network.replace(/\./g, '-');
					outageTypes.forEach(type => {
						if (map.current.getSource('powertrack-faults-raster-' + netId + '-' + type)) {
							//map.removeSource('powertrack-faults-raster-' + netId + '-' + type);
							map.current.getSource('powertrack-faults-raster-' + netId + '-' + type).tiles = [endpoint + '/gridiview/reporter/info/faultareas/' + network + '/' + type + '/{z}/{x}/{y}.png?ts=' + currTime];
						}
						else {
							map.current.addSource('powertrack-faults-raster-' + netId + '-' + type, {
								type: 'raster',
								tiles: [endpoint + '/gridiview/reporter/info/faultareas/' + network + '/' + type + '/{z}/{x}/{y}.png?ts=' + currTime],
								tileSize: 512,
								maxzoom: 7,
								minzoom: 4
							});
						}

						if (!map.current.getLayer('powertrack-faults-areas-raster-' + netId + '-' + type)) {
							map.current.addLayer({
								'id': 'powertrack-faults-areas-raster-' + netId + '-' + type,
								'type': 'raster',
								'source': 'powertrack-faults-raster-' + netId + '-' + type,
								'minzoom': 0,
								'maxzoom': 8
							});
						}
					});
				});
				if (!map.current.getSource('powertrack-faults-vector')) {
					//     map.removeSource('powertrack-faults-vector');
					//     // console.log("firing vector source")
					//     //map.getSource('powertrack-faults-vector').tiles = [endpoint + '/gridiview/reporter/info/faultareas/{z}/{x}/{y}.pbf?ts=' + currTime];
					// }
					// else {
					map.current.addSource('powertrack-faults-vector', {
						type: 'vector',
						tiles: [endpoint + '/gridiview/reporter/info/faultareas/{z}/{x}/{y}.pbf?ts=' + currTime],
						tileSize: 512,
						maxzoom: 8,
						minzoom: 8
					});
				}

				networks.forEach(network => {
					let netId = network.replace(/\./g, '-');
					outageTypes.forEach(type => {

						let filter = "";
						let paintOptions = {};

						switch (type) {
							case "unplanned":
								paintOptions = {
									'fill-color': [
										'match',
										['concat',
											['get', 'network'],
											'-',
											['get', 'classification']
										],
										network + '-' + 'pending',
										'#ea4c42', // This means a fault but minimal reports
										network + '-' + 'min',
										'#f10001', // This means a fault with more reports
										network + '-' + 'low',
										'#df0001', // Additional reports
										network + '-' + 'med',
										'#b00002', // Very many
										network + '-' + 'high',
										'#8a0000', // Mass uptake!
										'#000000' // Default
									],
									'fill-opacity': [
										'match',
										['concat',
											['get', 'network'],
											'-',
											['get', 'classification']
										],
										network + '-' + 'pending',
										0.5, // This means a fault but minimal reports
										network + '-' + 'min',
										0.5, // This means a fault with more reports
										network + '-' + 'low',
										0.5, // Additional reports
										network + '-' + 'med',
										0.5, // Very many
										network + '-' + 'high',
										0.5, // Mass uptake!
										0 // Default
									],
								}
								break;
							case "planned":
								filter = network + '-' + 'planned';
								paintOptions = {
									'fill-color': [
										'match',
										['concat',
											['get', 'network'],
											'-',
											['get', 'classification']
										],
										filter,
										'#428aea', //  This means a planned outage
										'#000000' // Default
									],
									'fill-opacity': [
										'match',
										['concat',
											['get', 'network'],
											'-',
											['get', 'classification']
										],
										filter,
										0.5, //  This means a fault with staff on-site
										0 // Default
									],
								}
								break;
							case "onsite":
								filter = network + '-' + 'onsite';
								paintOptions = {
									'fill-color': [
										'match',
										['concat',
											['get', 'network'],
											'-',
											['get', 'classification']
										],
										filter,
										'#A677A6', //  This means a fault with staff on-site
										'#000000' // Default                                   
									],
									'fill-opacity': [
										'match',
										['concat',
											['get', 'network'],
											'-',
											['get', 'classification']
										],
										filter,
										0.5, //  This means a fault with staff on-site
										0 // Default
									],
								}
								break;
							default:
								paintOptions = {
									'fill-color': '#000000',
									'fill-opacity': 0,
								}
								break;
						}

						if (!map.current.getLayer('powertrack-faults-areas-vector-' + netId + '-' + type)) {
							map.current.addLayer({
								'id': 'powertrack-faults-areas-vector-' + netId + '-' + type,
								'type': 'fill',
								'source': 'powertrack-faults-vector',
								'source-layer': "postcodes",
								'paint': paintOptions
							});
						}
					});
				});




				fetch(path2, {})
					.then(function (response) { return response.json(); })
					.then(function (json) {

						for (let i = 0; i < json.Faults.length; i++) {

							const popup = new mapboxgl.Popup({ offset: 25, closeOnClick: true })
								.setHTML(
									`
								<h3>Fault Information</h3>
						<div>
								<ion-card class='note-Card-Reference' onclick='window.open(&quot;https://powertrack.ssen.co.uk/powertrack#&quot; + &quot;${json.Faults[i].reference}&quot ,&quot;_blank&quot;)'>
									<h4>Reference</h4> <h4> - </h4> <h4>${json.Faults[i].reference}</h4>
								</ion-card>

								</div>

								<ion-card class='note-Card-Fault'>
									<h4>Postcode Area</h4> <h4> - </h4> <h4>${json.Faults[i].name}</h4>
								</ion-card>

								<ion-card class='note-Card-Fault'>
									<h4>Estimated Customers Affected</h4> <h4> - </h4> <h4> ${json.Faults[i].affectedCustomerCount} </h4> 
								</ion-card>

								<ion-card class='note-Card-Fault'>
									<h4>Affected Postcodes</h4>  <h4> - </h4>  <h4> ${json.Faults[i].affectedAreas.length} </h4> 
								</ion-card>
		
								
						`
								)


							const el = document.createElement('div');
							el.className = 'faultMarker';
							let lngLat: any;
							let faultMarkerVar: any;
							lngLat = [String(json.Faults[i].longitude), String(json.Faults[i].latitude)];
							faultMarkerVar = new mapboxgl.Marker({
								element: el,
								anchor: 'bottom'
							})
								.setLngLat(lngLat)
								.addTo(map.current)
								.setPopup(popup);
							faultMarkers.push(faultMarkerVar);
						}
						setFaultMarkerArray(faultMarkers);
					})

			}
		} else if (RLBToggle === "OFF") {
			if (previousRLBToggle === "ON") {
				let networks = ["ssen-scotland", "ssen-southern"];
				let dnoTest: any;
				for (let i = 0; i < networks.length; i++) {
					dnoTest = networks[i];
					map.current.removeLayer(dnoTest + '-vector-tiles');
					map.current.removeSource(dnoTest + '-tiles');
				}
			}
			key_Container = document.getElementById('key-Container');
			key_Container.style.display = "none";
			setPreviousRLBToggle("OFF")
		}
	}, [RLBToggle])

























	useEffect(() => {
		if (scenarioStage !== 0 && scenarioDay !== 0 && scenarioTime !== 0 && sendScenarioDataFlag !== 0) {
			let headers = new Headers();
			headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
			let pathForScenario: any;
			pathForScenario = BASE_URL;
			if (selectedArea !== "All") {
				pathForScenario = pathForScenario + "/gridim/rotaload/partners?action=getScenarioCustomerData&district=" + selectedArea + "&stage=" + scenarioStage.replace(/\s/g, '') + "&day=" + scenarioDay.replace(/\s/g, '') + "&time=" + scenarioTime.replace(/\s/g, '');
				setLastScenarioTime(scenarioTime);
				fetch(pathForScenario, { headers: headers })
					.then(function (response) { return response.json(); })
					.then(function (json) {
						let psrCustomers: any;
						let properties: any;
						setTimeAndLevelButtons("Disable");

						if (json.psrCustomers.toString().length < 4) {
							psrCustomers = json.psrCustomers.toString();
						} else if (json.psrCustomers.toString().length === 4) {
							const result = json.psrCustomers.toString().match(/.{1,1}/g) || [];
							psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
						} else if (json.psrCustomers.toString().length === 5) {
							const result = json.psrCustomers.toString().match(/.{1,2}/g) || [];
							psrCustomers = result[0] + ',' + result[1] + result[2];
						} else if (json.psrCustomers.toString().length === 6) {
							const result = json.psrCustomers.toString().match(/.{1,3}/g) || [];
							psrCustomers = result[0] + ',' + result[1];
						} else if (json.psrCustomers.toString().length === 7) {
							const result = json.psrCustomers.toString().match(/.{1,1}/g) || [];
							psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
						}

						if (json.properties.toString().length < 4) {
							properties = json.properties.toString();
						} else if (json.properties.toString().length === 4) {
							const result = json.properties.toString().match(/.{1,1}/g) || [];
							properties = result[0] + ',' + result[1] + result[2] + result[3];
						} else if (json.properties.toString().length === 5) {
							const result = json.properties.toString().match(/.{1,2}/g) || [];
							properties = result[0] + ',' + result[1] + result[2];
						} else if (json.properties.toString().length === 6) {
							const result = json.properties.toString().match(/.{1,3}/g) || [];
							properties = result[0] + ',' + result[1];
						} else if (json.properties.toString().length === 7) {
							const result = json.properties.toString().match(/.{1,1}/g) || [];
							properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
						}

						setCustomerNumbersScenario(psrCustomers);
						setpropertyNumbersScenario(properties);


						map.current.on('render', renderCheck);


						let networks = ["ssen-scotland", "ssen-southern"];
						let dnoTest: any;
						for (let i = 0; i < networks.length; i++) {
							dnoTest = networks[i];
							map.current.removeLayer(dnoTest + '-vector-tiles');
							map.current.removeSource(dnoTest + '-tiles');
						}

						let blocksArray = []

						let colours: any = {};
						let stops: Array<Array<string>> = [];
						let colorProps = { property: 'block', type: "categorical", stops: stops };

						let blockSet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'];

						for (let i = 0; i < json.blocks.length; i++) {
							let index = blockSet.indexOf(json.blocks[i])
							blocksArray.push(json.blocks[i])
							blockSet.splice(index, 1);
						}
						setLastBlocks(blocksArray)

						// let networks = ["murphypowerdistribution", "ssen-scotland","enw"];
						let blocks = blockSet.length;

						for (let i = 0; i < blocks; i++) {
							let block: string = blockSet[i];
							if (lastBlocks.length > 0) {
								if (lastScenarioTime !== scenarioTime) {
									for (let j = 0; j < lastBlocks.length; j++) {
										if (blockSet[i] === lastBlocks[j]) {
											colours[block] = "hsl(0,0%,50%)";
											j = lastBlocks.length;

										} else {
											colours[block] = blockColourArray[block];
										}
									}
								} else {
									colours[block] = blockColourArray[block];
								}
							} else {
								colours[block] = blockColourArray[block];
							}
							stops.push([block, colours[block]]);
						}
						// Resize map to fit window

						var dno: string;

						for (let i = 0; i < networks.length; i++) {
							dno = networks[i];

							const loc = window.location;
							let path;
							if (loc.hostname == "localhost" || loc.hostname == "127.0.0.1") {
								// This needs to explicitly point to S3
								path = "https://ssen-myrotablock-test.opcld.com";
							} else {
								// Going to assume that it's always on default port for http/https as it should be in production
								path = loc.protocol + "//" + loc.hostname;
							}
							path += "/partners/tiles/vector/";
							map.current.addSource(dno + '-tiles', {
								'type': 'vector',
								'tiles': [
									path + dno + '/{z}/{x}/{y}.pbf'
								],
								'minzoom': 4,
								'maxzoom': 12
							});

							let areaPaint: any = {
								'fill-color': colorProps,
								'fill-opacity': [
									'interpolate',
									// Set the exponential rate of change to 0.5
									['exponential', 0.5],
									['zoom'],
									// When zoom is 10, buildings will be 100% transparent.
									9,
									0.95,
									// When zoom is 18 or higher, buildings will be 100% opaque.
									17,
									0.25
								]
							};

							map.current.addLayer(
								{
									'id': dno + '-vector-tiles', // Layer ID
									'type': 'fill',
									'source': dno + '-tiles', // ID of the tile source created above
									// Source has several layers. We visualize the one with name 'sequence'.
									'source-layer': 'rld-block',
									'layout': {},
									'paint': areaPaint
								}
							);
						}

						if (selectedArea === "All") {
							for (let i = 0; i < userDistrictIdArray.length; i++) {
								map.current.removeLayer('Layer-' + i);
								map.current.removeLayer('outline' + i);
								map.current.removeSource(userDistrictIdArray[i] + '-ID');

							}
						} else {
							map.current.removeLayer('Layer-' + selectedArea);
							map.current.removeLayer('outline');
							map.current.removeSource(selectedArea + '-ID');
						}


						let path2: any;

						path2 = BASE_URL;

						path2 += "/gridim/rotaload/partners/overlays?districts=" + selectedArea;
						map.current.addSource(selectedArea + '-ID', {
							'type': 'geojson',
							'data': path2
						});

						let areaPaint: any = {
							'fill-color': 'black',
							'fill-opacity': [
								'interpolate',
								['exponential', 0.5],
								['zoom'],
								7,
								0.25,
								17,
								0.25
							]
						};

						map.current.addLayer(
							{
								'id': 'Layer-' + selectedArea,
								'type': 'fill',
								'source': selectedArea + '-ID',
								'layout': {},
								'paint': areaPaint
							}
						);

						map.current.addLayer({
							'id': 'outline',
							'type': 'line',
							'source': selectedArea + '-ID',
							'layout': {},
							'paint': {
								'line-color': 'white',
								'line-width': 2
							}
						});
						map.current.on('render', afterChangeComplete); // warning: this fires many times per second!
						let headers = new Headers();
						headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
						let tempBoundariesArray: any;
						tempBoundariesArray = [];
						if (selectedArea !== "All") {
							fetch(path2, { headers: headers })
								.then(function (response) { return response.json(); })
								.then(function (json) {
									const coordinates = json.features[0].geometry.coordinates;
									setOverlayArray(coordinates);

									let featureType = json.features[0].geometry.type.toLowerCase();

									if (featureType == "polygon") {



										var polygon = turf.polygon([coordinates[0]]);

										setCurrentBoundaries(polygon);
									} else if (featureType == "multipolygon") {
										for (let i = 0; i < coordinates.length; i++) {
											for (let j = 0; j < coordinates[i][0].length; j++) {
												llb.extend(coordinates[i][0][j]);
											}
										}
										map.current.fitBounds(llb);
										var multipolygon = turf.multiPolygon([coordinates]);
										setCurrentBoundaries(multipolygon);
									}
								})
						}
					})

			} else {
				let customerNoAll: any;
				customerNoAll = 0;
				let propertyNoAll: any;
				propertyNoAll = 0;
				let fetchesComplete = 0
				let psrCustomers: any;
				let properties: any;
				for (let i = 0; i < userDistrictIdArray.length; i++) {
					pathForScenario = BASE_URL + "/gridim/rotaload/partners?action=getScenarioCustomerData&district=" + userDistrictIdArray[i] + "&stage=" + scenarioStage.replace(/\s/g, '') + "&day=" + scenarioDay.replace(/\s/g, '') + "&time=" + scenarioTime.replace(/\s/g, '');
					setLastScenarioTime(scenarioTime);
					fetch(pathForScenario, { headers: headers })
						.then(function (response) { return response.json(); })
						.then(function (json) {
							customerNoAll = customerNoAll + json.psrCustomers;
							propertyNoAll = propertyNoAll + json.properties;
							setTimeAndLevelButtons("Disable");


							fetchesComplete++;
							if (fetchesComplete == (userDistrictIdArray.length)) {

								if (customerNoAll.toString().length < 4) {
									psrCustomers = customerNoAll.toString();
								} else if (customerNoAll.toString().length === 4) {
									const result = customerNoAll.toString().match(/.{1,1}/g) || [];
									psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
								} else if (customerNoAll.toString().length === 5) {
									const result = customerNoAll.toString().match(/.{1,2}/g) || [];
									psrCustomers = result[0] + ',' + result[1] + result[2];
								} else if (customerNoAll.toString().length === 6) {
									const result = customerNoAll.toString().match(/.{1,3}/g) || [];
									psrCustomers = result[0] + ',' + result[1];
								} else if (customerNoAll.toString().length === 7) {
									const result = customerNoAll.toString().match(/.{1,1}/g) || [];
									psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
								}

								if (propertyNoAll.toString().length < 4) {
									properties = propertyNoAll.toString();
								} else if (propertyNoAll.toString().length === 4) {
									const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
									properties = result[0] + ',' + result[1] + result[2] + result[3];
								} else if (propertyNoAll.toString().length === 5) {
									const result = propertyNoAll.toString().match(/.{1,2}/g) || [];
									properties = result[0] + ',' + result[1] + result[2];
								} else if (propertyNoAll.toString().length === 6) {
									const result = propertyNoAll.toString().match(/.{1,3}/g) || [];
									properties = result[0] + ',' + result[1];
								} else if (propertyNoAll.toString().length === 7) {
									const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
									properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
								}
								setCustomerNumbersScenario(psrCustomers);
								setpropertyNumbersScenario(properties);

								map.current.on('render', renderCheck);



								let networks = ["ssen-scotland", "ssen-southern"];
								let dnoTest: any;
								for (let i = 0; i < networks.length; i++) {
									dnoTest = networks[i];
									map.current.removeLayer(dnoTest + '-vector-tiles');
									map.current.removeSource(dnoTest + '-tiles');
								}


								let blocksArray = []
								let colours: any = {};

								let stops: Array<Array<string>> = [];

								let colorProps = { property: 'block', type: "categorical", stops: stops };

								let blockSet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'];

								for (let i = 0; i < json.blocks.length; i++) {
									let index = blockSet.indexOf(json.blocks[i])
									blocksArray.push(json.blocks[i])
									blockSet.splice(index, 1);
								}
								setLastBlocks(blocksArray);


								// let networks = ["murphypowerdistribution", "ssen-scotland","enw"];

								let blocks = blockSet.length;

								for (let i = 0; i < blocks; i++) {
									let block: string = blockSet[i];
									if (lastBlocks.length > 0) {
										if (lastScenarioTime !== scenarioTime) {
											for (let j = 0; j < lastBlocks.length; j++) {
												if (blockSet[i] === lastBlocks[j]) {
													colours[block] = "hsl(0,0%,50%)";
													j = lastBlocks.length;
												} else {
													colours[block] = blockColourArray[block];
												}
											}
										} else {
											colours[block] = blockColourArray[block];
										}
									} else {
										colours[block] = blockColourArray[block];
									}
									stops.push([block, colours[block]]);

								}
								// Resize map to fit window


								var dno: string;

								for (let i = 0; i < networks.length; i++) {
									dno = networks[i];

									const loc = window.location;
									let path;
									if (loc.hostname == "localhost" || loc.hostname == "127.0.0.1") {
										// This needs to explicitly point to S3
										path = "https://ssen-myrotablock-test.opcld.com";
									} else {
										// Going to assume that it's always on default port for http/https as it should be in production
										path = loc.protocol + "//" + loc.hostname;
									}
									path += "/partners/tiles/vector/";
									map.current.addSource(dno + '-tiles', {
										'type': 'vector',
										'tiles': [
											path + dno + '/{z}/{x}/{y}.pbf'
										],
										'minzoom': 4,
										'maxzoom': 12
									});

									let areaPaint: any = {
										'fill-color': colorProps,
										'fill-opacity': [
											'interpolate',
											// Set the exponential rate of change to 0.5
											['exponential', 0.5],
											['zoom'],
											// When zoom is 10, buildings will be 100% transparent.
											9,
											0.95,
											// When zoom is 18 or higher, buildings will be 100% opaque.
											17,
											0.25
										]
									};

									map.current.addLayer(
										{
											'id': dno + '-vector-tiles', // Layer ID
											'type': 'fill',
											'source': dno + '-tiles', // ID of the tile source created above
											// Source has several layers. We visualize the one with name 'sequence'.
											'source-layer': 'rld-block',
											'layout': {},
											'paint': areaPaint
										}
									);
								}


								for (let i = 0; i < userDistrictIdArray.length; i++) {
									map.current.removeLayer('Layer-' + i);
									map.current.removeLayer('outline' + i);
									map.current.removeSource(userDistrictIdArray[i] + '-ID');

								}


								let path2: any;

								path2 = BASE_URL;

								for (let i = 0; i < userDistrictIdArray.length; i++) {

									map.current.addSource(userDistrictIdArray[i] + '-ID', {
										'type': 'geojson',
										'data': BASE_URL + "/gridim/rotaload/partners/overlays?districts=" + userDistrictIdArray[i]
									});

									let areaPaint: any = {
										'fill-color': 'black',
										'fill-opacity': [
											'interpolate',
											['exponential', 0.5],
											['zoom'],
											7,
											0.25,
											17,
											0.25
										]
									};

									map.current.addLayer(
										{
											'id': 'Layer-' + i,
											'type': 'fill',
											'source': userDistrictIdArray[i] + '-ID',
											'layout': {},
											'paint': areaPaint
										}
									);

									map.current.addLayer({
										'id': 'outline' + i,
										'type': 'line',
										'source': userDistrictIdArray[i] + '-ID',
										'layout': {},
										'paint': {
											'line-color': 'white',
											'line-width': 2
										}
									});


								}


								map.current.on('render', afterChangeComplete); // warning: this fires many times per second!
								let headers = new Headers();
								headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
								let tempBoundariesArray: any;
								tempBoundariesArray = [];


								const fetchData = async () => {
									for (let i = 0; i < userDistrictIdArray.length; i++) {
										let pathTest: any;
										pathTest = BASE_URL + "/gridim/rotaload/partners/overlays?districts=" + userDistrictIdArray[i];
										const response = await fetch(pathTest, { headers: headers }) as any;
										const json = await response.json();
										const coordinates = json.features[0].geometry.coordinates;
										let featureType = json.features[0].geometry.type.toLowerCase();

										if (featureType == "polygon") {


											var polygon = turf.polygon([coordinates[0]]);

											tempBoundariesArray.push(polygon);

										} else if (featureType == "multipolygon") {
											for (let i = 0; i < coordinates.length; i++) {
												for (let j = 0; j < coordinates[i][0].length; j++) {
													llb.extend(coordinates[i][0][j]);
												}
											}
											map.current.fitBounds(llb);

											var multipolygon = turf.multiPolygon([coordinates]);
											tempBoundariesArray.push(multipolygon);
										}

										if (i == (userDistrictIdArray.length - 1)) {
											setBoundariesArray(tempBoundariesArray);
										}
									}
								}

								fetchData();


							}
						})

				}

			}
			setSendScenarioDataFlag(0);
		}
	}, [sendScenarioDataFlag]);

	function renderCheck() {
		if (!map.current.loaded()) { return }

		setTimeAndLevelButtons("Enable");
		setRefreshButton("Enable");
	}

	useEffect(() => {
		if (clearScenario === "Clear") {
			setClearScenario("Disabled")
			let networks = ["ssen-scotland", "ssen-southern"];
			let dnoTest: any;
			for (let i = 0; i < networks.length; i++) {
				dnoTest = networks[i];
				map.current.removeLayer(dnoTest + '-vector-tiles');
				map.current.removeSource(dnoTest + '-tiles');
			}
			let colours: any = {};
			let darkColours: any = {};
			let stops: Array<Array<string>> = [];
			let darkStops: Array<Array<string>> = [];
			let colorProps = { property: 'block', type: "categorical", stops: stops };
			let path2: any;
			path2 = BASE_URL;

			let blockSet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Z'];
			// let networks = ["murphypowerdistribution", "ssen-scotland","enw"];

			let blocks = blockSet.length;
			for (let i = 0; i < blocks; i++) {
				let block: string = blockSet[i];
				let hsl = "hsl(" + (256 * i / blocks).toFixed(0) + ",50%,50%)";
				let dhsl = "hsl(" + (256 * i / blocks).toFixed(0) + ",75%,25%)";
				colours[block] = hsl;
				darkColours[block] = dhsl;
				stops.push([block, hsl]);
				darkStops.push([block, dhsl]);
			}
			// Resize map to fit window


			var dno: string;

			for (let i = 0; i < networks.length; i++) {
				dno = networks[i];

				const loc = window.location;
				let path;
				if (loc.hostname == "localhost" || loc.hostname == "127.0.0.1") {
					// This needs to explicitly point to S3
					path = "https://ssen-myrotablock-test.opcld.com";
				} else {
					// Going to assume that it's always on default port for http/https as it should be in production
					path = loc.protocol + "//" + loc.hostname;
				}
				path += "/partners/tiles/vector/";
				map.current.addSource(dno + '-tiles', {
					'type': 'vector',
					'tiles': [
						path + dno + '/{z}/{x}/{y}.pbf'
					],
					'minzoom': 4,
					'maxzoom': 12
				});

				let areaPaint: any = {
					'fill-color': colorProps,
					'fill-opacity': [
						'interpolate',
						// Set the exponential rate of change to 0.5
						['exponential', 0.5],
						['zoom'],
						// When zoom is 10, buildings will be 100% transparent.
						9,
						0.95,
						// When zoom is 18 or higher, buildings will be 100% opaque.
						17,
						0.25
					]
				};

				map.current.addLayer(
					{
						'id': dno + '-vector-tiles', // Layer ID
						'type': 'fill',
						'source': dno + '-tiles', // ID of the tile source created above
						// Source has several layers. We visualize the one with name 'sequence'.
						'source-layer': 'rld-block',
						'layout': {},
						'paint': areaPaint
					}
				);
			}
			if (selectedArea === "All") {
				for (let i = 0; i < userDistrictIdArray.length; i++) {
					map.current.removeLayer('Layer-' + i);
					map.current.removeLayer('outline' + i);
					map.current.removeSource(userDistrictIdArray[i] + '-ID');

				}
			} else {
				map.current.removeLayer('Layer-' + selectedArea);
				map.current.removeLayer('outline');
				map.current.removeSource(selectedArea + '-ID');
			}
			if (selectedArea == 'All') {
				for (let i = 0; i < userDistrictIdArray.length; i++) {

					map.current.addSource(userDistrictIdArray[i] + '-ID', {
						'type': 'geojson',
						'data': BASE_URL + "/gridim/rotaload/partners/overlays?districts=" + userDistrictIdArray[i]
					});

					let areaPaint: any = {
						'fill-color': 'black',
						'fill-opacity': [
							'interpolate',
							['exponential', 0.5],
							['zoom'],
							7,
							0.25,
							17,
							0.25
						]
					};

					map.current.addLayer(
						{
							'id': 'Layer-' + i,
							'type': 'fill',
							'source': userDistrictIdArray[i] + '-ID',
							'layout': {},
							'paint': areaPaint
						}
					);

					map.current.addLayer({
						'id': 'outline' + i,
						'type': 'line',
						'source': userDistrictIdArray[i] + '-ID',
						'layout': {},
						'paint': {
							'line-color': 'white',
							'line-width': 2
						}
					});


				}
			} else {
				path2 += "/gridim/rotaload/partners/overlays?districts=" + selectedArea;
				map.current.addSource(selectedArea + '-ID', {
					'type': 'geojson',
					'data': path2
				});

				let areaPaint: any = {
					'fill-color': 'black',
					'fill-opacity': [
						'interpolate',
						['exponential', 0.5],
						['zoom'],
						7,
						0.25,
						17,
						0.25
					]
				};

				map.current.addLayer(
					{
						'id': 'Layer-' + selectedArea,
						'type': 'fill',
						'source': selectedArea + '-ID',
						'layout': {},
						'paint': areaPaint
					}
				);

				map.current.addLayer({
					'id': 'outline',
					'type': 'line',
					'source': selectedArea + '-ID',
					'layout': {},
					'paint': {
						'line-color': 'white',
						'line-width': 2
					}
				});
			}
			map.current.on('render', afterChangeComplete); // warning: this fires many times per second!
			let headers = new Headers();
			headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
			let tempBoundariesArray: any;
			tempBoundariesArray = [];
			if (selectedArea !== "All") {
				fetch(path2, { headers: headers })
					.then(function (response) { return response.json(); })
					.then(function (json) {
						const coordinates = json.features[0].geometry.coordinates;
						setOverlayArray(coordinates);
						let featureType = json.features[0].geometry.type.toLowerCase();

						if (featureType == "polygon") {



							var polygon = turf.polygon([coordinates[0]]);

							setCurrentBoundaries(polygon);
						} else if (featureType == "multipolygon") {

							for (let i = 0; i < coordinates.length; i++) {
								for (let j = 0; j < coordinates[i][0].length; j++) {
									llb.extend(coordinates[i][0][j]);
								}
							}
							map.current.fitBounds(llb);
							var multipolygon = turf.multiPolygon([coordinates]);
							setCurrentBoundaries(multipolygon);
						};
					})
			} else {

				const fetchData = async () => {
					for (let i = 0; i < userDistrictIdArray.length; i++) {
						let pathTest: any;
						pathTest = BASE_URL + "/gridim/rotaload/partners/overlays?districts=" + userDistrictIdArray[i];
						const response = await fetch(pathTest, { headers: headers }) as any;
						const json = await response.json();
						const coordinates = json.features[0].geometry.coordinates;
						let featureType = json.features[0].geometry.type.toLowerCase();

						if (featureType == "polygon") {


							var polygon = turf.polygon([coordinates[0]]);

							tempBoundariesArray.push(polygon);

						} else if (featureType == "multipolygon") {
							for (let i = 0; i < coordinates.length; i++) {
								for (let j = 0; j < coordinates[i][0].length; j++) {
									llb.extend(coordinates[i][0][j]);
								}
							}
							map.current.fitBounds(llb);
							var multipolygon = turf.multiPolygon([coordinates]);
							tempBoundariesArray.push(multipolygon);
						}

						if (i == (userDistrictIdArray.length - 1)) {
							setBoundariesArray(tempBoundariesArray);
						}
					}
				}

				fetchData();
			}
		}
	}, [clearScenario]);


	function afterChangeComplete() {
		if (!map.current.loaded()) { return } // still not loaded; bail out.

		let headers = new Headers();
		headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
		let path2: any;
		path2 = BASE_URL;
		if (selectedArea !== "All") {
			path2 = path2 + "/gridim/rotaload/partners/overlays?districts=" + selectedArea;
			fetch(path2, { headers: headers })
				.then(function (response) { return response.json(); })
				.then(function (json) {

					let psrCustomers: any;
					let properties: any;


					if (json.features[0].properties.psrCustomers.toString().length < 4) {
						psrCustomers = json.features[0].properties.psrCustomers.toString();
					} else if (json.features[0].properties.psrCustomers.toString().length === 4) {
						const result = json.features[0].properties.psrCustomers.toString().match(/.{1,1}/g) || [];
						psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
					} else if (json.features[0].properties.psrCustomers.toString().length === 5) {
						const result = json.features[0].properties.psrCustomers.toString().match(/.{1,2}/g) || [];
						psrCustomers = result[0] + ',' + result[1] + result[2];
					} else if (json.features[0].properties.psrCustomers.toString().length === 6) {
						const result = json.features[0].properties.psrCustomers.toString().match(/.{1,3}/g) || [];
						psrCustomers = result[0] + ',' + result[1];
					} else if (json.features[0].properties.psrCustomers.toString().length === 7) {
						const result = json.features[0].properties.psrCustomers.toString().match(/.{1,1}/g) || [];
						psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
					}

					if (json.features[0].properties.properties.toString().length < 4) {
						properties = json.features[0].properties.properties.toString();
					} else if (json.features[0].properties.properties.toString().length === 4) {
						const result = json.features[0].properties.properties.toString().match(/.{1,1}/g) || [];
						properties = result[0] + ',' + result[1] + result[2] + result[3];
					} else if (json.features[0].properties.properties.toString().length === 5) {
						const result = json.features[0].properties.properties.toString().match(/.{1,2}/g) || [];
						properties = result[0] + ',' + result[1] + result[2];
					} else if (json.features[0].properties.properties.toString().length === 6) {
						const result = json.features[0].properties.properties.toString().match(/.{1,3}/g) || [];
						properties = result[0] + ',' + result[1];
					} else if (json.features[0].properties.properties.toString().length === 7) {
						const result = json.features[0].properties.properties.toString().match(/.{1,1}/g) || [];
						properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
					}

					setpropertyNumbers(properties);
					setCustomerNumbers(psrCustomers);

				})
		} else {
			let customerNoAll: any;
			customerNoAll = 0;
			let propertyNoAll: any;
			propertyNoAll = 0;
			let fetchesComplete = 0;
			for (let i = 0; i < userDistrictIdArray.length; i++) {
				let pathTest: any;
				pathTest = BASE_URL + "/gridim/rotaload/partners/overlays?districts=" + userDistrictIdArray[i];
				fetch(pathTest, { headers: headers })
					.then(function (response) { return response.json(); })
					.then(function (json) {
						customerNoAll = customerNoAll + json.features[0].properties.psrCustomers;
						propertyNoAll = propertyNoAll + json.features[0].properties.properties;
						fetchesComplete++;
						if (fetchesComplete == (userDistrictIdArray.length)) {

							let psrCustomers: any;
							let properties: any;


							if (customerNoAll.toString().length < 4) {
								psrCustomers = customerNoAll.toString();
							} else if (customerNoAll.toString().length === 4) {
								const result = customerNoAll.toString().match(/.{1,1}/g) || [];
								psrCustomers = result[0] + ',' + result[1] + result[2] + result[3];
							} else if (customerNoAll.toString().length === 5) {
								const result = customerNoAll.toString().match(/.{1,2}/g) || [];
								psrCustomers = result[0] + ',' + result[1] + result[2];
							} else if (customerNoAll.toString().length === 6) {
								const result = customerNoAll.toString().match(/.{1,3}/g) || [];
								psrCustomers = result[0] + ',' + result[1];
							} else if (customerNoAll.toString().length === 7) {
								const result = customerNoAll.toString().match(/.{1,1}/g) || [];
								psrCustomers = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
							}

							if (propertyNoAll.toString().length < 4) {
								properties = propertyNoAll.toString();
							} else if (propertyNoAll.toString().length === 4) {
								const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
								properties = result[0] + ',' + result[1] + result[2] + result[3];
							} else if (propertyNoAll.toString().length === 5) {
								const result = propertyNoAll.toString().match(/.{1,2}/g) || [];
								properties = result[0] + ',' + result[1] + result[2];
							} else if (propertyNoAll.toString().length === 6) {
								const result = propertyNoAll.toString().match(/.{1,3}/g) || [];
								properties = result[0] + ',' + result[1];
							} else if (propertyNoAll.toString().length === 7) {
								const result = propertyNoAll.toString().match(/.{1,1}/g) || [];
								properties = result[0] + ',' + result[1] + result[2] + result[3] + ',' + + result[4] + result[5] + result[6];
							}

							setpropertyNumbers(properties);
							setCustomerNumbers(psrCustomers);
						}
					})
			}
		}
		map.current.off('render', afterChangeComplete); // remove this handler now that we're done.
	}




















	const llb = new mapboxgl.LngLatBounds();

	useEffect(() => {

		if (selectedArea !== 0) {
			if (initialFlag === "Set") {
				if (lastSelectedArea === "All") {
					for (let i = 0; i < userDistrictIdArray.length; i++) {
						map.current.removeLayer('Layer-' + i);
						map.current.removeLayer('outline' + i);
						map.current.removeSource(userDistrictIdArray[i] + '-ID');

					}
				} else {
					map.current.removeLayer('Layer-' + lastSelectedArea);
					map.current.removeLayer('outline');
					map.current.removeSource(lastSelectedArea + '-ID');
				}

			}

			if (visibleOrHiddenFilter === 1) {
				filter_Button = document.getElementById('filter-Button')
				filter_Button.style.display = 'flex'
			}




			setSendScenarioDataFlag("Set");
			setInitialFlag("Set");



			map.current.on('render', afterChangeComplete);
			let path2: any;

			path2 = BASE_URL;

			if (selectedArea == 'All') {
				for (let i = 0; i < userDistrictIdArray.length; i++) {

					map.current.addSource(userDistrictIdArray[i] + '-ID', {
						'type': 'geojson',
						'data': BASE_URL + "/gridim/rotaload/partners/overlays?districts=" + userDistrictIdArray[i]
					});

					let areaPaint: any = {
						'fill-color': 'black',
						'fill-opacity': [
							'interpolate',
							['exponential', 0.5],
							['zoom'],
							7,
							0.25,
							17,
							0.25
						]
					};

					map.current.addLayer(
						{
							'id': 'Layer-' + i,
							'type': 'fill',
							'source': userDistrictIdArray[i] + '-ID',
							'layout': {},
							'paint': areaPaint
						}
					);

					map.current.addLayer({
						'id': 'outline' + i,
						'type': 'line',
						'source': userDistrictIdArray[i] + '-ID',
						'layout': {},
						'paint': {
							'line-color': 'white',
							'line-width': 2
						}
					});


				}
			} else {
				path2 += "/gridim/rotaload/partners/overlays?districts=" + selectedArea;
				map.current.addSource(selectedArea + '-ID', {
					'type': 'geojson',
					'data': path2
				});

				let areaPaint: any = {
					'fill-color': 'black',
					'fill-opacity': [
						'interpolate',
						['exponential', 0.5],
						['zoom'],
						7,
						0.25,
						17,
						0.25
					]
				};

				map.current.addLayer(
					{
						'id': 'Layer-' + selectedArea,
						'type': 'fill',
						'source': selectedArea + '-ID',
						'layout': {},
						'paint': areaPaint
					}
				);

				map.current.addLayer({
					'id': 'outline',
					'type': 'line',
					'source': selectedArea + '-ID',
					'layout': {},
					'paint': {
						'line-color': 'white',
						'line-width': 2
					}
				});
			}

			let headers = new Headers();
			headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
			let tempBoundariesArray: any;
			tempBoundariesArray = [];
			if (selectedArea !== "All") {
				fetch(path2, { headers: headers })
					.then(function (response) { return response.json(); })
					.then(function (json) {
						const coordinates = json.features[0].geometry.coordinates;

						let featureType = json.features[0].geometry.type.toLowerCase();

						if (featureType == "polygon") {



							var polygon = turf.polygon([coordinates[0]]);

							setCurrentBoundaries(polygon);




						} else if (featureType == "multipolygon") {
							for (let i = 0; i < coordinates.length; i++) {
								for (let j = 0; j < coordinates[i][0].length; j++) {
									llb.extend(coordinates[i][0][j]);
								}
							
							}
							map.current.fitBounds(llb);

							var multipolygon = turf.multiPolygon([coordinates]);
							setCurrentBoundaries(multipolygon);
						}
						map.current.once('zoomend', () => {

							setBoundsZoom(map.current.getZoom());
						});
					})
			} else {

				const fetchData = async () => {
					for (let i = 0; i < userDistrictIdArray.length; i++) {
						let pathTest: any;
						pathTest = BASE_URL + "/gridim/rotaload/partners/overlays?districts=" + userDistrictIdArray[i];
						const response = await fetch(pathTest, { headers: headers }) as any;
						const json = await response.json();
						const coordinates = json.features[0].geometry.coordinates;

						let featureType = json.features[0].geometry.type.toLowerCase();

						if (featureType == "polygon") {

							var polygon = turf.polygon([coordinates[0]]);
							tempBoundariesArray.push(polygon);

						} else if (featureType == "multipolygon") {

							for (let i = 0; i < coordinates.length; i++) {
								for (let j = 0; j < coordinates[i][0].length; j++) {
									llb.extend(coordinates[i][0][j]);
								}
							}
							map.current.fitBounds(llb);

							var multipolygon = turf.multiPolygon([coordinates]);
							tempBoundariesArray.push(multipolygon);

						}
						if (i == (userDistrictIdArray.length - 1)) {
							setBoundariesArray(tempBoundariesArray);
						}
					}
				}

				fetchData();

				map.current.flyTo({
					center: [-2.6743, 54.8235],
					zoom: 5.5,
					essential: true // this animation is considered essential with respect to prefers-reduced-motion
				});
			}

			let pathNoteTest: any;

			if (selectedArea == "All") {
				let allAddress: any
				allAddress = "";
				for (let i = 0; i < userDistrictIdArray.length; i++) {
					allAddress = allAddress + userDistrictIdArray[i];
					if ((i !== userDistrictIdArray.length - 1)) {
						allAddress = allAddress + ","
					}
				}
				pathNoteTest = BASE_URL + "/gridim/rotaload/partners?action=getNotes&districts=" + allAddress;

			} else {
				pathNoteTest = BASE_URL + "/gridim/rotaload/partners?action=getNotes&districts=" + selectedArea;
			}
		}
	}, [selectedArea]);




















	let maptestthing: any;
	maptestthing = [];


	function updateMap(mapArray: any) {
		if (noteToggle == "ON") {

			mapArray.forEach((marker: { remove: () => any; }) => marker.remove())

			let headers = new Headers();
			headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);

			let pathNoteTest: any;

			if (selectedArea == "All") {
				let allAddress: any
				allAddress = "";
				for (let i = 0; i < userDistrictIdArray.length; i++) {
					allAddress = allAddress + userDistrictIdArray[i];
					if ((i !== userDistrictIdArray.length - 1)) {
						allAddress = allAddress + ","
					}
				}
				pathNoteTest = BASE_URL + "/gridim/rotaload/partners?action=getNotes&districts=" + allAddress;

			} else {
				pathNoteTest = BASE_URL + "/gridim/rotaload/partners?action=getNotes&districts=" + selectedArea;
			}

			fetch(pathNoteTest, { headers: headers })
				.then(function (response) { return response.json(); })
				.then(async function (note) {
					const d = new Date();
					let faultArray: any[] = [];
					let index = note.length + 1;

					const fetchData = async () => {

						const response = await fetch(liveFaultsPath) as any;

						const json = await response.json();
						for (let i = 0; i < json.Faults.length; i++) {
							faultArray.push(json.Faults[i].reference);
						}

						note.forEach((note: any) => { // Goes through each note obtained through the fetch, keeps count of every note with the index.  
							index--;
							let count = 0;
							let note_Expiration = note.Expiration;
							let note_Fault = note.LinkedTo;
							let passTimeCheck: any;

							if (selectedArea === "All") {
								for (let i = 0; i < userDistrictIdArray.length; i++) {
									if (note.district === userDistrictIdArray[i]) {
										count = count + 1;
									}
								}
							} else if (note.district === selectedArea) {
								count = count + 1;
							}

							if (count === 1) {
								if (note_Expiration.length > 3 && note_Expiration != "undefined") {
									const date = note_Expiration.split("T");

									let time: any;
									let dateTimeTemp = date[1];

									if (dateTimeTemp.includes("+")) {
										time = dateTimeTemp.split("+")

									} else if (dateTimeTemp.includes("-")) {
										time = dateTimeTemp.split("-")
									}


									const YMD = date[0].split("-")
									const HMS = time[0].split(":")

									let dateTest: any;
									dateTest = new Date();

									let dText: any;
									dText = new Date(Number(YMD[0]), (Number(YMD[1]) - 1), Number(YMD[2]), Number(HMS[0]), Number(HMS[1]), Number(HMS[2]));

									passTimeCheck = "Fail";
									if (dText.getTime() > dateTest.getTime()) {
										passTimeCheck = "Pass";
									}


									let noteFaultPass: any;
									if (passTimeCheck === "Pass") {

										noteFaultPass = "Fail"
										for (let i = 0; i < faultArray.length; i++) {
											if (note_Fault === faultArray[i] || note_Fault === "No_Fault") {
												noteFaultPass = "Pass"
											}
										}

										if (noteFaultPass === "Pass") {

											const el = document.createElement('div');

											if (note.organisation == resiliencePartner) {
												let markerVar: any;


												if (note.Visability === "Public") {
													el.className = 'markerPublicNote';
												} else if (note.Visability === "Partners") {
													el.className = 'markerPartnersNote';
												} else if (note.Visability === "SSEN") {
													el.className = 'markerSSENNote';
												}


												let lngLat = [String(note.longitude), String(note.latitude)];
												markerVar = new mapboxgl.Marker({
													element: el,
													anchor: 'bottom'
												})
													.setLngLat(lngLat)
													.addTo(map.current);
												maptestthing.push(markerVar)
												markerVar.getElement().addEventListener('click', () => {
													setMarkerClickedLocation(markerVar.getLngLat());
													setNoteMarkerType("User")
													setNoteToHighlight(markerVar.getLngLat());
													setHighlightedNote(note.Visability);
													setmapOrSidebarClick("Map");
												});
											} else {
												let markerVar: any;


												if (note.Visability === "Public") {
													el.className = 'markerPublicNoteNonUser';
												} else if (note.Visability === "Partners") {
													el.className = 'markerPartnersNoteNonUser';
												} else if (note.Visability === "SSEN") {
													el.className = 'markerSSENNoteNonUser';
												}


												let lngLat = [String(note.longitude), String(note.latitude)];
												markerVar = new mapboxgl.Marker({
													element: el,
													anchor: 'bottom'
												})
													.setLngLat(lngLat)
													.addTo(map.current);
												maptestthing.push(markerVar)
												markerVar.getElement().addEventListener('click', () => {
													setMarkerClickedLocation(markerVar.getLngLat());
													setNoteMarkerType("Non-User")
													setHighlightedNote(note.Visability);
													setNoteToHighlight(markerVar.getLngLat());
													setmapOrSidebarClick("Map");
												});
											}
											setMarkerArray(maptestthing);

										}
									}
								}
							}

						}
						)

					};
					await fetchData();

					maptestthing = [];


					setTimeout(EnableRefreshButton, 2000)


				});
		}
	}





	function EnableRefreshButton() {
		setRefreshButton("Enabled")
	}














	useEffect(() => {
		if (noteToHighlight != "0") {
			let testValue: any;
			let testValue2: any;
			if (mapOrSidebarClick === "Sidebar") {
				let arrayOfNote = noteToHighlight.split(",");

				testValue = Number(arrayOfNote[1]);
				testValue = testValue.toFixed(3);


				testValue2 = Number(arrayOfNote[0]);
				testValue2 = testValue2.toFixed(3);

			} else if (mapOrSidebarClick === "Map") {
				let arrayOfNote = noteToHighlight;

				testValue = arrayOfNote.lat;
				testValue = testValue.toFixed(3);


				testValue2 = arrayOfNote.lng;
				testValue2 = testValue2.toFixed(3);
			}



			for (let i = 0; i < markerArray.length; i++) {
				if ((markerArray[i]._lngLat.lng.toFixed(3).toString() === testValue2) && (markerArray[i]._lngLat.lat.toFixed(3).toString() === testValue)) {
					if (noteMarkerType === "User") {
						if (highlightedNote === "Public") {
							markerArray[i]._element.className = "markerPublicNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						} else if (highlightedNote === "Partners") {
							markerArray[i]._element.className = "markerPartnersNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						} else if (highlightedNote === "SSEN") {
							markerArray[i]._element.className = "markerSSENNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						}

						setNoteMarkerType(0);
					} else if (noteMarkerType === "Non-User") {
						if (highlightedNote === "Public") {
							markerArray[i]._element.className = "markerPublicNoteNonUserSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						} else if (highlightedNote === "Partners") {
							markerArray[i]._element.className = "markerPartnersNoteNonUserSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						} else if (highlightedNote === "SSEN") {
							markerArray[i]._element.className = "markerSSENNoteNonUserSelected mapboxgl-marker mapboxgl-marker-anchor-bottom"
						}
						setNoteMarkerType(0);
					}
					if (map.current.getZoom() < boundsZoom) {
						map.current.flyTo({
							center: [markerArray[i]._lngLat.lng, markerArray[i]._lngLat.lat],
							duration: 1200,
							essential: true,
							zoom: boundsZoom
						});
					} else {
						map.current.flyTo({
							center: [markerArray[i]._lngLat.lng, markerArray[i]._lngLat.lat],
							duration: 1200,
							essential: true,
						})
					}
				} else {



					if (markerArray[i]._element.className === "markerPublicNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom" || markerArray[i]._element.className === "markerPartnersNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom" || markerArray[i]._element.className === "markerSSENNoteSelected mapboxgl-marker mapboxgl-marker-anchor-bottom") {
						markerArray[i]._element.className = "markerPublicNote mapboxgl-marker mapboxgl-marker-anchor-bottom";
					} else if (markerArray[i]._element.className === "markerPublicNote mapboxgl-marker mapboxgl-marker-anchor-bottom" || markerArray[i]._element.className === "markerPartnersNote mapboxgl-marker mapboxgl-marker-anchor-bottom" || markerArray[i]._element.className === "markerSSENNote mapboxgl-marker mapboxgl-marker-anchor-bottom") {
						markerArray[i]._element.className = "markerPublicNote mapboxgl-marker mapboxgl-marker-anchor-bottom";
					} else {
						markerArray[i]._element.className = "markerPublicNoteNonUser mapboxgl-marker mapboxgl-marker-anchor-bottom";
					}

				}
			}
		}
	}, [noteToHighlight])











	let modal_Postcode: any;
	let modal_Container2: any;
	let filter_Container: any;
	let filter_Button: any;

	useEffect(() => {
		if (noteLocationSelection !== 0) {
			let outofbounds: any;
			if (selectedArea !== 0 && selectedArea !== "All") {
				outofbounds = 0;
				map.current.once('click', async (e: any) => {
					const el = document.createElement('div');
					el.className = 'markerPublicNote';
					let point = turf.point([e.lngLat.lng, e.lngLat.lat]);
					let isInsideBoundary = "Outside";
					for (let i = 0; i < currentBoundaries.geometry.coordinates[0].length; i++) {


						var polygon = turf.polygon([currentBoundaries.geometry.coordinates[0][i][0]]);

						if (turf.booleanPointInPolygon(point, polygon)) {
							let markerVar: any;
							markerVar = new mapboxgl.Marker({
								element: el,
								offset: [3, -18],
								anchor: 'bottom'

							})
								.setLngLat(e.lngLat)
								.addTo(map.current);
							markerVar.getElement().addEventListener('click', () => {
								setMarkerClickedLocation(markerVar.getLngLat());
								setNoteMarkerType("User")
								setNoteToHighlight(markerVar.getLngLat());
								setmapOrSidebarClick("Map");
								if (map.current.getZoom() < boundsZoom) {
									map.current.flyTo({
										center: [markerVar._lngLat.lng, markerVar._lngLat.lat],
										duration: 1200,
										essential: true,
										zoom: boundsZoom
									});
								} else {
									map.current.flyTo({
										center: [markerVar._lngLat.lng, markerVar._lngLat.lat],
										duration: 1200,
										essential: true,
									})
								};
							});
							modal_Postcode = document.getElementById('modal-Postcode');
							modal_Postcode.style.display = "block";
							setLatLong(markerVar.getLngLat());
							modal_Container2 = document.getElementById('modal-Container2');
							modal_Container2.style.display = 'flex'
							mapCancelMarkers.push(markerVar)
							setMarkerCancelArray(mapCancelMarkers);
							setMouseAppearance(mouseState.NORMAL);
							setTempMarkerStorage(markerVar);
							setAddNoteAllLocation(selectedArea);
							setNoteMouseActive("Deactivate");
							setUpdateNotes("Update");
							let arrayofFault: any;
							arrayofFault = [];

							const response = await fetch(liveFaultsPath) as any;

							const json = await response.json();

							for (let i = 0; i < json.Faults.length; i++) {
								arrayofFault.push(json.Faults[i].reference);
							}

							setfaultArrayForSave(arrayofFault.sort());
							setOutsideOrInsideBoundry("Inside");
							isInsideBoundary = "Inside";
							setNoteLocationSelection(0);


						}
					}
					if (isInsideBoundary != "Inside") {
						setOutsideOrInsideBoundry("Outside");
						setNoteLocationSelection(0);
					}

				})
			} else if (selectedArea == "All") {
				outofbounds = 0;
				map.current.once('click', async (e: any) => {
					const el = document.createElement('div');
					el.className = 'markerPublicNote';
					let point = turf.point([e.lngLat.lng, e.lngLat.lat]);
					let isInsideBoundary = "Outside";
					for (let i = 0; i < userDistrictIdArray.length; i++) {


						for (let j = 0; j < boundariesArray[i].geometry.coordinates[0].length; j++) {

							var polygon = turf.polygon([boundariesArray[i].geometry.coordinates[0][j][0]]);


							if (turf.booleanPointInPolygon(point, polygon)) {
								let markerVar: any;
								markerVar = new mapboxgl.Marker({
									element: el,
									offset: [3, -18],
									anchor: 'bottom'

								})
									.setLngLat(e.lngLat)
									.addTo(map.current);
								markerVar.getElement().addEventListener('click', () => {
									setMarkerClickedLocation(markerVar.getLngLat());
									setNoteMarkerType("User")
									setNoteToHighlight(markerVar.getLngLat());
									setmapOrSidebarClick("Map");
									if (map.current.getZoom() < boundsZoom) {
										map.current.flyTo({
											center: [markerVar._lngLat.lng, markerVar._lngLat.lat],
											duration: 1200,
											essential: true,
											zoom: boundsZoom
										});
									} else {
										map.current.flyTo({
											center: [markerVar._lngLat.lng, markerVar._lngLat.lat],
											duration: 1200,
											essential: true,
										})
									};
								});
								modal_Postcode = document.getElementById('modal-Postcode');
								modal_Postcode.style.display = "block";
								setLatLong(markerVar.getLngLat());
								modal_Container2 = document.getElementById('modal-Container2');
								modal_Container2.style.display = 'flex'
								mapCancelMarkers.push(markerVar)
								setMarkerCancelArray(mapCancelMarkers);
								setMouseAppearance(mouseState.NORMAL);
								setTempMarkerStorage(markerVar);
								setAddNoteAllLocation(userDistrictIdArray[i]);
								setNoteMouseActive("Deactivate");
								setUpdateNotes("Update");
								let arrayofFault: any;
								arrayofFault = [];

								const response = await fetch(liveFaultsPath) as any;

								const json = await response.json();

								for (let k = 0; k < json.Faults.length; k++) {
									arrayofFault.push(json.Faults[k].reference);
								}

								setfaultArrayForSave(arrayofFault.sort());
								setOutsideOrInsideBoundry("Inside");
								isInsideBoundary = "Inside";
								setNoteLocationSelection(0);
							}
						}
						if (outofbounds == userDistrictIdArray.length) {
							setOutsideOrInsideBoundry("Outside");
							setNoteLocationSelection(0);
						}

					}
					if (isInsideBoundary != "Inside") {
						setOutsideOrInsideBoundry("Outside");
						setNoteLocationSelection(0);
					}
				})

			}
		}
	}, [noteLocationSelection]);



	useEffect(() => {
		if (addMarkerToArray !== 0) {
			let tempMarker = markerArray;
			tempMarker.push(tempMarkerStorage);
			setMarkerArray(tempMarker);
			setAddMarkerToArray(0);
			setTempMarkerStorage([]);
		}
	}, [addMarkerToArray]);



	function handleClick(): void {
		markerCancelArray.forEach((marker: { remove: () => any; }) => marker.remove());
		setMarkerCancelArray(0);
		modal_Postcode = document.getElementById('modal-Postcode');
		modal_Postcode.style.display = "none";
		modal_Container2 = document.getElementById('modal-Container2');
		modal_Container2.style.display = 'none'
		setNoteLocationSelection(0);
		setEnableButtons("Enable");
		setNoteMouseActive("Inactive");
	}

	function saveLocation(noteVisability: string): void {

		if (noteVisability === "Public") {
			modal_Postcode = document.getElementById('modal-Public');
			modal_Postcode.style.display = "block";
			modal_Container2 = document.getElementById('modal-Container3');
			modal_Container2.style.display = 'flex'
			modal_Postcode = document.getElementById('modal-Postcode');
			modal_Postcode.style.display = "none";
			modal_Container2 = document.getElementById('modal-Container2');
			modal_Container2.style.display = 'none'
		} else if (noteVisability === "Public-Confirm") {
			setXCoordConfirmed(latLong.lng);
			setYCoordConfirmed(latLong.lat);
			setNewNoteVisability("Public");
			setMouseAppearance(mouseState.NORMAL);

			modal_Postcode = document.getElementById('modal-Postcode');
			modal_Postcode.style.display = "none";
			modal_Container2 = document.getElementById('modal-Container2');
			modal_Container2.style.display = 'none'
			modal_Postcode = document.getElementById('modal-Public');
			modal_Postcode.style.display = "none";
			modal_Container2 = document.getElementById('modal-Container3');
			modal_Container2.style.display = 'none'

			setNoteLocationSelection(0);
		} else if (noteVisability === "Public-Denied") {

			modal_Postcode = document.getElementById('modal-Postcode');
			modal_Postcode.style.display = "block";
			modal_Container2 = document.getElementById('modal-Container2');
			modal_Container2.style.display = 'flex'
			modal_Postcode = document.getElementById('modal-Public');
			modal_Postcode.style.display = "none";
			modal_Container2 = document.getElementById('modal-Container3');
			modal_Container2.style.display = 'none'
		} else {
			setXCoordConfirmed(latLong.lng);
			setYCoordConfirmed(latLong.lat);
			setNewNoteVisability(noteVisability);
			setMouseAppearance(mouseState.NORMAL);

			modal_Postcode = document.getElementById('modal-Postcode');
			modal_Postcode.style.display = "none";
			modal_Container2 = document.getElementById('modal-Container2');
			modal_Container2.style.display = 'none'


			setNoteLocationSelection(0);
		}
	}

	useEffect(() => {
		if (noteCancelled === "Cancelled") {
			markerCancelArray.forEach((marker: { remove: () => any; }) => marker.remove());
			setNoteCancelled("UNSET");
			setNoteLocationSelection(0);
			setMouseAppearance(mouseState.NORMAL);
			setNoteMouseActive("Inactive");
		}
	}, [noteCancelled]);





	return (
		<>
			<div id='mapbox-Container' ref={mapboxContainer}>
				<div id='key-Container' className='key-Container' style={{ display: 'none' }}>
					<div id="key" className="key">
						<div id='key-Letter-And-Block' className='key-Letter-And-Block' style={{ width: '60px', right: '6px', position: 'relative', display: 'flex', justifyContent: 'center' }}>
							<div id="key-Letter" className="key-Letter">KEY</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(0,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">A</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(12,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">B</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(23,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">C</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(35,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">D</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(47,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">E</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(58,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">F</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(70,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">G</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(81,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">H</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(93,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">J</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(105,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">K</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(116,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">L</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(128,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">M</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(140,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">N</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(151,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">O</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(163,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">P</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(175,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">Q</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(186,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">R</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(198,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">S</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(209,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">T</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(221,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">U</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-And-Block'>
							<div style={{ backgroundColor: "hsl(233,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">V</div>
						</div>
						<div id='key-Letter-And-Block' className='key-Letter-Z'>
							<div style={{ backgroundColor: "hsl(244,50%,50%)" }} id='key-Block' className='key-Block' ></div>
							<div id="key-Letter" className="key-Letter">Z</div>
						</div>
					</div>
				</div>
			</div>
			<div id='filter-Button'>
				<div className='map-Button-Container'>
					<IonButton shape='round' onClick={() => filterClick(0)} ><IonIcon slot='icon-only' icon={funnel} /></IonButton>
					<label className='map-Button-Label'>Filter</label>
				</div>
			</div>
			<div id='map-Buttons'>
				<div className='map-Button-Container'>
					<IonButton id='zoom-in' shape='round' onClick={() => map.current.zoomIn()}><IonIcon slot='icon-only' icon={add} /></IonButton>
					<label className='map-Button-Label'>Zoom In</label>
				</div>
				<div className='map-Button-Container'>
					<IonButton id='zoom-out' shape='round' onClick={() => map.current.zoomOut()}><IonIcon slot='icon-only' icon={remove} /></IonButton>
					<label className='map-Button-Label'>Zoom Out</label>
				</div>
				<div className='map-Button-Container'>
					<IonButton id='reset-to-uk-bounds' shape='round' onClick={() => map.current.fitBounds([[-10.5, 49.5], [2.5, 60.5]])}><IonIcon slot='icon-only' icon={refresh} /></IonButton>
					<label className='map-Button-Label'>Reset Map</label>
				</div>

			</div>
			<div id='modal-Container2' style={{ display: 'none', position: 'fixed', right: '0px', top: '0px', left: '0px', justifyContent: 'center', alignContent: 'center', height: '100%', flexWrap: 'wrap', zIndex: '1', alignItems: 'center' }}>
				<div id="modal-Postcode" className="modal-Postcode" style={{ display: "none" }}>
					<div className="modal_content">
						<div className='note-Input'>
							<IonCard style={{ background: '#214E71', borderRadius: '15px 15px 0 0', width: '740px', right: '10px', top: '-10px' }}><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Confirm Note Location</h1></IonCard>
							<div style={{ fontSize: '20px', margin: '12px' }}>Do you want this note to be public, internal for other partners, or internal for SSEN staff members?</div>
							<div className='note-Layout-Input'>
								<div className='note-Button-Placement'>
									<div style={{ display: "flex", flexDirection: "column" }}>


										<div style={{ display: "flex" }}>
											<IonButton className='note-Button-Green' shape="round" onClick={() => saveLocation("Public")} >Public</IonButton>
											<IonButton className='note-Button-Green' shape="round" onClick={() => saveLocation("Partners")} >Partners</IonButton>
											<IonButton className='note-Button-Green' shape="round" onClick={() => saveLocation("SSEN")} >SSEN</IonButton>


										</div>
										<div style={{ display: "flex", justifyContent: "center" }}>
											<IonButton className='note-Button-Red' shape="round" onClick={() => handleClick()}>Cancel</IonButton>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id='modal-Container3' style={{ display: 'none', position: 'fixed', right: '0px', top: '0px', left: '0px', justifyContent: 'center', alignContent: 'center', height: '100%', flexWrap: 'wrap', zIndex: '1', alignItems: 'center' }}>
				<div id="modal-Public" className="modal-Postcode" style={{ display: "none" }}>
					<div className="modal_content">
						<div className='note-Input'>
							<IonCard style={{ background: '#214E71', borderRadius: '15px 15px 0 0', width: '740px', right: '10px', top: '-10px' }}><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Confirm Note Location</h1></IonCard>
							<div style={{ fontSize: '20px', margin: '12px' }}>Are you sure you want this note to be public?</div>
							<div className='note-Layout-Input'>
								<div className='note-Button-Placement'>
									<div style={{ display: "flex", flexDirection: "column" }}>
										<div style={{ display: "flex", justifyContent: "center" }}>
											<IonButton className='note-Button-Red' shape="round" onClick={() => saveLocation("Public-Denied")}>Cancel</IonButton>
											<IonButton className='note-Button-Green' shape="round" onClick={() => saveLocation("Public-Confirm")}>Confirm</IonButton>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id='filter-Container' style={{ display: 'none', position: 'fixed', right: '0px', top: '0px', left: '0px', justifyContent: 'center', alignContent: 'center', flexWrap: 'wrap', zIndex: '1', alignItems: 'center' }}>
				<div id="modal-Filter" className="modal-Filter" style={{ display: "flex" }}>
					<div className="modal_content">
						<div className='note-Input'>
							<IonCard style={{ background: '#214E71', borderRadius: '15px 15px 0 0', right: '10px', top: '-10px', width: '100%' }}>
								<h1 className='filter-Header'>  Filters
									<div className='filter-Close-Button' id="filter-Close" onClick={() => filterClick(1)} >
										<IonIcon slot='icon-only' className='icon-Only' icon={closeCircle} style={{ color: "white", right: '-13px', position: 'absolute' }} />
									</div>
								</h1></IonCard>
							<div className='note-Layout-Input'>
								<div className='filter-Segment-Placement'>
									<div>
										<div style={{ fontSize: '20px', margin: '12px' }}>Rota Load Blocks</div>
										<IonSegment value={RLBToggle} onIonChange={(e: any) => setRLBToggle(e.detail.value)} id="segment-Id-1" mode='ios'>
											<IonSegmentButton value="OFF">
												<IonLabel>Off</IonLabel>
											</IonSegmentButton>
											<IonSegmentButton value="ON">
												<IonLabel>On</IonLabel>
											</IonSegmentButton>
										</IonSegment>
									</div>
									<div>
										<div style={{ fontSize: '20px', margin: '12px' }}>Notes</div>
										<IonSegment value={noteToggle} onIonChange={(e: any) => setNoteToggle(e.detail.value)} id="segment-Id-2" mode='ios'>
											<IonSegmentButton value="OFF">
												<IonLabel>Off</IonLabel>
											</IonSegmentButton>
											<IonSegmentButton value="ON">
												<IonLabel>On</IonLabel>
											</IonSegmentButton>
										</IonSegment>
									</div>
									<div>
										<div style={{ fontSize: '20px', margin: '12px' }}>Faults</div>
										<IonSegment value={faultToggle} onIonChange={(e: any) => setFaultToggle(e.detail.value)} id="segment-Id-3" mode='ios'>
											<IonSegmentButton value="OFF">
												<IonLabel>Off</IonLabel>
											</IonSegmentButton>
											<IonSegmentButton value="ON">
												<IonLabel>On</IonLabel>
											</IonSegmentButton>
										</IonSegment>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
}

export default MapContent;
import './Home.css';
import HeadingBar from '../components/headingBarComponent/headingBarComponent';
import SideBar from '../components/sidebarComponent/sidebarComponent';
import MapContent, { BASE_URL } from '../components/mapContentComponent/mapContentComponent';
import { useEffect, useState } from 'react';
import { SidebarState, propertyNumbersState, customerNumberState, selectedAreaState, deleteButtonNumberState, deleteNoteReferenceState, deleteDateReferenceState, PostcodeConfirmedState, scenarioDayState, scenarioTimeState, scenarioStageState, noteLocationSelectionState, latLongState, deleteLocationReferenceState, editButtonNumberState, noteSaveCountState, resiliencePartnerState, markerClickedLocationState, lastSelectedAreaState, deleteIDReferenceState, markerArrayState, noteCancelledState, currentBoundryState, outsideOrInsideBoundryState, updateNotesState, deleteMarkerState, addMarkerToArrayState, tempMarkerStorageState, boundariesArrayState, addNoteAllLocationState, deleteDistrictReferenceState, noteToHighlightState, boundsZoomState, typeOfNoteActionState, highlightLastNoteState, noteTypeState, overlayArrayState, modalState, noteOrgState, mouseState, enableButtonsState, scenarioDayViewState, scenarioTimeViewState, scenarioStageViewState, propertyNumbersScenarioState, customerNumberScenarioState, sendScenarioDataState, mapOrSidebarClickState, lastBlocksState, lastScenarioTimeState, timeAndLevelButtonsState, clearScenarioState, RLBToggleState, faultToggleState, noteToggleState, previousNoteToggleState, previousFaultToggleState, previousRLBToggleState, InitialFlagState, faultMarkerArrayState, visibleOrHiddenFilterState, newNoteVisabilityState, linkedToFaultState, permNoteState, copiedReferenceState, noteExpiryDateState, faultReferenceToBeSavedState, noteMouseActiveState, highlightedNoteState, updateMapNotesState, sidebarTimerState, mapTimerState, refreshButtonState,  } from '../enums/enums';
import mapboxgl from 'mapbox-gl';
import { IonButton, IonCard, IonIcon } from '@ionic/react';
import { personCircleOutline } from 'ionicons/icons';

export const ACCESS_CREDENTIALS = { token: null, basicAuthentication: "" };

function Home() {
  let hash: string = window.location.hash;
  if (hash != null && hash.length > 1) {
    console.log(hash);
    try {
      if (hash.startsWith("#")) {
        hash = hash.substring(1);
      }
      let decoded = atob(hash);
      let token = JSON.parse(decoded);

      ACCESS_CREDENTIALS.token = token;
      ACCESS_CREDENTIALS.basicAuthentication = "Basic " + btoa(token.sub + ":" + token.key);

      //This removes the history
      window.history.pushState("", document.title, window.location.pathname
        + window.location.search)
    } catch (e) {
      console.log(e);
    }
  }
  console.log(ACCESS_CREDENTIALS.token);

  let [notesArray, setNotesArray] = useState([]);

  let [publicUserNotes, setPublicUserNotes] = useState([]);

  let [partnersUserNotes, setPartnersUserNotes] = useState([]);

  let [SSENUserNotes, setSSENUserNotes] = useState([]);

  let [publicNonUserNotes, setPublicNonUserNotes] = useState([]);

  let [partnersNonUserNotes, setPartnersNonUserNotes] = useState([]);

  let [SSENNonUserNotes, setSSENNonUserNotes] = useState([]);

  let [notesArrayNonUser, setNotesArrayNonUser] = useState([]);

  let [organisationArray, setOrganisationArray] = useState([]);

  let [blockColourArray] = useState({
    A: "hsl(0,50%,50%)",
    B: "hsl(12,50%,50%)",
    C: "hsl(23,50%,50%)",
    D: "hsl(35,50%,50%)",
    E: "hsl(47,50%,50%)",
    F: "hsl(58,50%,50%)",
    G: "hsl(70,50%,50%)",
    H: "hsl(81,50%,50%)",
    J: "hsl(93,50%,50%)",
    K: "hsl(105,50%,50%)",
    L: "hsl(116,50%,50%)",
    M: "hsl(128,50%,50%)",
    N: "hsl(140,50%,50%)",
    O: "hsl(151,50%,50%)",
    P: "hsl(163,50%,50%)",
    Q: "hsl(175,50%,50%)",
    R: "hsl(186,50%,50%)",
    S: "hsl(198,50%,50%)",
    T: "hsl(209,50%,50%)",
    U: "hsl(221,50%,50%)",
    V: "hsl(233,50%,50%)",
    Z: "hsl(244,50%,50%)",
  })

  let [lastSelectedArea, setLastSelectedArea] = useState(lastSelectedAreaState.UNSET);

  let [resiliencePartner, setResiliencePartner] = useState(resiliencePartnerState.UNSET);

  let [markerClickedLocation, setMarkerClickedLocation] = useState(markerClickedLocationState.UNSET);

  let [sidebarExpanded, setSidebarExpanded] = useState(SidebarState.EXPANDED);

  let [propertyNumbers, setpropertyNumbers] = useState<number>(propertyNumbersState.UNSET);
  let [customerNumbers, setCustomerNumbers] = useState<number>(customerNumberState.UNSET);

  let [noteLocationSelection, setNoteLocationSelection] = useState(noteLocationSelectionState.UNSET);

  let [overlayArray, setOverlayArray] = useState(overlayArrayState.UNSET);

  let [XCoordConfirmed, setXCoordConfirmed] = useState(PostcodeConfirmedState.UNSET);
  let [YCoordConfirmed, setYCoordConfirmed] = useState(PostcodeConfirmedState.UNSET);
  let [selectedArea, setSelectedArea] = useState<number>(selectedAreaState.UNSET);

  let [boundsZoom, setBoundsZoom] = useState(boundsZoomState.UNSET);

  let [typeOfNoteAction, setTypeOfNoteAction] = useState(typeOfNoteActionState.UNSET);

  let [highlightLastNote, setHighlightLastNote] = useState(highlightLastNoteState.UNSET);

  let [noteSaveCount, setNoteSaveCount] = useState(noteSaveCountState.UNSET);




  let [noteType, setNoteType] = useState(noteTypeState.UNSET);

  let [modalVisability, setModalVisability] = useState(modalState.HIDDEN);

  let [mouseAppearance, setMouseAppearance] = useState(mouseState.NORMAL);

  let [enableButtonsVal, setEnableButtons] = useState(enableButtonsState.UNSET)

  let [markerArray, setMarkerArray] = useState([]);

  let [faultArrayForSave, setfaultArrayForSave] = useState([]);

  

  let [markerCancelArray, setMarkerCancelArray] = useState(markerArrayState.UNSET);
  let [addMarkerToArray, setAddMarkerToArray] = useState(addMarkerToArrayState.UNSET);
  let [tempMarkerStorage, setTempMarkerStorage] = useState(tempMarkerStorageState.UNSET);
  let [noteMarkerType, setNoteMarkerType] = useState()
  let [currentBoundaries, setCurrentBoundaries] = useState(currentBoundryState.UNSET);
  let [outsideOrInsideBoundry, setOutsideOrInsideBoundry] = useState(outsideOrInsideBoundryState.UNSET);

  let [boundariesArray, setBoundariesArray] = useState(boundariesArrayState.UNSET);
  let [addNoteAllLocation, setAddNoteAllLocation] = useState(addNoteAllLocationState.UNSET);

  let [noteToHighlight, setNoteToHighlight] = useState(noteToHighlightState.UNSET)

  let [editButtonNumber, setEditButtonNumber] = useState(editButtonNumberState.UNSET);
  let [deleteButtonNumber, setDeleteButtonNumber] = useState(deleteButtonNumberState.UNSET);

  let [userDistrictIdArray, setUserDistrictIdArray] = useState([]);
  let [userDistrictNameArray, setUserDistrictNameArray] = useState([]);

  let [noteCancelled, setNoteCancelled] = useState(noteCancelledState.UNSET);
  let [updateNotes, setUpdateNotes] = useState(updateNotesState.UNSET);
  let [updateMapNotes, setUpdateMapNotes] = useState(updateMapNotesState.UNSET);

  let [deleteMarker, setDeleteMarker] = useState(deleteMarkerState.UNSET);

  let [mapOrSidebarClick, setmapOrSidebarClick] = useState(mapOrSidebarClickState.UNSET);

  let [deleteNoteReference, setDeleteNoteReference] = useState(deleteNoteReferenceState.UNSET);
  let [deleteDateReference, setDeleteDateReference] = useState(deleteDateReferenceState.UNSET);
  let [deleteLocationReferenceX, setDeleteLocationReferenceX] = useState(deleteLocationReferenceState.UNSET);
  let [deleteLocationReferenceY, setDeleteLocationReferenceY] = useState(deleteLocationReferenceState.UNSET);
  let [deleteDistrictReference, setDeleteDistrictReference] = useState(deleteDistrictReferenceState.UNSET);
  let [deleteIDReference, setDeleteIDReference] = useState(deleteIDReferenceState.UNSET);

  let [scenarioDay, setScenarioDay] = useState(scenarioDayState.UNSET);
  let [scenarioTime, setScenarioTime] = useState(scenarioTimeState.UNSET);
  let [scenarioStage, setScenarioStage] = useState(scenarioStageState.UNSET);

  let [scenarioDayView, setScenarioDayView] = useState(scenarioDayViewState.UNSET);
  let [scenarioTimeView, setScenarioTimeView] = useState(scenarioTimeViewState.UNSET);
  let [scenarioStageView, setScenarioStageView] = useState(scenarioStageViewState.UNSET);

  let [propertyNumbersScenario, setpropertyNumbersScenario] = useState<number>(propertyNumbersScenarioState.UNSET);
  let [customerNumbersScenario, setCustomerNumbersScenario] = useState<number>(customerNumberScenarioState.UNSET);

  let [sendScenarioDataFlag, setSendScenarioDataFlag] = useState<number>(sendScenarioDataState.UNSET);

  let [latLong, setLatLong] = useState(latLongState.UNSET);

  let [noteOrg, setNoteOrg] = useState(noteOrgState.UNSET)

  let [lastBlocks, setLastBlocks] = useState(lastBlocksState.UNSET)

  let [lastScenarioTime, setLastScenarioTime] = useState(lastScenarioTimeState.UNSET)

  let [timeAndLevelButtons, setTimeAndLevelButtons] = useState(timeAndLevelButtonsState.UNSET)

  let [clearScenario, setClearScenario] = useState(clearScenarioState.UNSET)

  let [RLBToggle, setRLBToggle] = useState(RLBToggleState.UNSET)
  let [previousRLBToggle, setPreviousRLBToggle] = useState(previousRLBToggleState.UNSET)

  let [noteToggle, setNoteToggle] = useState(noteToggleState.UNSET)
  let [previousNoteToggle, setPreviousNoteToggle] = useState(previousNoteToggleState.UNSET)

  let [faultToggle, setFaultToggle] = useState(faultToggleState.UNSET)
  let [previousFaultToggle, setPreviousFaultToggle] = useState(previousFaultToggleState.UNSET)

  let [initialFlag, setInitialFlag] = useState(InitialFlagState.UNSET)

  let [faultMarkerArray, setFaultMarkerArray] = useState(faultMarkerArrayState.UNSET)

  let [visibleOrHiddenFilter, setVisibleOrHiddenFilter] = useState(visibleOrHiddenFilterState.UNSET)

  let [newNoteVisability, setNewNoteVisability] = useState(newNoteVisabilityState.UNSET)

  let [linkedToFault, setLinkedToFault] = useState(linkedToFaultState.UNSET)

  let [permNote, setPermNote] = useState(permNoteState.UNSET)

  let [copiedReference, setCopiedReference] = useState(copiedReferenceState.UNSET)

  let [noteExpiryDate, setNoteExpiryDate] = useState(noteExpiryDateState.UNSET)

  let [faultReferenceToBeSaved, setFaultReferenceToBeSaved] = useState(faultReferenceToBeSavedState.UNSET)

  let [noteMouseActive, setNoteMouseActive] = useState(noteMouseActiveState.UNSET);

  let [highlightedNote, setHighlightedNote] = useState(highlightedNoteState.UNSET)

  let [sidebarTimer, setSidebarTimer] = useState(sidebarTimerState.UNSET)
  let [mapTimer, setMapTimer] = useState(mapTimerState.UNSET)

  let [refreshButton, setRefreshButton] = useState(refreshButtonState.UNSET)

  return (
    <>
      <HeadingBar></HeadingBar>
      <div id="page-Content" className={`${sidebarExpanded === SidebarState.EXPANDED ? 'sidebar-Shown' : 'sidebar-Hidden'} ${modalVisability === modalState.HIDDEN ? 'modal-Hidden' : 'modal-Shown'}  ${mouseAppearance === mouseState.NORMAL ? 'mouse-Normal' : 'mouse-Marker'}`}>
        <MapContent tempMarkerStorage={tempMarkerStorage} setTempMarkerStorage={setTempMarkerStorage} addMarkerToArray={addMarkerToArray} setAddMarkerToArray={setAddMarkerToArray} setNoteMarkerType={setNoteMarkerType} setOutsideOrInsideBoundry={setOutsideOrInsideBoundry} setDeleteMarker={setDeleteMarker} deleteMarker={deleteMarker}
          currentBoundaries={currentBoundaries} setCurrentBoundaries={setCurrentBoundaries} setNoteCancelled={setNoteCancelled} noteCancelled={noteCancelled} markerCancelArray={markerCancelArray} setMarkerCancelArray={setMarkerCancelArray}
          resiliencePartner={resiliencePartner} markerArray={markerArray} setMarkerArray={setMarkerArray} lastSelectedArea={lastSelectedArea}
          setpropertyNumbers={setpropertyNumbers} setMarkerClickedLocation={setMarkerClickedLocation} setXCoordConfirmed={setXCoordConfirmed}
          setYCoordConfirmed={setYCoordConfirmed} setCustomerNumbers={setCustomerNumbers} deleteButtonNumber={deleteButtonNumber} setLatLong={setLatLong} latLong={latLong} selectedArea={selectedArea}
          noteLocationSelection={noteLocationSelection} setNoteLocationSelection={setNoteLocationSelection} boundariesArray={boundariesArray} setBoundariesArray={setBoundariesArray}
          setAddNoteAllLocation={setAddNoteAllLocation} userDistrictIdArray={userDistrictIdArray} userDistrictNameArray={userDistrictNameArray} noteToHighlight={noteToHighlight} setNoteToHighlight={setNoteToHighlight}
          boundsZoom={boundsZoom} setBoundsZoom={setBoundsZoom}
          noteType={noteType} overlayArray={overlayArray} setOverlayArray={setOverlayArray} setModalVisability={setModalVisability} setpropertyNumbersScenario={setpropertyNumbersScenario} setCustomerNumbersScenario={setCustomerNumbersScenario}
          scenarioDay={scenarioDay} scenarioTime={scenarioTime} scenarioStage={scenarioStage} noteMarkerType={noteMarkerType}
          setMouseAppearance={setMouseAppearance} setEnableButtons={setEnableButtons} sendScenarioDataFlag={sendScenarioDataFlag} setSendScenarioDataFlag={setSendScenarioDataFlag} mapOrSidebarClick={mapOrSidebarClick} setmapOrSidebarClick={setmapOrSidebarClick} lastBlocks={lastBlocks} setLastBlocks={setLastBlocks}
          lastScenarioTime={lastScenarioTime} setLastScenarioTime={setLastScenarioTime} blockColourArray={blockColourArray} timeAndLevelButtons={timeAndLevelButtons} setTimeAndLevelButtons={setTimeAndLevelButtons} clearScenario={clearScenario} setClearScenario={setClearScenario} RLBToggle={RLBToggle} setRLBToggle={setRLBToggle}
          noteToggle={noteToggle} setNoteToggle={setNoteToggle} faultToggle={faultToggle} setFaultToggle={setFaultToggle} previousNoteToggle={previousNoteToggle} setPreviousNoteToggle={setPreviousNoteToggle} previousRLBToggle={previousRLBToggle} setPreviousRLBToggle={setPreviousRLBToggle} previousFaultToggle={previousFaultToggle} setPreviousFaultToggle={setPreviousFaultToggle}
          initialFlag={initialFlag} setInitialFlag={setInitialFlag} faultMarkerArray={faultMarkerArray} setFaultMarkerArray={setFaultMarkerArray} visibleOrHiddenFilter={visibleOrHiddenFilter} setVisibleOrHiddenFilter={setVisibleOrHiddenFilter} newNoteVisability={newNoteVisability} setNewNoteVisability={setNewNoteVisability}
          copiedReference={copiedReference} setCopiedReference={setCopiedReference} faultReferenceToBeSaved={faultReferenceToBeSaved} setFaultReferenceToBeSaved={setFaultReferenceToBeSaved}
          publicUserNotes={publicUserNotes} setPublicUserNotes={setPublicUserNotes} partnersUserNotes={partnersUserNotes} setPartnersUserNotes={setPartnersUserNotes} SSENUserNotes={SSENUserNotes} setSSENUserNotes={setSSENUserNotes}

          publicNonUserNotes={publicNonUserNotes} setPublicNonUserNotes={setPublicNonUserNotes} partnersNonUserNotes={partnersNonUserNotes} setPartnersNonUserNotes={setPartnersNonUserNotes} SSENNonUserNotes={SSENNonUserNotes} setSSENNonUserNotes={setSSENNonUserNotes}
          noteMouseActive={noteMouseActive} setNoteMouseActive={setNoteMouseActive} highlightedNote={highlightedNote} setHighlightedNote={setHighlightedNote}
          updateMapNotes={updateMapNotes} setUpdateMapNotes={setUpdateMapNotes}  updateNotes={updateNotes}  setfaultArrayForSave={setfaultArrayForSave} mapTimer={mapTimer} setmapTimer={setMapTimer} setRefreshButton={setRefreshButton} setUpdateNotes={setUpdateNotes} 
        />


        <SideBar selectedArea={selectedArea} scenarioDay={scenarioDay} scenarioTime={scenarioTime} scenarioStage={scenarioStage} propertyNumbers={propertyNumbers} sidebarExpanded={sidebarExpanded} customerNumbers={customerNumbers}
          XCoordConfirmed={XCoordConfirmed} YCoordConfirmed={YCoordConfirmed} setEnableButtons={setEnableButtons} enableButtonsVal={enableButtonsVal} timeAndLevelButtons={timeAndLevelButtons} setTimeAndLevelButtons={setTimeAndLevelButtons}

          typeOfNoteAction={typeOfNoteAction} setTypeOfNoteAction={setTypeOfNoteAction} newNoteVisability={newNoteVisability} setNewNoteVisability={setNewNoteVisability} faultArrayForSave={faultArrayForSave}

          noteSaveCount={noteSaveCount} setNoteSaveCount={setNoteSaveCount} noteToHighlight={noteToHighlight} setNoteToHighlight={setNoteToHighlight} highlightLastNote={highlightLastNote} setHighlightLastNote={setHighlightLastNote} propertyNumbersScenario={propertyNumbersScenario}
          userDistrictIdArray={userDistrictIdArray} setUserDistrictIdArray={setUserDistrictIdArray} userDistrictNameArray={userDistrictNameArray} setUserDistrictNameArray={setUserDistrictNameArray} customerNumbersScenario={customerNumbersScenario}

          markerClickedLocation={markerClickedLocation} setMarkerClickedLocation={setMarkerClickedLocation} noteMarkerType={noteMarkerType} setNoteMarkerType={setNoteMarkerType} scenarioDayView={scenarioDayView} setScenarioDayView={setScenarioDayView}

          setNoteCancelled={setNoteCancelled} updateNotes={updateNotes} setUpdateNotes={setUpdateNotes} setDeleteMarker={setDeleteMarker} setAddMarkerToArray={setAddMarkerToArray} scenarioTimeView={scenarioTimeView} setScenarioTimeView={setScenarioTimeView}

          addNoteAllLocation={addNoteAllLocation} noteOrg={noteOrg} setNoteOrg={setNoteOrg} scenarioStageView={scenarioStageView} setScenarioStageView={setScenarioStageView}

          setLastSelectedArea={setLastSelectedArea} lastSelectedArea={lastSelectedArea} deleteDistrictReference={deleteDistrictReference} setDeleteDistrictReference={setDeleteDistrictReference} setmapOrSidebarClick={setmapOrSidebarClick}

          outsideOrInsideBoundry={outsideOrInsideBoundry} setOutsideOrInsideBoundry={setOutsideOrInsideBoundry}

          deleteButtonNumber={deleteButtonNumber} deleteNoteReference={deleteNoteReference} deleteDateReference={deleteDateReference} setMouseAppearance={setMouseAppearance}

          resiliencePartner={resiliencePartner} setResiliencePartner={setResiliencePartner} faultReferenceToBeSaved={faultReferenceToBeSaved} setFaultReferenceToBeSaved={setFaultReferenceToBeSaved}

          setDeleteDateReference={setDeleteDateReference} setDeleteNoteReference={setDeleteNoteReference}

          setDeleteButtonNumber={setDeleteButtonNumber} setSelectedArea={setSelectedArea} setSidebarExpanded={setSidebarExpanded}

          setEditButtonNumber={setEditButtonNumber} editButtonNumber={editButtonNumber} noteType={noteType} setNoteType={setNoteType} setLinkedToFault={setLinkedToFault} linkedToFault={linkedToFault} permNote={permNote} setPermNote={setPermNote}

          setScenarioStage={setScenarioStage} setScenarioTime={setScenarioTime} setScenarioDay={setScenarioDay} setNoteLocationSelection={setNoteLocationSelection}
          setDeleteLocationReferenceX={setDeleteLocationReferenceX} deleteLocationReferenceX={deleteLocationReferenceX} setDeleteLocationReferenceY={setDeleteLocationReferenceY}
          deleteLocationReferenceY={deleteLocationReferenceY} deleteIDReference={deleteIDReference} setDeleteIDReference={setDeleteIDReference}

          notesArray={notesArray} setNotesArray={setNotesArray} notesArrayNonUser={notesArrayNonUser} setNotesArrayNonUser={setNotesArrayNonUser} organisationArray={organisationArray}
          publicUserNotes={publicUserNotes} setPublicUserNotes={setPublicUserNotes} partnersUserNotes={partnersUserNotes} setPartnersUserNotes={setPartnersUserNotes} SSENUserNotes={SSENUserNotes} setSSENUserNotes={setSSENUserNotes}

          publicNonUserNotes={publicNonUserNotes} setPublicNonUserNotes={setPublicNonUserNotes} partnersNonUserNotes={partnersNonUserNotes} setPartnersNonUserNotes={setPartnersNonUserNotes} SSENNonUserNotes={SSENNonUserNotes} setSSENNonUserNotes={setSSENNonUserNotes}

          setOrganisationArray={setOrganisationArray} setModalVisability={setModalVisability} modalVisability={modalVisability} mouseAppearance={mouseAppearance} sendScenarioDataFlag={sendScenarioDataFlag} setSendScenarioDataFlag={setSendScenarioDataFlag}
          clearScenario={clearScenario} setClearScenario={setClearScenario} noteToggle={noteToggle} RLBToggle={RLBToggle} noteExpiryDate={noteExpiryDate} setNoteExpiryDate={setNoteExpiryDate}
          noteMouseActive={noteMouseActive} setNoteMouseActive={setNoteMouseActive} sidebarTimer={sidebarTimer} setSidebarTimer={setSidebarTimer} refreshButton={refreshButton} setRefreshButton={setRefreshButton}
        />
      </div>
      <div style={{ display: 'flex', position: 'absolute', right: '0px' }}>
        <IonCard className='login-Card'>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ display: 'flex', margin: '0 1rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ fontSize: '13px', margin: '3px', position: 'relative', left: '4px' }}>
                  {resiliencePartner}
                </div>
                <div style={{ fontSize: '11px' }}>
                  Resilience Partner
                </div>
              </div>
              <div style={{ display: 'flex', position: 'relative', left: '4px' }}>
                <IonIcon className='login-Icon' id='chevronUpCircle' icon={personCircleOutline} />
              </div>
            </div>
            <IonButton className='logout-Button' href={BASE_URL + '/renaissance/common/service/authenticate?callback=' + window.location}>Log In</IonButton>
          </div>
        </IonCard>
      </div>
    </>
  );
}

export default Home;

export enum resiliencePartnerState {
  UNSET = "Please Log In"
}

export enum numberOfNotesState {
  UNSET
}

export enum updateNotesState {
  UNSET
}

export enum updateMapNotesState {
  UNSET
}


export enum deleteMarkerState {
  UNSET
}

export enum tempMarkerStorageState {
  UNSET
}

export enum boundariesArrayState {
  UNSET
}

export enum addMarkerToArrayState {
  UNSET
}

export enum lastScenarioTimeState {
    UNSET
}

export enum markerArrayState {
  UNSET = "Test if this is issue"
}

export enum addNoteAllLocationState {
  UNSET
}

export enum currentBoundryState {
  UNSET
}

export enum outsideOrInsideBoundryState {
  UNSET
}

export enum noteMarkerTypeState {
  UNSET
}

export enum noteCancelledState {
  UNSET
}

export enum markerClickedLocationState {
  UNSET
}

export enum propertyNumbersState {
  UNSET = 0
}

export enum customerNumberState {
  UNSET = 0
}

export enum propertyNumbersScenarioState {
  UNSET = 0
}

export enum customerNumberScenarioState {
  UNSET = 0
}

export enum sendScenarioDataState {
  UNSET
}

export enum mapOrSidebarClickState {
  UNSET
}

export enum noteLocationSelectionState {
  UNSET
}

export enum latLongState {
  UNSET
}

export enum PR1ExtendedState {
  EXPANDED,
  COLLAPSED,
}

export enum PostcodeConfirmedState {
  UNSET
}

export enum selectedAreaState {
  UNSET
}

export enum noteCountState {
  UNSET
}

export enum noteSaveCountState {
  UNSET
}

export enum noteSaveCountNonUserState {
  UNSET
}

export enum editButtonNumberState {
  UNSET
}

export enum deleteButtonNumberState {
  UNSET
}

export enum deleteNoteReferenceState {
  UNSET
}

export enum deleteDateReferenceState {
  UNSET
}

export enum deleteDateEditReferenceState {
  UNSET
}

export enum deleteLocationReferenceState {
  UNSET
}

export enum deleteIDReferenceState {
  UNSET
}

export enum deleteDistrictReferenceState {
  UNSET
}

export enum scenarioDayState {
  UNSET
}

export enum scenarioTimeState {
  UNSET
}

export enum scenarioStageState {
  UNSET
}

export enum scenarioDayViewState {
  UNSET
}

export enum scenarioTimeViewState {
  UNSET
}

export enum scenarioStageViewState {
  UNSET
}

export enum SidebarState {
  EXPANDED,
  COLLAPSED,
}

export enum lastSelectedAreaState {
  UNSET
}

export enum noteToHighlightState {
  UNSET
}

export enum boundsZoomState {
  UNSET
}

export enum typeOfNoteActionState {
  UNSET
}

export enum highlightLastNoteState {
  UNSET
}

export enum noteTypeState {
  UNSET
}

export enum overlayArrayState {
  UNSET
}


export enum modalState {
  SHOWN,
  HIDDEN
}

export enum noteOrgState {
  UNSET
}

export enum mouseState {
  MARKER,
  NORMAL
}

export enum enableButtonsState {
  UNSET
}

export enum lastBlocksState {
  UNSET
}

export enum blockColourArrayState {

}

export enum timeAndLevelButtonsState {
  UNSET
}

export enum clearScenarioState {
  UNSET
}

export enum RLBToggleState {
  UNSET
}

export enum noteToggleState {
  UNSET
}

export enum faultToggleState {
  UNSET
}

export enum previousRLBToggleState {
  UNSET
}

export enum previousNoteToggleState {
  UNSET
}

export enum previousFaultToggleState {
  UNSET
}

export enum InitialFlagState {
  UNSET
}

export enum faultMarkerArrayState {
  UNSET
}

export enum visibleOrHiddenFilterState {
  UNSET
}

export enum newNoteVisabilityState {
  UNSET
}

export enum linkedToFaultState {
  UNSET
}

export enum permNoteState {
  UNSET
}

export enum copiedReferenceState {
  UNSET
}

export enum noteExpiryDateState {
  UNSET
}

export enum faultReferenceToBeSavedState {
  UNSET
}

export enum publicUserNotesState{
  UNSET
}

export enum partnersUserNotesState{
  UNSET
}

export enum SSENUserNotesState{
  UNSET
}


export enum publicNonUserNotesState{
  UNSET
}

export enum partnersNonUserNotesState{
  UNSET
}

export enum SSENNonUserNotesState{
  UNSET
}

export enum noteMouseActiveState {
  UNSET
}

export enum highlightedNoteState {
  UNSET
}

export enum faultArrayForSaveState {
  UNSET
}

export enum sidebarTimerState {
  UNSET
}

export enum mapTimerState {
  UNSET
}

export enum mapTimerArrayState {
  UNSET
}

export enum refreshButtonState {
  UNSET
}
import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCheckbox, IonContent, IonDatetime, IonDatetimeButton, IonHeader, IonIcon, IonLabel, IonModal, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTextarea, IonTitle } from '@ionic/react';
import './sidebarComponentStyle.css';
import { chevronDown, chevronForwardCircle, chevronUp, chevronDownCircle, closeCircleOutline, create, personCircleOutline, timeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { SidebarState, propertyNumbersState, customerNumberState, deleteButtonNumberState, deleteNoteReferenceState, deleteDateReferenceState, PostcodeConfirmedState, scenarioDayState, scenarioTimeState, scenarioStageState, deleteLocationReferenceState, editButtonNumberState, noteSaveCountState, resiliencePartnerState, deleteIDReferenceState, outsideOrInsideBoundryState, updateNotesState, modalState, mouseState } from '../../enums/enums';
import { BASE_URL } from '../mapContentComponent/mapContentComponent';
import { ACCESS_CREDENTIALS } from '../../pages/Home';


type sideBarProps = {
  resiliencePartner: resiliencePartnerState;
  setResiliencePartner: any;

  setLastSelectedArea: any;
  lastSelectedArea: any;
  organisationArray: any;
  setOrganisationArray: any;
  setOutsideOrInsideBoundry: any;
  setMarkerClickedLocation: any;
  markerClickedLocation: any;
  propertyNumbers: propertyNumbersState;
  sidebarExpanded: SidebarState;
  customerNumbers: customerNumberState;
  scenarioDay: scenarioDayState;
  scenarioTime: scenarioTimeState;
  scenarioStage: scenarioStageState;
  selectedArea: any;

  setmapOrSidebarClick: any;

  scenarioStageView: any;
  setScenarioStageView: any;
  scenarioDayView: any;
  setScenarioDayView: any;
  scenarioTimeView: any;
  setScenarioTimeView: any;


  customerNumbersScenario: any;
  propertyNumbersScenario: any;


  setEnableButtons: any;
  enableButtonsVal: any;

  noteSaveCount: noteSaveCountState;
  setNoteSaveCount: any;

  outsideOrInsideBoundry: outsideOrInsideBoundryState;
  setMouseAppearance: any
  noteType: any;
  setNoteType: any;

  notesArray: any;
  setNotesArray: any;
  notesArrayNonUser: any;
  setNotesArrayNonUser: any;

  publicUserNotes: any;
  setPublicUserNotes: any;
  partnersUserNotes: any;
  setPartnersUserNotes: any;
  SSENUserNotes: any;
  setSSENUserNotes: any;

  publicNonUserNotes: any;
  setPublicNonUserNotes: any;
  partnersNonUserNotes: any;
  setPartnersNonUserNotes: any;
  SSENNonUserNotes: any;
  setSSENNonUserNotes: any;

  setDeleteMarker: any;
  updateNotes: updateNotesState;
  setUpdateNotes: any;

  highlightLastNote: any;
  setHighlightLastNote: any;

  sendScenarioDataFlag: any;
  setSendScenarioDataFlag: any;

  typeOfNoteAction: any;
  setTypeOfNoteAction: any;


  setNoteCancelled: any;

  setAddMarkerToArray: any;

  userDistrictIdArray: any;
  setUserDistrictIdArray: any;

  userDistrictNameArray: any;
  setUserDistrictNameArray: any;


  editButtonNumber: editButtonNumberState;
  setEditButtonNumber: any;

  deleteButtonNumber: deleteButtonNumberState;
  deleteNoteReference: deleteNoteReferenceState;
  deleteDateReference: deleteDateReferenceState;
  deleteLocationReferenceX: deleteLocationReferenceState;
  deleteLocationReferenceY: deleteLocationReferenceState;
  deleteIDReference: deleteIDReferenceState;

  setDeleteButtonNumber: any;
  setDeleteDateReference: any;
  setDeleteNoteReference: any;
  setDeleteLocationReferenceX: any;
  setDeleteLocationReferenceY: any;
  setDeleteIDReference: any;
  deleteDistrictReference: any;
  setDeleteDistrictReference: any;
  noteMarkerType: any;
  setNoteMarkerType: any;
  addNoteAllLocation: any;

  XCoordConfirmed: PostcodeConfirmedState;
  YCoordConfirmed: PostcodeConfirmedState;
  setNoteLocationSelection: any;
  setScenarioDay: any;
  setScenarioTime: any;
  setScenarioStage: any;
  setSelectedArea: any;

  noteToHighlight: any;
  setNoteToHighlight: any;

  setModalVisability: any;
  modalVisability: any;

  noteOrg: any;
  setNoteOrg: any;

  mouseAppearance: any;

  timeAndLevelButtons: any;
  setTimeAndLevelButtons: any

  clearScenario: any;
  setClearScenario: any;
  noteToggle: any;
  RLBToggle: any;


  newNoteVisability: any;
  setNewNoteVisability: any;

  linkedToFault: any;
  setLinkedToFault: any;

  permNote: any;
  setPermNote: any;

  noteExpiryDate: any;
  setNoteExpiryDate: any;

  faultReferenceToBeSaved: any;
  setFaultReferenceToBeSaved: any;

  noteMouseActive: any;
  setNoteMouseActive: any;

  faultArrayForSave: any;
  sidebarTimer: any;
  setSidebarTimer: any;

  refreshButton: any;

  setRefreshButton: any;


  setSidebarExpanded: (sidebarExpanded: SidebarState) => void;
}

const SideBar: React.FC<sideBarProps> = ({ propertyNumbers, scenarioDay, scenarioTime, scenarioStage, setScenarioDay, setScenarioTime, setScenarioStage, sidebarExpanded, XCoordConfirmed, YCoordConfirmed, customerNumbers,

  setNoteType, enableButtonsVal, setEnableButtons, sidebarTimer, setSidebarTimer,

  deleteButtonNumber, deleteDateReference, setDeleteDateReference, setDeleteNoteReference, setDeleteButtonNumber, setSelectedArea, setSidebarExpanded,
  setNoteLocationSelection, setDeleteLocationReferenceX, setDeleteLocationReferenceY, deleteLocationReferenceX, deleteLocationReferenceY, deleteIDReference, setDeleteIDReference, selectedArea,

  resiliencePartner, setResiliencePartner, setLastSelectedArea, lastSelectedArea, setNoteCancelled, outsideOrInsideBoundry, setmapOrSidebarClick,

  notesArray, setNotesArray, notesArrayNonUser, updateNotes, setUpdateNotes, deleteDistrictReference, typeOfNoteAction, setTypeOfNoteAction,
  setDeleteDistrictReference, setNoteToHighlight,

  organisationArray, setOrganisationArray, setOutsideOrInsideBoundry, setDeleteMarker, highlightLastNote, setHighlightLastNote,

  markerClickedLocation, setMarkerClickedLocation, noteMarkerType, setNoteMarkerType, setAddMarkerToArray, customerNumbersScenario, propertyNumbersScenario,
  setNoteSaveCount, noteSaveCount, addNoteAllLocation,
  scenarioStageView, setScenarioStageView, scenarioDayView,
  setScenarioDayView, scenarioTimeView, setScenarioTimeView, setEditButtonNumber,
  setUserDistrictIdArray, setUserDistrictNameArray, userDistrictIdArray, userDistrictNameArray,

  setModalVisability, modalVisability, noteOrg, setNoteOrg, setMouseAppearance, mouseAppearance, setSendScenarioDataFlag, timeAndLevelButtons,
  setClearScenario, noteToggle, RLBToggle, newNoteVisability,
  linkedToFault, setLinkedToFault, permNote, setPermNote, noteExpiryDate, setNoteExpiryDate, faultReferenceToBeSaved, setFaultReferenceToBeSaved,
  publicUserNotes, setPublicUserNotes, partnersUserNotes, setPartnersUserNotes, SSENUserNotes, setSSENUserNotes,

  publicNonUserNotes,
  setPublicNonUserNotes,
  partnersNonUserNotes,
  setPartnersNonUserNotes,
  SSENNonUserNotes,
  setSSENNonUserNotes,

  noteMouseActive,
  setNoteMouseActive,
  faultArrayForSave,
  setRefreshButton,
  refreshButton
}) => {

  let liveFaultsPath = "https://ssen-powertrack-api.opcld.com/gridiview/reporter/info/livefaults"


  useEffect(() => { //This use effect sets up all necessary variables for basic use such as dsiricts user has access to, only obtainable with a log in 
    let pathLogin: any;
    let headersForLogin = new Headers();
    let tempDistrictIDArray: any;
    let tempDistrictNameArray: any;

    tempDistrictIDArray = [];
    tempDistrictNameArray = [];
    pathLogin = BASE_URL + "/gridim/rotaload/partners?action=getUserAccessDetails";


    headersForLogin.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);

    fetch(pathLogin, { headers: headersForLogin })
      .then(function (response) { return response.json(); })
      .then(async function (userDetailsJson) {

        if (userDetailsJson != "") {
          try {

            const response = await fetch(liveFaultsPath);
          }
          catch (err) {

            segmentActivation = document.getElementById('segment-Id-1');
            segmentActivation.disabled = true;

            segmentActivation2 = document.getElementById('segment-Id-2');
            segmentActivation2.disabled = true;

            segmentActivation3 = document.getElementById('segment-Id-3');
            segmentActivation3.disabled = true;


            ionSelectActive = document.getElementById('ion-select-ID');
            ionSelectActive.disabled = true;

            alert("Fault fetching is currently down, please contact support about error.");
          }

        }

        setResiliencePartner(userDetailsJson.organisation);
        for (let i = 0; i < userDetailsJson.districts.length; i++) {
          tempDistrictIDArray.push(userDetailsJson.districts[i].id);
          tempDistrictNameArray.push(userDetailsJson.districts[i].name);
        }
        setUserDistrictIdArray(tempDistrictIDArray.sort());
        setUserDistrictNameArray(tempDistrictNameArray.sort());
      })
    setNoteSaveCount(1);
    setScenarioTime("");
    setScenarioDay("");
    setScenarioStage("");
    setScenarioStageView("")
    setScenarioDayView("")
    setScenarioTimeView("")
    setSidebarExpanded(SidebarState.EXPANDED);
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);

  const [mouseDownPos, setmouseDownPos] = useState(0);
  const [mouseUpPos, setmouseUpPos] = useState(0);

  useEffect(() => {
    const mousePosDiff = mouseDownPos - mouseUpPos;
    if (mousePosDiff < -50) {
      setSidebarExpanded(SidebarState.COLLAPSED);
    } else if (mousePosDiff > 5) {
      setSidebarExpanded(SidebarState.EXPANDED);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mouseUpPos]
  );
  //The majority of these are used to enable style changes, the others are used to store values before setting them, can be moved but will results in several local veriables instead of these global ones.
  let modal: any;
  let modal_Edit: any;
  let modal_Delete: any;
  let modal_Scenario: any;
  let saved_Note_Text: any;
  let modal_OutOfBounds: any;
  let stageSelection: any;
  let modal_Container: any;
  let daySelection: any;
  let timeSelection: any;
  let stageIncrement: any;
  let stageReduce: any;
  let timeIncrement: any;
  let timeReduce: any;
  let modal_Edit_Line: any;
  let scenarioSelected1: any;
  let scenarioSelected2: any;
  let scenarioSelected3: any;
  let data_Cards_Style_Attempt: any;
  let scenario_Planner_Style: any;
  let scenario_Planner_Style_2: any;
  let Note_Section: any;
  let scenario_Planner_Buttons: any;
  let PR_Note_Non_User_Extend: any;

  let public_Note_Non_User: any;
  let partner_Note_Non_User: any;
  let SSEN_Note_Non_User: any;
  let public_Note_Chevron_Non_User: any;
  let partner_Note_Chevron_Non_User: any;
  let SSEN_Note_Chevron_Non_User: any;

  let chevronDirectionNonUser: any;
  let PR_Note_User_Extend: any;
  let public_Note_User_Extend: any;
  let partner_Note_User_Extend: any;
  let SSEN_Note_User_Extend: any;
  let chevronDirectionUser: any;
  let public_Note_Chevron: any;
  let partner_Note_Chevron: any;
  let SSEN_Note_Chevron: any;
  let level_Buttons: any;
  let time_Buttons: any;
  let modal_Delete_Line: any;
  let Card_Design: any;
  let modal_Show: any;
  let modal_Show_Line: any;
  let modal_Show_NonUser: any;
  let modal_Show_Line_NonUser: any;
  let buttonShow: any;
  let buttonEdit: any;
  let buttonDelete: any;
  let addNoteButton: any;
  let scenarioButton: any;
  let incrementTimeVal: any;
  let reduceTimeVal: any;
  let incrementStageVal: any;
  let reduceStageVal: any;
  let segmentActivation: any;
  let segmentActivation2: any;
  let segmentActivation3: any;
  let ionSelectActive: any;
  let dottedLine: any;
  let dottedLine2: any;
  let noteExtendActivate: any;
  let scenarioClear: any;
  let updateTimerSidebar: any;

  let note_Card_Button_Conatiner: any;





  let reresh_Note_Button: any;


  useEffect(() => {
    if (refreshButton === "Disabled") { // Use effect for note toggle coming on and affecting the sidebar by adding the notes
      reresh_Note_Button = document.getElementById('refresh-Note-Button');
      reresh_Note_Button.disabled = 'true';
    } else if (refreshButton === "Enabled") {
      reresh_Note_Button = document.getElementById('refresh-Note-Button');
      reresh_Note_Button.disabled = 'false';
    }
  }, [refreshButton])




  useEffect(() => {
    if (noteToggle === "ON") { // Use effect for note toggle coming on and affecting the sidebar by adding the notes

      scenario_Planner_Style_2 = document.getElementById('scenario-Planner-Style-2');
      scenario_Planner_Style_2.style.display = 'block';
      Note_Section = document.getElementById('note-Container');
      Note_Section.style.display = 'flex';
      dottedLine2 = document.getElementById('horizontal-dotted-line-2');
      dottedLine2.style.display = "flex";

      // If user toggles note mode then all of the necessary buttons, cards and, labels appear. 

      let fakeArrayTest: any;
      fakeArrayTest = [];
      setOrganisationArray(fakeArrayTest);
      // Sets all arrays to an empty array so they can be refilled easier. 

      setUpdateNotes("Update");
      setTypeOfNoteAction("Show Notes");
      // Updates note to show RLD notes. 
    } if (noteToggle === "OFF") {
      scenario_Planner_Style_2 = document.getElementById('scenario-Planner-Style-2');
      scenario_Planner_Style_2.style.display = 'none';
      Note_Section = document.getElementById('note-Container');
      Note_Section.style.display = 'none';
      dottedLine2 = document.getElementById('horizontal-dotted-line-2');
      dottedLine2.style.display = "none";
    }
  }, [noteToggle])



  useEffect(() => {
    if (RLBToggle === "ON") {  // Updates when RLB is toggled on/off

      scenario_Planner_Style = document.getElementById('scenario-Planner-Style');
      scenario_Planner_Style.style.display = 'block';
      scenario_Planner_Buttons = document.getElementById('scenario-Planner-Buttons');
      scenario_Planner_Buttons.disabled = 'false';
      scenario_Planner_Buttons.style.display = 'flex';
      dottedLine = document.getElementById('horizontal-dotted-line');
      dottedLine.style.display = "flex";
      setUpdateNotes("Update");
    } if (RLBToggle === "OFF") {
      setUpdateNotes("Update");

      scenario_Planner_Style = document.getElementById('scenario-Planner-Style');
      scenario_Planner_Style.style.display = 'none';

      scenario_Planner_Buttons = document.getElementById('scenario-Planner-Buttons');
      scenario_Planner_Buttons.disabled = 'true';
      scenario_Planner_Buttons.style.display = 'none';

      dottedLine = document.getElementById('horizontal-dotted-line');
      dottedLine.style.display = "none";

      level_Buttons = document.getElementById('level-Buttons');
      level_Buttons.style.display = 'none';
      time_Buttons = document.getElementById('time-Buttons');
      time_Buttons.style.display = 'none';

      scenarioSelected2 = document.getElementById('scenario-Planner-Section-2');
      scenarioSelected2.style.display = 'none';
      scenarioSelected3 = document.getElementById('scenario-Planner-Section-3');
      scenarioSelected3.style.display = 'none';
      stageSelection = document.getElementById('stage-Selection');
      daySelection = document.getElementById('day-Selection');
      timeSelection = document.getElementById('time-Selection');
      stageIncrement = document.getElementById('stage-Increment');
      stageReduce = document.getElementById('stage-Reduce');
      stageIncrement.style.pointerEvents = 'none';
      stageReduce.style.pointerEvents = 'none';
      timeIncrement = document.getElementById('time-Increment');
      timeReduce = document.getElementById('time-Reduce');
      timeIncrement.style.pointerEvents = 'none';
      timeReduce.style.pointerEvents = 'none';
      scenarioClear = document.getElementById('scenario-Clear-Buttons');
      scenarioClear.style.display = 'none';
      setScenarioStage(0);
      setScenarioDay(0);
      setScenarioTime(0);
    }
  }, [RLBToggle])






















  function scenarioPlanner() { // Function to bring up scenario planner modal, called when "Create a Scenario" button is clicked.
    stageSelection = document.getElementById('stage-Selection');
    daySelection = document.getElementById('day-Selection');
    timeSelection = document.getElementById('time-Selection');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");

    setModalVisability(modalState.SHOWN);
    disableButtons();
    //clears values incase scenario creation has already happened that use session.
    stageSelection.value = "";
    daySelection.value = "";
    timeSelection.value = "";
    modal_Scenario = document.getElementById('modal-Scenario');
    if (modal_Scenario.style.display == "block") {
      if (scenarioStage.toString() != "" && scenarioTime.toString() != "" && scenarioDay.toString() != "") {
        stageIncrement = document.getElementById('stage-Increment');
        stageReduce = document.getElementById('stage-Reduce');
        stageIncrement.style.pointerEvents = 'all';
        stageReduce.style.pointerEvents = 'all';
        timeIncrement = document.getElementById('time-Increment');
        timeReduce = document.getElementById('time-Reduce');
        timeIncrement.style.pointerEvents = 'all';
        timeReduce.style.pointerEvents = 'all';
        modal_Scenario.style.display = "none";
        modal_Container.style.display = 'none';
        setModalVisability(modalState.HIDDEN);
        enableButtons();
      } else {
        modal_Scenario.style.display = "none";
        modal_Container.style.display = 'none';
        setModalVisability(modalState.HIDDEN);
        enableButtons();
      }
    } else if (modal_Scenario.style.display == "none") {
      modal_Scenario.style.display = "block";
      modal_Container.style.display = 'flex';
    }
  }



  function scenarioPlanChosen() { // Function called when user clicks view scenario button on scenario planner modal.
    stageSelection = document.getElementById('stage-Selection');
    daySelection = document.getElementById('day-Selection');
    timeSelection = document.getElementById('time-Selection');

    setModalVisability(modalState.HIDDEN);
    enableButtons();

    // Makes sure user submits values in all three sceanrio parameters.
    if (stageSelection.value != "" && daySelection.value != "" && timeSelection.value != "") {
      modal_Container = document.getElementById('modal-Container');
      modal_Container.style.display = 'none';
      level_Buttons = document.getElementById('level-Buttons');
      level_Buttons.style.display = 'flex';
      time_Buttons = document.getElementById('time-Buttons');
      time_Buttons.style.display = 'flex';
      scenarioSelected1 = document.getElementById('Scenario-Planner-Section');
      scenarioSelected1.style.display = 'flex';
      scenarioSelected2 = document.getElementById('scenario-Planner-Section-2');
      scenarioSelected2.style.display = 'flex';
      scenarioSelected3 = document.getElementById('scenario-Planner-Section-3');
      scenarioSelected3.style.display = 'flex';
      stageSelection = document.getElementById('stage-Selection');
      daySelection = document.getElementById('day-Selection');
      timeSelection = document.getElementById('time-Selection');
      modal_Scenario = document.getElementById('modal-Scenario');
      modal_Scenario.style.display = "none";
      stageIncrement = document.getElementById('stage-Increment');
      stageReduce = document.getElementById('stage-Reduce');
      stageIncrement.style.pointerEvents = 'all';
      stageReduce.style.pointerEvents = 'all';
      timeIncrement = document.getElementById('time-Increment');
      timeReduce = document.getElementById('time-Reduce');
      timeIncrement.style.pointerEvents = 'all';
      timeReduce.style.pointerEvents = 'all';
      scenarioClear = document.getElementById('scenario-Clear-Buttons');
      scenarioClear.style.display = 'flex';
      setScenarioStage(stageSelection.value);
      setScenarioDay(daySelection.value);
      setScenarioTime(timeSelection.value);
      sendScenarioData(stageSelection.value, daySelection.value, timeSelection.value);
    } else {
      alert("Please fully enter scenario parameters"); //Alert to inform user they have left one of the parameters empty/unselected. 
      setScenarioStage(Math.random());
      setScenarioDay(Math.random());
      setScenarioTime(Math.random());
    }
  }

  useEffect(() => { // Enables the buttons for scenarios, should be added to a scenario component
    if (timeAndLevelButtons === "Enable") {
      incrementTimeVal = document.getElementById('time-Increment');
      incrementTimeVal.style.pointerEvents = 'all';
      incrementStageVal = document.getElementById('stage-Increment');
      incrementStageVal.style.pointerEvents = 'all';
      reduceTimeVal = document.getElementById('time-Reduce');
      reduceTimeVal.style.pointerEvents = 'all';
      reduceStageVal = document.getElementById('stage-Reduce');
      reduceStageVal.style.pointerEvents = 'all';
    }
    if (timeAndLevelButtons === "Disable") {
      incrementTimeVal = document.getElementById('time-Increment');
      incrementTimeVal.style.pointerEvents = 'none';
      incrementStageVal = document.getElementById('stage-Increment');
      incrementStageVal.style.pointerEvents = 'none';
      reduceTimeVal = document.getElementById('time-Reduce');
      reduceTimeVal.style.pointerEvents = 'none';
      reduceStageVal = document.getElementById('stage-Reduce');
      reduceStageVal.style.pointerEvents = 'none';
    }
  }, [timeAndLevelButtons]
  );

  function scenarioClearer() {
    level_Buttons = document.getElementById('level-Buttons');
    level_Buttons.style.display = 'none';
    time_Buttons = document.getElementById('time-Buttons');
    time_Buttons.style.display = 'none';

    scenarioSelected2 = document.getElementById('scenario-Planner-Section-2');
    scenarioSelected2.style.display = 'none';
    scenarioSelected3 = document.getElementById('scenario-Planner-Section-3');
    scenarioSelected3.style.display = 'none';
    stageSelection = document.getElementById('stage-Selection');
    daySelection = document.getElementById('day-Selection');
    timeSelection = document.getElementById('time-Selection');
    stageIncrement = document.getElementById('stage-Increment');
    stageReduce = document.getElementById('stage-Reduce');
    stageIncrement.style.pointerEvents = 'none';
    stageReduce.style.pointerEvents = 'none';
    timeIncrement = document.getElementById('time-Increment');
    timeReduce = document.getElementById('time-Reduce');
    timeIncrement.style.pointerEvents = 'none';
    timeReduce.style.pointerEvents = 'none';
    scenarioClear = document.getElementById('scenario-Clear-Buttons');
    scenarioClear.style.display = 'none';
    setScenarioStage(0);
    setScenarioDay(0);
    setScenarioTime(0);
    setClearScenario("Clear")
  }


  function incrementStage() { // Function to increase stage parameter without having to create an entire scenario. Called when user presses up chevron under level label, only present after a scenario has been created. 
    if (scenarioStage.toString() != "") {
      let scenarioString = scenarioStage.toString();
      let string = scenarioString.substring(0, scenarioString.length - 1);
      let string2 = scenarioString.charAt(scenarioString.length - 1);
      let string3 = scenarioString.charAt(scenarioString.length - 2);
      let scnerioInt = parseInt(string2);
      let scnerioInt2 = parseInt(string3);
      if (scnerioInt == 8 && scnerioInt2 == 1) {
      } else {
        scnerioInt = scnerioInt + 1;
        let String4 = scnerioInt.toString()
        let string5 = string + String4
        setScenarioStage(string5);
        sendScenarioData(string5, scenarioDay, scenarioTime)
      }

    }
    setUpdateNotes("Update");
  }

  function reduceStage() { // Function to decrease stage parameter without having to create an entire scenario. Called when user presses down chevron under level label, only present after a scenario has been created. 
    if (scenarioStage.toString() != "") {
      let scenarioString = scenarioStage.toString();
      let string2 = scenarioString.charAt(scenarioString.length - 1);
      let string3 = scenarioString.charAt(scenarioString.length - 2);
      let scnerioInt = parseInt(string2);
      let scnerioInt2 = parseInt(string3);
      if (scnerioInt != 0 && scnerioInt2 == 1) {
        let string = scenarioString.substring(0, scenarioString.length - 1);
        scnerioInt = scnerioInt - 1;
        let String4 = scnerioInt.toString()
        let string5 = string + String4
        setScenarioStage(string5);
        sendScenarioData(string5, scenarioDay, scenarioTime)
      } else if (scnerioInt == 0 && scnerioInt2 == 1) {
        let string = scenarioString.substring(0, scenarioString.length - 2);
        scnerioInt = 9;
        let String4 = scnerioInt.toString()
        let string5 = string + String4
        setScenarioStage(string5);
        sendScenarioData(string5, scenarioDay, scenarioTime)
      } else if (scnerioInt != 1) {
        let string = scenarioString.substring(0, scenarioString.length - 1);
        scnerioInt = scnerioInt - 1;
        let String4 = scnerioInt.toString()
        let string5 = string + String4
        setScenarioStage(string5);
        sendScenarioData(string5, scenarioDay, scenarioTime)
      }

    }
    setUpdateNotes("Update");
  }

  function incrementTime() { // Function to increase time parameter without having to create an entire scenario. Called when user presses up chevron under time label, only present after a scenario has been created. 
    if (scenarioTime.toString() != "") {
      let dayArray: any;
      dayArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
      let scenarioTimeVal: any;
      scenarioTimeVal = "";
      let scenarioDayVal: any;
      switch (scenarioTime.toString()) {
        case "00:30 - 03:30":
          setScenarioTime("03:30 - 06:30")
          scenarioTimeVal = "03:30 - 06:30";
          scenarioDayVal = scenarioDay;
          break;
        case "03:30 - 06:30":
          setScenarioTime("06:30 - 09:30")
          scenarioTimeVal = "06:30 - 09:30";
          scenarioDayVal = scenarioDay;
          break;
        case "06:30 - 09:30":
          setScenarioTime("09:30 - 12:30")
          scenarioTimeVal = "09:30 - 12:30";
          scenarioDayVal = scenarioDay;
          break;
        case "09:30 - 12:30":
          setScenarioTime("12:30 - 15:30")
          scenarioTimeVal = "12:30 - 15:30";
          scenarioDayVal = scenarioDay;
          break;
        case "12:30 - 15:30":
          setScenarioTime("15:30 - 18:30")
          scenarioTimeVal = "15:30 - 18:30";
          scenarioDayVal = scenarioDay;
          break;
        case "15:30 - 18:30":
          setScenarioTime("18:30 - 21:30")
          scenarioTimeVal = "18:30 - 21:30";
          scenarioDayVal = scenarioDay;
          break;
        case "18:30 - 21:30":
          setScenarioTime("21:30 - 00:30")
          scenarioTimeVal = "21:30 - 00:30";
          scenarioDayVal = scenarioDay;
          break;
        case "21:30 - 00:30":
          setScenarioTime("00:30 - 03:30")
          scenarioTimeVal = "00:30 - 03:30";
          for (let i = 0; i < dayArray.length; i++) {
            if (dayArray[i] === scenarioDay) {
              if (i === 6) {
                setScenarioDay(dayArray[0]);
                scenarioDayVal = dayArray[0];
              } else {
                setScenarioDay(dayArray[i + 1]);
                scenarioDayVal = dayArray[i + 1]
              }
            }
          }
          break;
      }
      sendScenarioData(scenarioStage, scenarioDayVal, scenarioTimeVal)
    }
    setUpdateNotes("Update");
  }

  function reduceTime() { // Function to decrease time parameter without having to create an entire scenario. Called when user presses down chevron under time label, only present after a scenario has been created. 
    if (scenarioTime.toString() != "") {
      let dayArray: any;
      dayArray = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
      let scenarioTimeVal: any;
      scenarioTimeVal = "";
      let scenarioDayVal: any;
      switch (scenarioTime.toString()) {
        case "00:30 - 03:30":
          setScenarioTime("21:30 - 00:30")
          scenarioTimeVal = "21:30 - 00:30";
          for (let i = 0; i < dayArray.length; i++) {
            if (dayArray[i] === scenarioDay) {
              if (i === 0) {
                setScenarioDay(dayArray[6]);
                scenarioDayVal = dayArray[6];
              } else {
                setScenarioDay(dayArray[i - 1]);
                scenarioDayVal = dayArray[i - 1];
              }
            }
          }
          break;
        case "21:30 - 00:30":
          setScenarioTime("18:30 - 21:30")
          scenarioTimeVal = "18:30 - 21:30";
          scenarioDayVal = scenarioDay;
          break;
        case "18:30 - 21:30":
          setScenarioTime("15:30 - 18:30")
          scenarioTimeVal = "15:30 - 18:30";
          scenarioDayVal = scenarioDay;
          break;
        case "15:30 - 18:30":
          setScenarioTime("12:30 - 15:30")
          scenarioTimeVal = "12:30 - 15:30";
          scenarioDayVal = scenarioDay;
          break;
        case "12:30 - 15:30":
          setScenarioTime("09:30 - 12:30")
          scenarioTimeVal = "09:30 - 12:30";
          scenarioDayVal = scenarioDay;
          break;
        case "09:30 - 12:30":
          setScenarioTime("06:30 - 09:30")
          scenarioTimeVal = "06:30 - 09:30";
          scenarioDayVal = scenarioDay;
          break;
        case "06:30 - 09:30":
          setScenarioTime("03:30 - 06:30")
          scenarioTimeVal = "03:30 - 06:30";
          scenarioDayVal = scenarioDay;
          break;
        case "03:30 - 06:30":
          setScenarioTime("00:30 - 03:30")
          scenarioTimeVal = "00:30 - 03:30";
          scenarioDayVal = scenarioDay;
          break;
      }
      sendScenarioData(scenarioStage, scenarioDayVal, scenarioTimeVal)
    }
    setUpdateNotes("Update");
  }

  function sendScenarioData(stage: any, day: any, time: any) {
    setScenarioDay(day);
    setScenarioStage(stage);
    setScenarioTime(time);
    setSendScenarioDataFlag("Send");
  }

















  function disableButtons() { //Disables all of the buttons on the sidebar, if we split up into smaller components this will need split up




    publicUserNotes.map((function (publicUserNotes: any) {
      buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
      buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
      buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
      buttonShow.disabled = true;
      buttonEdit.disabled = true;
      buttonDelete.disabled = true;
    }))

    partnersUserNotes.map((function (partnersUserNotes: any) {
      buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
      buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
      buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
      buttonShow.disabled = true;
      buttonEdit.disabled = true;
      buttonDelete.disabled = true;
    }))

    SSENUserNotes.map((function (SSENUserNotes: any) {
      buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
      buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
      buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
      buttonShow.disabled = true;
      buttonEdit.disabled = true;
      buttonDelete.disabled = true;
    }))



    publicNonUserNotes.map((function (publicNonUserNotes: any) {
      buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
      buttonShow.disabled = true;
    }))


    partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
      buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
      buttonShow.disabled = true;
    }))


    SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
      buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
      buttonShow.disabled = true;
    }))


    // addNoteButton = document.getElementById('add-Note-Button');
    // addNoteButton.disabled = true;
    scenarioButton = document.getElementById('scenario-Planner-Buttons');
    scenarioButton.disabled = true;
    incrementTimeVal = document.getElementById('time-Increment');
    incrementTimeVal.style.pointerEvents = 'none';
    incrementStageVal = document.getElementById('stage-Increment');
    incrementStageVal.style.pointerEvents = 'none';
    reduceTimeVal = document.getElementById('time-Reduce');
    reduceTimeVal.style.pointerEvents = 'none';
    reduceStageVal = document.getElementById('stage-Reduce');
    reduceStageVal.style.pointerEvents = 'none';


    segmentActivation = document.getElementById('segment-Id-1');
    segmentActivation.disabled = true;

    segmentActivation2 = document.getElementById('segment-Id-2');
    segmentActivation2.disabled = true;

    segmentActivation3 = document.getElementById('segment-Id-3');
    segmentActivation3.disabled = true;


    ionSelectActive = document.getElementById('ion-select-ID');
    ionSelectActive.disabled = true;

    noteExtendActivate = document.getElementById('chevronDownCircle');
    noteExtendActivate.style.pointerEvents = 'none';

    organisationArray.map(function (organisation: any, index: number) {
      index++;
      noteExtendActivate = document.getElementById("chevronDownCircle" + index);
      noteExtendActivate.style.pointerEvents = 'none';
    })
  }

  useEffect(() => {
    if (enableButtonsVal != 0) {
      enableButtons();
      setEnableButtons(0);
    }
  }, [enableButtonsVal]
  );

  function enableButtons() { // Enables all of the sidebar buttons for use



    publicUserNotes.map((function (publicUserNotes: any) {
      buttonShow = document.getElementById('Show-Button-' + publicUserNotes.index);
      buttonEdit = document.getElementById('Edit-Button-' + publicUserNotes.index);
      buttonDelete = document.getElementById('Delete-Button-' + publicUserNotes.index);
      buttonShow.disabled = false;
      buttonEdit.disabled = false;
      buttonDelete.disabled = false;
    }))

    partnersUserNotes.map((function (partnersUserNotes: any) {
      buttonShow = document.getElementById('Show-Button-' + partnersUserNotes.index);
      buttonEdit = document.getElementById('Edit-Button-' + partnersUserNotes.index);
      buttonDelete = document.getElementById('Delete-Button-' + partnersUserNotes.index);
      buttonShow.disabled = false;
      buttonEdit.disabled = false;
      buttonDelete.disabled = false;
    }))

    SSENUserNotes.map((function (SSENUserNotes: any) {
      buttonShow = document.getElementById('Show-Button-' + SSENUserNotes.index);
      buttonEdit = document.getElementById('Edit-Button-' + SSENUserNotes.index);
      buttonDelete = document.getElementById('Delete-Button-' + SSENUserNotes.index);
      buttonShow.disabled = false;
      buttonEdit.disabled = false;
      buttonDelete.disabled = false;
    }))



    publicNonUserNotes.map((function (publicNonUserNotes: any) {
      buttonShow = document.getElementById("Button-Show-NonUser-" + publicNonUserNotes.index);
      buttonShow.disabled = false;
    }))


    partnersNonUserNotes.map((function (partnersNonUserNotes: any) {
      buttonShow = document.getElementById("Button-Show-NonUser-" + partnersNonUserNotes.index);
      buttonShow.disabled = false;
    }))


    SSENNonUserNotes.map((function (SSENNonUserNotes: any) {
      buttonShow = document.getElementById("Button-Show-NonUser-" + SSENNonUserNotes.index);
      buttonShow.disabled = false;
    }))




    addNoteButton = document.getElementById('add-Note-Button');
    addNoteButton.disabled = false;
    scenarioButton = document.getElementById('scenario-Planner-Buttons');
    scenarioButton.disabled = false;
    incrementTimeVal = document.getElementById('time-Increment');
    incrementTimeVal.style.pointerEvents = 'all';
    incrementStageVal = document.getElementById('stage-Increment');
    incrementStageVal.style.pointerEvents = 'all';
    reduceTimeVal = document.getElementById('time-Reduce');
    reduceTimeVal.style.pointerEvents = 'all';
    reduceStageVal = document.getElementById('stage-Reduce');
    reduceStageVal.style.pointerEvents = 'all';

    segmentActivation = document.getElementById('segment-Id-1');
    segmentActivation.disabled = false;
    segmentActivation2 = document.getElementById('segment-Id-2');
    segmentActivation2.disabled = false;
    segmentActivation3 = document.getElementById('segment-Id-3');
    segmentActivation3.disabled = false;

    ionSelectActive = document.getElementById('ion-select-ID');
    ionSelectActive.disabled = false;

    noteExtendActivate = document.getElementById('chevronDownCircle');
    noteExtendActivate.style.pointerEvents = 'all';

    organisationArray.map(function (organisation: any, index: number) {
      index++;
      noteExtendActivate = document.getElementById("chevronDownCircle" + index);
      noteExtendActivate.style.pointerEvents = 'all';
    })
  }

















  function handleClick() { // Is called when user confirms they want to add a note to their selected location. Brings up create note modal.
    modal = document.getElementById('modal');
    modal_Delete = document.getElementById('modal-Delete');
    modal_Scenario = document.getElementById('modal-Scenario');
    modal_Container = document.getElementById('modal-Container');

    setUpdateNotes("Update");

    setNoteType("Miscellaneous")

    if (noteMouseActive === "Active") {
      setMouseAppearance(mouseState.NORMAL);
      setNoteMouseActive("Inactive");
      enableButtons();
    } else {
      if (modal.style.display == "flex") {
        setModalVisability(modalState.HIDDEN);
        setMouseAppearance(mouseState.NORMAL);
        modal.style.display = "none"
        modal_Container.style.display = 'none';
        setNoteCancelled("Cancelled");
        enableButtons();
      } else {
        setNoteMouseActive("Active");
        setNoteLocationSelection("SET");
        modal_Delete.style.display = "none";
        modal_Scenario.style.display = "none";
        setMouseAppearance(mouseState.MARKER);
        disableButtons();
      }
    }


  }

  function OutOfBoundsClick() { // Is called when user tries to create a note outside of the boundries of their selected area, brings up modal informing user, closes with understood button in center. 
    modal_OutOfBounds = document.getElementById('modal-OutOfBounds');
    modal_OutOfBounds.style.display = "none";
    modal_Container = document.getElementById('modal-Container');
    modal_Container.style.display = 'none';
    setModalVisability(modalState.HIDDEN);
    setOutsideOrInsideBoundry("UNSET")
    setNoteMouseActive("Active");
    setNoteLocationSelection("SET");
    setMouseAppearance(mouseState.MARKER);
    disableButtons();
    setUpdateNotes("Update");
  }

  useEffect(() => { // useEffect that calls when outsideOrInsideBoundry changes, which happens if user clicks to add map outside their selected area. Brings up out of boundries modal to inform user.
    if (outsideOrInsideBoundry.toString() == "Outside") {
      modal_OutOfBounds = document.getElementById('modal-OutOfBounds');
      modal_OutOfBounds.style.display = "block";
      modal_Container = document.getElementById('modal-Container');
      modal_Container.style.display = 'flex';
      setModalVisability(modalState.SHOWN);
    }
  },
    [outsideOrInsideBoundry]
  )



  let defaultExpireTime: any;

  useEffect(() => { // useEffect that runs when both XCoordConfirmed and YCoordConfirmed changes, which happens when user accepts clicked location for new note, brings up modal about creating a new note.  
    if (XCoordConfirmed != 0 && YCoordConfirmed != 0) {
      modal = document.getElementById('modal');
      modal_Container = document.getElementById('modal-Container');
      modal.style.display = "flex";
      modal_Container.style.display = 'flex';

      setModalVisability(modalState.SHOWN);
      const d = new Date();
      defaultExpireTime = (d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + "T" + d.getHours() + ":" + (d.getMinutes() + 5) + ":" + d.getSeconds() + "+01:00");
      time_Date_Selector = document.getElementById('datetime');

      time_Date_Selector.todayParts.minute = (time_Date_Selector.todayParts.minute + 5);

      setUpdateNotes("Update");

    }
  },
    [XCoordConfirmed, YCoordConfirmed]
  );



  let perm_Checkbox: any;
  let fault_Checkbox: any;
  let datetime_Button: any;
  let link_to_Fault_Reference: any;

  useEffect(() => {
    if (linkedToFault === true) {
      perm_Checkbox = document.getElementById('perm-Checkbox');
      perm_Checkbox.disabled = 'true';
      note_Card_Button_Conatiner = document.getElementById('note-card-btn-container');
      note_Card_Button_Conatiner.style.display = 'none';
      datetime_Button = document.getElementById('datetime-Button');
      datetime_Button.style.display = 'none';
      link_to_Fault_Reference = document.getElementById('link-to-Fault-Reference');
      link_to_Fault_Reference.style.display = 'flex';
    } else {
      perm_Checkbox = document.getElementById('perm-Checkbox');
      perm_Checkbox.disabled = 'false';
      datetime_Button = document.getElementById('datetime-Button');
      datetime_Button.style.display = 'flex';
      link_to_Fault_Reference = document.getElementById('link-to-Fault-Reference');
      link_to_Fault_Reference.style.display = 'none';
      note_Card_Button_Conatiner = document.getElementById('note-card-btn-container');
      note_Card_Button_Conatiner.style.display = 'flex';
    }

  }, [linkedToFault])

  useEffect(() => {
    if (permNote === true) {
      fault_Checkbox = document.getElementById('fault-Checkbox');
      fault_Checkbox.disabled = 'true';
      datetime_Button = document.getElementById('datetime-Button');
      datetime_Button.disabled = 'true';
    } else {
      fault_Checkbox = document.getElementById('fault-Checkbox');
      fault_Checkbox.disabled = 'false';
      datetime_Button = document.getElementById('datetime-Button');
      datetime_Button.disabled = 'false';
    }

  }, [permNote])


  let refernce_Line: any;
  let refernce_Dropdown: any;
  let time_Date_Selector: any;

  function saveNote() { // Is called when user clicks publish button on "Create Note" modal, sends data entered into the modal to the backend, then sets update which activates code further down.
    saved_Note_Text = document.getElementById('note-Input-Line');

    refernce_Line = document.getElementById('note-Input-Line-Fault-Ref');

    if ((noteExpiryDate == "0" || noteExpiryDate == "") && linkedToFault == false && permNote == false) {
      alert("Please select a valid expiry date for this note.");
    } else if (noteExpiryDate != "0" && noteExpiryDate != "") {

      const date = noteExpiryDate.split("T");
      let time: any;
      let dateTimeTemp = date[1];
      if (dateTimeTemp.includes("+")) {
        time = dateTimeTemp.split("+")

      } else if (dateTimeTemp.includes("-")) {
        time = dateTimeTemp.split("-")
      }


      const YMD = date[0].split("-")
      const HMS = time[0].split(":")


      let dateTest: any;
      dateTest = new Date();

      let dateToEdit: any;
      dateToEdit = new Date(dateTest.getFullYear(), dateTest.getMonth(), dateTest.getDate(), dateTest.getHours(), (dateTest.getMinutes() + 1), dateTest.getSeconds());

      let dText: any;
      dText = new Date(Number(YMD[0]), (Number(YMD[1]) - 1), Number(YMD[2]), Number(HMS[0]), Number(HMS[1]), Number(HMS[2]));

      time_Date_Selector = document.getElementById('datetime');

      if (dText.getTime() < dateToEdit.getTime() && linkedToFault == false && permNote == false) {
        alert("Please select a valid expiry date for this note.");
      } else if (saved_Note_Text.value.trim().length === 0) {
        alert("The note must have some text content.");
      } else {


        let tempNotesArray = [...notesArray];

        modal = document.getElementById('modal');
        PR_Note_User_Extend = document.getElementById('note');
        PR_Note_User_Extend.style.height = 'auto';
        let note_Expiration_Date: any;
        let note_Linked_Fault: any;

        if (permNote || linkedToFault) {
          note_Expiration_Date = "2100-10-19T10:12:00+01:00";
        } else {
          note_Expiration_Date = noteExpiryDate
        }

        if (permNote || !linkedToFault) {
          note_Linked_Fault = "No_Fault";
        } else {
          note_Linked_Fault = faultReferenceToBeSaved
        }


        // Data inputed into modal is formatted below
        let note_locationX = XCoordConfirmed.toFixed(3);
        let note_locationY = YCoordConfirmed.toFixed(3);
        let note_Content = saved_Note_Text.value
        let note_Visability = newNoteVisability;
        let selectedAreaLocal: any;

        if (selectedArea != "All") {
          selectedAreaLocal = lastSelectedArea;
        } else {
          selectedAreaLocal = addNoteAllLocation;
        }


        // Creates path for data to be posted to, also creates headings containing necessary auth 
        let pathNoteSaveTest: any;
        pathNoteSaveTest = BASE_URL + "/gridim/rotaload/partners";
        let headersForSave = new Headers();
        headersForSave.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
        headersForSave.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");

        // var of data to be posted, record then encoded into formbody.
        var details = {
          'action': 'addNote',
          'text': note_Content,
          'district': selectedAreaLocal,
          'latitude': note_locationY,
          'longitude': note_locationX,
          'Expiration': note_Expiration_Date,
          'faultRef': note_Linked_Fault,
          'noteVisability': note_Visability,
        };
        const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&');

        // fetch method of POST that effectivly saves data conatined in formbody to designated path. If the POST action fails an error is thrown, if it doesn't fail sets values which causes local notes to update and what type of note action happend to change note highlighting. 

        fetch(pathNoteSaveTest, {
          method: 'POST',
          headers: headersForSave,
          body: formBody
        })
          .then(function (response) {
            if (!response.ok) {
              throw new Error("HTTP status " + response.status);
            } else {
              setUpdateNotes("Update");
              setTypeOfNoteAction("Add Note");
            }
          }
          )

        // Clears modal entry fields the disposes of modal, adds 1 to amount of notes saved this session, sets addmarkertoarray causing code further down to run and make marker official rather than temporary.

        setNoteSaveCount(noteSaveCount + 1);
        modal_Container = document.getElementById('modal-Container');
        modal_Container.style.display = 'none';
        setModalVisability(modalState.HIDDEN);
        enableButtons();
        saved_Note_Text.value = ""
        modal.style.display = "none";
        setSelectedArea(lastSelectedArea);
        setNotesArray(tempNotesArray);
        setAddMarkerToArray("Add");
        fault_Checkbox = document.getElementById('fault-Checkbox');
        fault_Checkbox.checked = false;
        refernce_Dropdown = document.getElementById('reference-Select-ID');

        refernce_Dropdown.value = "";
        const d = new Date();


        time_Date_Selector = document.getElementById('datetime');

        time_Date_Selector.value = "";

      }
    } else if ((faultReferenceToBeSaved === 0 || faultReferenceToBeSaved === "") && linkedToFault === true) {
      alert("The note must have a fault reference for linking.");
    } else if (faultReferenceToBeSaved != 0 && linkedToFault === true) {
      if (saved_Note_Text.value.trim().length === 0) {
        alert("The note must have some text content.");
      } else {
        let tempNotesArray = [...notesArray];

        modal = document.getElementById('modal');
        PR_Note_User_Extend = document.getElementById('note');
        PR_Note_User_Extend.style.height = 'auto';
        let note_Expiration_Date: any;
        let note_Linked_Fault: any;

        if (permNote || linkedToFault) {
          note_Expiration_Date = "2100-10-19T10:12:00+01:00";
        } else {
          note_Expiration_Date = noteExpiryDate
        }

        if (permNote || !linkedToFault) {
          note_Linked_Fault = "No_Fault";
        } else {
          note_Linked_Fault = faultReferenceToBeSaved
        }


        // Data inputed into modal is formatted below
        let note_locationX = XCoordConfirmed.toFixed(3);
        let note_locationY = YCoordConfirmed.toFixed(3);
        let note_Content = saved_Note_Text.value
        let note_Visability = newNoteVisability;
        let selectedAreaLocal: any;

        if (selectedArea != "All") {
          selectedAreaLocal = lastSelectedArea;
        } else {
          selectedAreaLocal = addNoteAllLocation;
        }


        // Creates path for data to be posted to, also creates headings containing necessary auth 
        let pathNoteSaveTest: any;
        pathNoteSaveTest = BASE_URL + "/gridim/rotaload/partners";
        let headersForSave = new Headers();
        headersForSave.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
        headersForSave.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");

        // var of data to be posted, record then encoded into formbody.
        var details = {
          'action': 'addNote',
          'text': note_Content,
          'district': selectedAreaLocal,
          'latitude': note_locationY,
          'longitude': note_locationX,
          'Expiration': note_Expiration_Date,
          'faultRef': note_Linked_Fault,
          'noteVisability': note_Visability,
        };
        const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&');

        // fetch method of POST that effectivly saves data conatined in formbody to designated path. If the POST action fails an error is thrown, if it doesn't fail sets values which causes local notes to update and what type of note action happend to change note highlighting. 

        fetch(pathNoteSaveTest, {
          method: 'POST',
          headers: headersForSave,
          body: formBody
        })
          .then(function (response) {
            if (!response.ok) {
              throw new Error("HTTP status " + response.status);
            } else {
              setUpdateNotes("Update");
              setTypeOfNoteAction("Add Note");
            }
          }
          )

        // Clears modal entry fields the disposes of modal, adds 1 to amount of notes saved this session, sets addmarkertoarray causing code further down to run and make marker official rather than temporary.

        setNoteSaveCount(noteSaveCount + 1);
        modal_Container = document.getElementById('modal-Container');
        modal_Container.style.display = 'none';
        setModalVisability(modalState.HIDDEN);
        enableButtons();
        saved_Note_Text.value = ""
        modal.style.display = "none";
        setSelectedArea(lastSelectedArea);
        setNotesArray(tempNotesArray);
        setAddMarkerToArray("Add");
        fault_Checkbox = document.getElementById('fault-Checkbox');
        fault_Checkbox.checked = false;
        refernce_Dropdown = document.getElementById('reference-Select-ID');

        refernce_Dropdown.value = "";
        const d = new Date();


        time_Date_Selector = document.getElementById('datetime');

        time_Date_Selector.value = "";
      }
    } else if (permNote == true) {
      if (saved_Note_Text.value.trim().length === 0) {
        alert("The note must have some text content.");
      } else {
        let tempNotesArray = [...notesArray];

        modal = document.getElementById('modal');
        PR_Note_User_Extend = document.getElementById('note');
        PR_Note_User_Extend.style.height = 'auto';
        let note_Expiration_Date: any;
        let note_Linked_Fault: any;

        if (permNote || linkedToFault) {
          note_Expiration_Date = "2100-10-19T10:12:00+01:00";
        } else {
          note_Expiration_Date = noteExpiryDate
        }

        if (permNote || !linkedToFault) {
          note_Linked_Fault = "No_Fault";
        } else {
          note_Linked_Fault = faultReferenceToBeSaved
        }


        // Data inputed into modal is formatted below
        let note_locationX = XCoordConfirmed.toFixed(3);
        let note_locationY = YCoordConfirmed.toFixed(3);
        let note_Content = saved_Note_Text.value
        let note_Visability = newNoteVisability;
        let selectedAreaLocal: any;

        if (selectedArea != "All") {
          selectedAreaLocal = lastSelectedArea;
        } else {
          selectedAreaLocal = addNoteAllLocation;
        }


        // Creates path for data to be posted to, also creates headings containing necessary auth 
        let pathNoteSaveTest: any;
        pathNoteSaveTest = BASE_URL + "/gridim/rotaload/partners";
        let headersForSave = new Headers();
        headersForSave.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
        headersForSave.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");

        // var of data to be posted, record then encoded into formbody.
        var details = {
          'action': 'addNote',
          'text': note_Content,
          'district': selectedAreaLocal,
          'latitude': note_locationY,
          'longitude': note_locationX,
          'Expiration': note_Expiration_Date,
          'faultRef': note_Linked_Fault,
          'noteVisability': note_Visability,
        };
        const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&');

        // fetch method of POST that effectivly saves data conatined in formbody to designated path. If the POST action fails an error is thrown, if it doesn't fail sets values which causes local notes to update and what type of note action happend to change note highlighting. 

        fetch(pathNoteSaveTest, {
          method: 'POST',
          headers: headersForSave,
          body: formBody
        })
          .then(function (response) {
            if (!response.ok) {
              throw new Error("HTTP status " + response.status);
            } else {
              setUpdateNotes("Update");
              setTypeOfNoteAction("Add Note");
            }
          }
          )

        // Clears modal entry fields the disposes of modal, adds 1 to amount of notes saved this session, sets addmarkertoarray causing code further down to run and make marker official rather than temporary.

        setNoteSaveCount(noteSaveCount + 1);
        modal_Container = document.getElementById('modal-Container');
        modal_Container.style.display = 'none';
        setModalVisability(modalState.HIDDEN);
        enableButtons();
        saved_Note_Text.value = ""
        modal.style.display = "none";
        setSelectedArea(lastSelectedArea);
        setNotesArray(tempNotesArray);
        setAddMarkerToArray("Add");
        fault_Checkbox = document.getElementById('fault-Checkbox');
        fault_Checkbox.checked = false;
        refernce_Dropdown = document.getElementById('reference-Select-ID');

        refernce_Dropdown.value = "";
        const d = new Date();


        time_Date_Selector = document.getElementById('datetime');

        time_Date_Selector.value = "";
      }
    }


  };














  function editPublicNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
    modal_Edit = document.getElementById('modal-Edit');
    modal_Edit_Line = document.getElementById('note-Edit-Line');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    setEditButtonNumber(noteNumber);
    if (modal_Edit.style.display == "none") {
      modal_Edit.style.display = "block";
      modal_Container.style.display = 'flex';
      disableButtons();
      setModalVisability(modalState.SHOWN);

      // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

      publicUserNotes.map((function (notesArrayEdit: any) {
        if (notesArrayEdit.index == noteNumber) {
          setDeleteNoteReference(notesArrayEdit.note_Content);
          modal_Edit_Line.value = notesArrayEdit.note_Content;
          setDeleteDateReference(notesArrayEdit.note_Creation);
          setDeleteDistrictReference(notesArrayEdit.note_District);
          setDeleteIDReference(notesArrayEdit.note_ID);
          const myArray = notesArrayEdit.note_Location.split(",");
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    } else if (modal_Edit.style.display = "block") {
      modal_Edit.style.display = "none";
      modal_Container.style.display = 'none';
      setModalVisability(modalState.HIDDEN);
      enableButtons();
    }
  }

  function editPartnersNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
    modal_Edit = document.getElementById('modal-Edit');
    modal_Edit_Line = document.getElementById('note-Edit-Line');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    setEditButtonNumber(noteNumber);
    if (modal_Edit.style.display == "none") {
      modal_Edit.style.display = "block";
      modal_Container.style.display = 'flex';
      disableButtons();
      setModalVisability(modalState.SHOWN);

      // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

      partnersUserNotes.map((function (notesArrayEdit: any) {
        if (notesArrayEdit.index == noteNumber) {
          setDeleteNoteReference(notesArrayEdit.note_Content);
          modal_Edit_Line.value = notesArrayEdit.note_Content;
          setDeleteDateReference(notesArrayEdit.note_Creation);
          setDeleteDistrictReference(notesArrayEdit.note_District);
          setDeleteIDReference(notesArrayEdit.note_ID);
          const myArray = notesArrayEdit.note_Location.split(",");
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    } else if (modal_Edit.style.display = "block") {
      modal_Edit.style.display = "none";
      modal_Container.style.display = 'none';
      setModalVisability(modalState.HIDDEN);
      enableButtons();
    }
  }

  function editSSENNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
    modal_Edit = document.getElementById('modal-Edit');
    modal_Edit_Line = document.getElementById('note-Edit-Line');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    setEditButtonNumber(noteNumber);
    if (modal_Edit.style.display == "none") {
      modal_Edit.style.display = "block";
      modal_Container.style.display = 'flex';
      disableButtons();
      setModalVisability(modalState.SHOWN);

      // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

      SSENUserNotes.map((function (notesArrayEdit: any) {
        if (notesArrayEdit.index == noteNumber) {
          setDeleteNoteReference(notesArrayEdit.note_Content);
          modal_Edit_Line.value = notesArrayEdit.note_Content;
          setDeleteDateReference(notesArrayEdit.note_Creation);
          setDeleteDistrictReference(notesArrayEdit.note_District);
          setDeleteIDReference(notesArrayEdit.note_ID);
          const myArray = notesArrayEdit.note_Location.split(",");
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    } else if (modal_Edit.style.display = "block") {
      modal_Edit.style.display = "none";
      modal_Container.style.display = 'none';
      setModalVisability(modalState.HIDDEN);
      enableButtons();
    }
  }

  function saveEditNote() { // Function to save the values edited by the user on the edit note modal, called by clicking publish button on the edit modal.  
    modal_Edit_Line = document.getElementById('note-Edit-Line');
    if (modal_Edit_Line.value.trim().length === 0) {
      alert("The note must have some text content.");
    } else {
      modal_Edit = document.getElementById('modal-Edit');
      modal_Edit.style.display = "none";
      modal_Container = document.getElementById('modal-Container');
      modal_Container.style.display = 'none';
      enableButtons();
      setModalVisability(modalState.HIDDEN);

      // Creates path for edited data to be posted to, also creates headings containing necessary auth 

      let pathNoteEditTest: any
      let headersForEdit = new Headers();
      headersForEdit.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
      headersForEdit.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");

      pathNoteEditTest = BASE_URL + "/gridim/rotaload/partners";

      // var of data to be posted, record then encoded into formbody.

      var details = {
        'action': 'updateNote',
        'id': deleteIDReference,
        'text': modal_Edit_Line.value,
      };
      const formBody = Object.entries(details).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')

      // fetch POST method used to post the now edited data to the backend, if fetch fails an error is thrown, if it doesn't fail notes are set to update and type of note action is set. 

      fetch(pathNoteEditTest, {
        method: 'POST',
        headers: headersForEdit,
        body: formBody
      })
        .then(function (response) {
          if (!response.ok) {
            throw new Error("HTTP status " + response.status);
          } else {
            setUpdateNotes("Update");
            setTypeOfNoteAction("Edit Note")
          }
        }
        )
    }
  }

















  function deletePublicNote(deleteNumber: any) { // Function to display delete modal to see what data the note contains before getting rid of it, called by pressing the button on user organisation note.
    modal_Delete = document.getElementById('modal-Delete');
    modal_Delete_Line = document.getElementById('note-Delete-Line');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    setDeleteButtonNumber(deleteNumber);
    if (modal_Delete.style.display == "none") {
      modal_Delete.style.display = "flex";
      modal_Container.style.display = 'flex';

      setModalVisability(modalState.SHOWN);
      disableButtons();

      // Runs through notes array to link note user wants to delete with data, sets the data in the note for easy deletion and viewing. 

      publicUserNotes.map((function (notesArrayDelete: any) {
        if (notesArrayDelete.index == deleteNumber) {
          setDeleteNoteReference(notesArrayDelete.note_Content);
          modal_Delete_Line.value = notesArrayDelete.note_Content;
          setDeleteDateReference(notesArrayDelete.note_Creation);
          const myArray = notesArrayDelete.note_Location.split(",");
          setDeleteDistrictReference(notesArrayDelete.note_District);
          setDeleteIDReference(notesArrayDelete.note_ID)
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    } else if (modal_Delete.style.display == "flex") {
      modal_Delete.style.display = "none";
      modal_Container.style.display = 'none';

      setModalVisability(modalState.HIDDEN);
      enableButtons();
    }
  }

  function deletePartnerNote(deleteNumber: any) { // Function to display delete modal to see what data the note contains before getting rid of it, called by pressing the button on user organisation note.
    modal_Delete = document.getElementById('modal-Delete');
    modal_Delete_Line = document.getElementById('note-Delete-Line');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    setDeleteButtonNumber(deleteNumber);
    if (modal_Delete.style.display == "none") {
      modal_Delete.style.display = "flex";
      modal_Container.style.display = 'flex';

      setModalVisability(modalState.SHOWN);
      disableButtons();

      // Runs through notes array to link note user wants to delete wiht data, stes the data in the note for easy deletion and viewing. 

      partnersUserNotes.map((function (notesArrayDelete: any) {
        if (notesArrayDelete.index == deleteNumber) {
          setDeleteNoteReference(notesArrayDelete.note_Content);
          modal_Delete_Line.value = notesArrayDelete.note_Content;
          setDeleteDateReference(notesArrayDelete.note_Creation);
          const myArray = notesArrayDelete.note_Location.split(",");
          setDeleteDistrictReference(notesArrayDelete.note_District);
          setDeleteIDReference(notesArrayDelete.note_ID)
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    } else if (modal_Delete.style.display == "flex") {
      modal_Delete.style.display = "none";
      modal_Container.style.display = 'none';

      setModalVisability(modalState.HIDDEN);
      enableButtons();
    }
  }

  function deleteSSENNote(deleteNumber: any) { // Function to display delete modal to see what data the note contains before getting rid of it, called by pressing the button on user organisation note.
    modal_Delete = document.getElementById('modal-Delete');
    modal_Delete_Line = document.getElementById('note-Delete-Line');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    setDeleteButtonNumber(deleteNumber);
    if (modal_Delete.style.display == "none") {
      modal_Delete.style.display = "flex";
      modal_Container.style.display = 'flex';

      setModalVisability(modalState.SHOWN);
      disableButtons();

      // Runs through notes array to link note user wants to delete wiht data, stes the data in the note for easy deletion and viewing. 

      SSENUserNotes.map((function (notesArrayDelete: any) {
        if (notesArrayDelete.index == deleteNumber) {
          setDeleteNoteReference(notesArrayDelete.note_Content);
          modal_Delete_Line.value = notesArrayDelete.note_Content;
          setDeleteDateReference(notesArrayDelete.note_Creation);
          const myArray = notesArrayDelete.note_Location.split(",");
          setDeleteDistrictReference(notesArrayDelete.note_District);
          setDeleteIDReference(notesArrayDelete.note_ID)
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    } else if (modal_Delete.style.display == "flex") {
      modal_Delete.style.display = "none";
      modal_Container.style.display = 'none';

      setModalVisability(modalState.HIDDEN);
      enableButtons();
    }
  }


  function deleteNoteClick() { // Function for deleting note, called by user clicking delete button on "delete this note" modal. 
    modal_Delete = document.getElementById('modal-Delete');
    modal_Delete.style.display = "none";
    modal_Container = document.getElementById('modal-Container');
    modal_Container.style.display = 'none';

    setModalVisability(modalState.HIDDEN);
    enableButtons();

    // Creates path for where note should be deleted from as well as whcih note using note ID, also creates headings containing necessary auth 

    let pathNoteDeleteTest: any;
    let headersForDelete = new Headers();
    headersForDelete.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);
    headersForDelete.append("Content-Type", "application/x-www-form-urlencoded;charset=UTF-8");
    pathNoteDeleteTest = BASE_URL + "/gridim/rotaload/partners?action=deleteNote&id=" + deleteIDReference;

    // Fetch delete method, data to which note needs to be deleted is included in the path and no data is psoted in delete method itself, if fails an error is thrown, else notes need to be updated, type of note action needs to be set, and marker for that note needs to be deleted from the map.

    fetch(pathNoteDeleteTest, {
      method: 'DELETE',
      headers: headersForDelete,
    }).then(function (response) {
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      } else {
        setUpdateNotes("Update");

        setTypeOfNoteAction("Delete Note");




      }
    })
  }














  function showPublicUserNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
    modal_Show = document.getElementById('modal-Show');
    modal_Show_Line = document.getElementById('note-Show-Line');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    if (modal_Show.style.display == "block") {
      modal_Show.style.display = "none";
      modal_Container.style.display = 'none';
      setModalVisability(modalState.HIDDEN);
      enableButtons();
    } else if (modal_Show.style.display == "none") {
      modal_Show.style.display = "block";
      modal_Container.style.display = 'flex';

      setModalVisability(modalState.SHOWN);
      disableButtons();

      // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

      publicUserNotes.map((function (notesArrayShow: any) {
        if (notesArrayShow.index == noteNumber) {
          setDeleteNoteReference(notesArrayShow.note_Content);
          modal_Show_Line.value = notesArrayShow.note_Content;
          setDeleteDateReference(notesArrayShow.note_Creation);
          setDeleteDistrictReference(notesArrayShow.note_District);
          setDeleteIDReference(notesArrayShow.note_ID);
          const myArray = notesArrayShow.note_Location.split(",");
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    }
  }

  function showPartnerUserNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
    modal_Show = document.getElementById('modal-Show');
    modal_Show_Line = document.getElementById('note-Show-Line');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    if (modal_Show.style.display == "block") {
      modal_Show.style.display = "none";
      modal_Container.style.display = 'none';
      setModalVisability(modalState.HIDDEN);
      enableButtons();
    } else if (modal_Show.style.display == "none") {
      modal_Show.style.display = "block";
      modal_Container.style.display = 'flex';

      setModalVisability(modalState.SHOWN);
      disableButtons();

      // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

      partnersUserNotes.map((function (notesArrayShow: any) {
        if (notesArrayShow.index == noteNumber) {
          setDeleteNoteReference(notesArrayShow.note_Content);
          modal_Show_Line.value = notesArrayShow.note_Content;
          setDeleteDateReference(notesArrayShow.note_Creation);
          setDeleteDistrictReference(notesArrayShow.note_District);
          setDeleteIDReference(notesArrayShow.note_ID);
          const myArray = notesArrayShow.note_Location.split(",");
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    }
  }

  function showSSENUserNote(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
    modal_Show = document.getElementById('modal-Show');
    modal_Show_Line = document.getElementById('note-Show-Line');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    if (modal_Show.style.display == "block") {
      modal_Show.style.display = "none";
      modal_Container.style.display = 'none';
      setModalVisability(modalState.HIDDEN);
      enableButtons();
    } else if (modal_Show.style.display == "none") {
      modal_Show.style.display = "block";
      modal_Container.style.display = 'flex';

      setModalVisability(modalState.SHOWN);
      disableButtons();

      // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

      SSENUserNotes.map((function (notesArrayShow: any) {
        if (notesArrayShow.index == noteNumber) {
          setDeleteNoteReference(notesArrayShow.note_Content);
          modal_Show_Line.value = notesArrayShow.note_Content;
          setDeleteDateReference(notesArrayShow.note_Creation);
          setDeleteDistrictReference(notesArrayShow.note_District);
          setDeleteIDReference(notesArrayShow.note_ID);
          const myArray = notesArrayShow.note_Location.split(",");
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    }
  }















  function showPublicNoteNonUser(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
    modal_Show_NonUser = document.getElementById('modal-Show-NonUser');
    modal_Show_Line_NonUser = document.getElementById('note-Show-Line-NonUser');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    if (modal_Show_NonUser.style.display == "none") {
      modal_Show_NonUser.style.display = "block";
      modal_Container.style.display = 'flex';

      setModalVisability(modalState.SHOWN);
      disableButtons();

      // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

      publicNonUserNotes.map((function (notesArrayShow: any) {
        if (notesArrayShow.index == noteNumber) {
          setDeleteNoteReference(notesArrayShow.note_Content);
          modal_Show_Line_NonUser.value = notesArrayShow.note_Content;
          setDeleteDateReference(notesArrayShow.note_Creation);
          setDeleteDistrictReference(notesArrayShow.note_District);
          setDeleteIDReference(notesArrayShow.note_ID);
          setNoteOrg(notesArrayShow.note_Organisation);
          const myArray = notesArrayShow.note_Location.split(",");
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    } else if (modal_Show_NonUser.style.display == "block") {
      modal_Show_NonUser.style.display = "none";
      modal_Container.style.display = 'none';

      setModalVisability(modalState.HIDDEN);
      enableButtons();
    }
  }

  function showPartnerNoteNonUser(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
    modal_Show_NonUser = document.getElementById('modal-Show-NonUser');
    modal_Show_Line_NonUser = document.getElementById('note-Show-Line-NonUser');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    if (modal_Show_NonUser.style.display == "none") {
      modal_Show_NonUser.style.display = "block";
      modal_Container.style.display = 'flex';

      setModalVisability(modalState.SHOWN);
      disableButtons();

      // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

      partnersNonUserNotes.map((function (notesArrayShow: any) {
        if (notesArrayShow.index == noteNumber) {
          setDeleteNoteReference(notesArrayShow.note_Content);
          modal_Show_Line_NonUser.value = notesArrayShow.note_Content;
          setDeleteDateReference(notesArrayShow.note_Creation);
          setDeleteDistrictReference(notesArrayShow.note_District);
          setDeleteIDReference(notesArrayShow.note_ID);
          setNoteOrg(notesArrayShow.note_Organisation);
          const myArray = notesArrayShow.note_Location.split(",");
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    } else if (modal_Show_NonUser.style.display == "block") {
      modal_Show_NonUser.style.display = "none";
      modal_Container.style.display = 'none';

      setModalVisability(modalState.HIDDEN);
      enableButtons();
    }
  }


  function showSSENNoteNonUser(noteNumber: any) { // Function to bring up edit modal, called by pressing the edit button on any user organisation written notes. 
    modal_Show_NonUser = document.getElementById('modal-Show-NonUser');
    modal_Show_Line_NonUser = document.getElementById('note-Show-Line-NonUser');
    modal_Container = document.getElementById('modal-Container');
    setUpdateNotes("Update");
    if (modal_Show_NonUser.style.display == "none") {
      modal_Show_NonUser.style.display = "block";
      modal_Container.style.display = 'flex';

      setModalVisability(modalState.SHOWN);
      disableButtons();

      // Goes through notesArray to find the details of the note user wants to edit, sets values so that they can then be used during editing.

      SSENNonUserNotes.map((function (notesArrayShow: any) {
        if (notesArrayShow.index == noteNumber) {
          setDeleteNoteReference(notesArrayShow.note_Content);
          modal_Show_Line_NonUser.value = notesArrayShow.note_Content;
          setDeleteDateReference(notesArrayShow.note_Creation);
          setDeleteDistrictReference(notesArrayShow.note_District);
          setDeleteIDReference(notesArrayShow.note_ID);
          setNoteOrg(notesArrayShow.note_Organisation);
          const myArray = notesArrayShow.note_Location.split(",");
          setDeleteLocationReferenceX(myArray[0]);
          setDeleteLocationReferenceY(myArray[1].trim())
        }
      }))
    } else if (modal_Show_NonUser.style.display == "block") {
      modal_Show_NonUser.style.display = "none";
      modal_Container.style.display = 'none';

      setModalVisability(modalState.HIDDEN);
      enableButtons();
    }
  }















  function ExtendNotes(PRNumber: any) { // Function to extend/minimise the card used to diplay notes, called when the user clicks the chevrons next to the organisation at the top of the note card. Same function for both user organisation notes and other notes. 
    if (PRNumber > 0) {
      // Runs this code if user extends/minimises note from other organisation. 
      PR_Note_Non_User_Extend = document.getElementById('note-Non-User-' + PRNumber);
      chevronDirectionNonUser = document.getElementById('chevronDownCircle' + PRNumber);
      if (PR_Note_Non_User_Extend.style.height == '72px') {
        PR_Note_Non_User_Extend.style.height = 'auto';
        chevronDirectionNonUser.style.transform = 'rotate(180deg)';
      } else if (PR_Note_Non_User_Extend.style.height == 'auto') {
        PR_Note_Non_User_Extend.style.height = '72px';
        chevronDirectionNonUser.style.transform = 'none';
      }
    } else {
      // Runds this code if the note was written by the same organisation currently logged in. 
      PR_Note_User_Extend = document.getElementById('note');
      chevronDirectionUser = document.getElementById('chevronDownCircle');
      if (PR_Note_User_Extend.style.height == '72px') {
        PR_Note_User_Extend.style.height = 'auto';
        chevronDirectionUser.style.transform = 'rotate(180deg)';
      } else if (PR_Note_User_Extend.style.height == 'auto') {
        PR_Note_User_Extend.style.height = '72px';
        chevronDirectionUser.style.transform = 'none';
      }
    }

  }

  function publicNotesExtend(PRNumber: any) { // Function to extend/minimise the card used to diplay notes, called when the user clicks the chevrons next to the organisation at the top of the note card. Same function for both user organisation notes and other notes. 
    if (PRNumber > 0) {
      // Runs this code if user extends/minimises note from other organisation. 
      PR_Note_Non_User_Extend = document.getElementById('note-Non-User-Public-' + PRNumber);
      chevronDirectionNonUser = document.getElementById('chevronDownCircle-Public-' + PRNumber);
      if (PR_Note_Non_User_Extend.style.height == '72px') {
        PR_Note_Non_User_Extend.style.height = 'auto';
        chevronDirectionNonUser.style.transform = 'rotate(180deg)';
      } else if (PR_Note_Non_User_Extend.style.height == 'auto') {
        PR_Note_Non_User_Extend.style.height = '72px';
        chevronDirectionNonUser.style.transform = 'none';
      }
    } else {
      // Runds this code if the note was written by the same organisation currently logged in. 
      PR_Note_User_Extend = document.getElementById('note-Public');
      chevronDirectionUser = document.getElementById('chevronDownCircle-Public');
      if (PR_Note_User_Extend.style.height == '72px') {
        PR_Note_User_Extend.style.height = 'auto';
        chevronDirectionUser.style.transform = 'rotate(180deg)';
      } else if (PR_Note_User_Extend.style.height == 'auto') {
        PR_Note_User_Extend.style.height = '72px';
        chevronDirectionUser.style.transform = 'none';
      }
    }

  }

  function partnerNotesExtend(PRNumber: any) { // Function to extend/minimise the card used to diplay notes, called when the user clicks the chevrons next to the organisation at the top of the note card. Same function for both user organisation notes and other notes. 
    if (PRNumber > 0) {
      // Runs this code if user extends/minimises note from other organisation. 
      PR_Note_Non_User_Extend = document.getElementById('note-Non-User-Partners-' + PRNumber);
      chevronDirectionNonUser = document.getElementById('chevronDownCircle-Partners-' + PRNumber);
      if (PR_Note_Non_User_Extend.style.height == '72px') {
        PR_Note_Non_User_Extend.style.height = 'auto';
        chevronDirectionNonUser.style.transform = 'rotate(180deg)';
      } else if (PR_Note_Non_User_Extend.style.height == 'auto') {
        PR_Note_Non_User_Extend.style.height = '72px';
        chevronDirectionNonUser.style.transform = 'none';
      }
    } else {
      // Runds this code if the note was written by the same organisation currently logged in. 
      PR_Note_User_Extend = document.getElementById('note-Partners');
      chevronDirectionUser = document.getElementById('chevronDownCircle-Partners');
      if (PR_Note_User_Extend.style.height == '72px') {
        PR_Note_User_Extend.style.height = 'auto';
        chevronDirectionUser.style.transform = 'rotate(180deg)';
      } else if (PR_Note_User_Extend.style.height == 'auto') {
        PR_Note_User_Extend.style.height = '72px';
        chevronDirectionUser.style.transform = 'none';
      }
    }

  }

  function SSENNotesExtend(PRNumber: any) { // Function to extend/minimise the card used to diplay notes, called when the user clicks the chevrons next to the organisation at the top of the note card. Same function for both user organisation notes and other notes. 
    if (PRNumber > 0) {
      // Runs this code if user extends/minimises note from other organisation. 
      PR_Note_Non_User_Extend = document.getElementById('note-Non-User-SSEN-' + PRNumber);
      chevronDirectionNonUser = document.getElementById('chevronDownCircle-SSEN-' + PRNumber);
      if (PR_Note_Non_User_Extend.style.height == '72px') {
        PR_Note_Non_User_Extend.style.height = 'auto';
        chevronDirectionNonUser.style.transform = 'rotate(180deg)';
      } else if (PR_Note_Non_User_Extend.style.height == 'auto') {
        PR_Note_Non_User_Extend.style.height = '72px';
        chevronDirectionNonUser.style.transform = 'none';
      }
    } else {
      // Runds this code if the note was written by the same organisation currently logged in. 
      PR_Note_User_Extend = document.getElementById('note-SSEN');
      chevronDirectionUser = document.getElementById('chevronDownCircle-SSEN');
      if (PR_Note_User_Extend.style.height == '72px') {
        PR_Note_User_Extend.style.height = 'auto';
        chevronDirectionUser.style.transform = 'rotate(180deg)';
      } else if (PR_Note_User_Extend.style.height == 'auto') {
        PR_Note_User_Extend.style.height = '72px';
        chevronDirectionUser.style.transform = 'none';
      }
    }

  }















  function HighlightNoteUser(hightlightNumber: any) { // Function to highlight note, called by either clicking on note on the sisdebar or by clicking on a marker on the map. This function handles notes written by the current logged in organisation.
    if (modalVisability === 1) {
      if (mouseAppearance === 0) {
      } else {
        let tempHighlightPublicArray = publicUserNotes;
        let tempHighlightPartnerArray = partnersUserNotes;
        let tempHighlightSSENArray = SSENUserNotes;


        let tempHighlightPublicNonUserArray = publicNonUserNotes;
        let tempHighlightPartnerNonUserArray = partnersNonUserNotes;
        let tempHighlightSSENNonUserArray = SSENNonUserNotes;

        tempHighlightPublicArray.map(function (note: any) {
          if (note.index == hightlightNumber) {
            setNoteToHighlight(note.note_Location);
            setNoteMarkerType("User");
            setmapOrSidebarClick("Sidebar");
            Card_Design = document.getElementById('Card-' + note.index);
            Card_Design.style.border = "solid #FFFFFF 2.5px";
            Card_Design.style.background = "#64A1BB";
            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
          } else {
            Card_Design = document.getElementById('Card-' + note.index);
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";
          }
        })

        tempHighlightPartnerArray.map(function (note: any) {
          if (note.index == hightlightNumber) {
            setNoteToHighlight(note.note_Location);
            setNoteMarkerType("User");
            setmapOrSidebarClick("Sidebar");
            Card_Design = document.getElementById('Card-' + note.index);
            Card_Design.style.border = "solid #FFFFFF 2.5px";
            Card_Design.style.background = "#64A1BB";
            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
          } else {
            Card_Design = document.getElementById('Card-' + note.index);
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";
          }
        })

        tempHighlightSSENArray.map(function (note: any) {
          if (note.index == hightlightNumber) {
            setNoteToHighlight(note.note_Location);
            setNoteMarkerType("User");
            setmapOrSidebarClick("Sidebar");
            Card_Design = document.getElementById('Card-' + note.index);
            Card_Design.style.border = "solid #FFFFFF 2.5px";
            Card_Design.style.background = "#64A1BB";
            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
          } else {
            Card_Design = document.getElementById('Card-' + note.index);
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";
          }
        })

        tempHighlightPublicNonUserArray.map(function (note: any) {
          Card_Design = document.getElementById('Card-NonUser-' + note.index);
          Card_Design.style.border = "2px solid rgb(157, 181, 197)";
          Card_Design.style.background = "rgb(59, 107, 139)";
        })

        tempHighlightPartnerNonUserArray.map(function (note: any) {
          Card_Design = document.getElementById('Card-NonUser-' + note.index);
          Card_Design.style.border = "2px solid rgb(157, 181, 197)";
          Card_Design.style.background = "rgb(59, 107, 139)";
        })

        tempHighlightSSENNonUserArray.map(function (note: any) {
          Card_Design = document.getElementById('Card-NonUser-' + note.index);
          Card_Design.style.border = "2px solid rgb(157, 181, 197)";
          Card_Design.style.background = "rgb(59, 107, 139)";
        })
      }
    }
    setUpdateNotes("Update");
  }

  function HighlightNoteNonUser(hightlightNumber: any) { // Function to highlight note, called by either clicking on note on the sidebar or by clicking on a marker on the map. This function handles notes written by the other organisations that use RLD partner layer.
    if (modalVisability === 1) {
      if (mouseAppearance === 0) {
      } else {

        let tempHighlightPublicArray = publicUserNotes;
        let tempHighlightPartnerArray = partnersUserNotes;
        let tempHighlightSSENArray = SSENUserNotes;


        let tempHighlightPublicNonUserArray = publicNonUserNotes;
        let tempHighlightPartnerNonUserArray = partnersNonUserNotes;
        let tempHighlightSSENNonUserArray = SSENNonUserNotes;


        tempHighlightPublicNonUserArray.map(function (note: any) {
          if (note.index === hightlightNumber) {
            setNoteToHighlight(note.note_Location);
            setNoteMarkerType("Non-User");
            setmapOrSidebarClick("Sidebar");
            Card_Design = document.getElementById('Card-NonUser-' + note.index);
            Card_Design.style.border = "solid #FFFFFF 2.5px";
            Card_Design.style.background = "#64A1BB";
            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
          } else {
            Card_Design = document.getElementById('Card-NonUser-' + note.index);
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";
          }
        })


        tempHighlightPartnerNonUserArray.map(function (note: any) {
          if (note.index === hightlightNumber) {
            setNoteToHighlight(note.note_Location);
            setNoteMarkerType("Non-User");
            setmapOrSidebarClick("Sidebar");
            Card_Design = document.getElementById('Card-NonUser-' + note.index);
            Card_Design.style.border = "solid #FFFFFF 2.5px";
            Card_Design.style.background = "#64A1BB";
            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
          } else {
            Card_Design = document.getElementById('Card-NonUser-' + note.index);
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";
          }
        })


        tempHighlightSSENNonUserArray.map(function (note: any) {
          if (note.index === hightlightNumber) {
            setNoteToHighlight(note.note_Location);
            setNoteMarkerType("Non-User");
            setmapOrSidebarClick("Sidebar");
            Card_Design = document.getElementById('Card-NonUser-' + note.index);
            Card_Design.style.border = "solid #FFFFFF 2.5px";
            Card_Design.style.background = "#64A1BB";
            Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
          } else {
            Card_Design = document.getElementById('Card-NonUser-' + note.index);
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";
          }
        })

        tempHighlightPublicArray.map(function (note: any) {
          Card_Design = document.getElementById('Card-' + note.index);
          Card_Design.style.border = "2px solid rgb(157, 181, 197)";
          Card_Design.style.background = "rgb(59, 107, 139)";
        })

        tempHighlightPartnerArray.map(function (note: any) {
          Card_Design = document.getElementById('Card-' + note.index);
          Card_Design.style.border = "2px solid rgb(157, 181, 197)";
          Card_Design.style.background = "rgb(59, 107, 139)";
        })

        tempHighlightSSENArray.map(function (note: any) {
          Card_Design = document.getElementById('Card-' + note.index);
          Card_Design.style.border = "2px solid rgb(157, 181, 197)";
          Card_Design.style.background = "rgb(59, 107, 139)";
        })
      }
    }
  }

  useEffect(() => {
    if (markerClickedLocation != 0) { //useEffect that is called when markerClickedLocation and noteMarkerType changes, changes when user clicks on note marker on map.
      if (mouseAppearance === 0) {

      } else {
        if (noteMarkerType == "User") { //Checks if note is from user org or not to know which array to check.

          setUpdateNotes("Update")
          publicUserNotes.map((function (note: any) { //Check user org array.

            let arrayOfNote = note.note_Location.split(",");

            let testValue: any;
            testValue = Number(arrayOfNote[1]);
            testValue = testValue.toFixed(3);

            let testValue2: any;
            testValue2 = Number(arrayOfNote[0]);
            testValue2 = testValue2.toFixed(3);
            // Values used to determine what note needs to be highlighted/fly to. 

            if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) { // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.
              PR_Note_User_Extend = document.getElementById('note');
              PR_Note_User_Extend.style.height = 'auto';

              chevronDirectionUser = document.getElementById('chevronDownCircle');
              chevronDirectionUser.style.transform = 'none';

              public_Note_User_Extend = document.getElementById('note-Public');
              public_Note_User_Extend.style.height = 'auto';

              public_Note_Chevron = document.getElementById('chevronDownCircle-Public');
              public_Note_Chevron.style.transform = 'none';


              Card_Design = document.getElementById('Card-' + note.index);
              Card_Design.style.border = "solid #FFFFFF 2.5px";
              Card_Design.style.background = "#64A1BB";
              Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
            } else {
              Card_Design = document.getElementById('Card-' + note.index);
              Card_Design.style.border = "2px solid rgb(157, 181, 197)";
              Card_Design.style.background = "rgb(59, 107, 139)";
            }
          }))

          partnersUserNotes.map((function (note: any) { //Check user org array.

            let arrayOfNote = note.note_Location.split(",");

            let testValue: any;
            testValue = Number(arrayOfNote[1]);
            testValue = testValue.toFixed(3);

            let testValue2: any;
            testValue2 = Number(arrayOfNote[0]);
            testValue2 = testValue2.toFixed(3);
            // Values used to determine what note needs to be highlighted/fly to. 

            if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) { // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.

              PR_Note_User_Extend = document.getElementById('note');
              chevronDirectionUser = document.getElementById('chevronDownCircle');

              PR_Note_User_Extend.style.height = 'auto';
              chevronDirectionUser.style.transform = 'rotate(180deg)';

              partner_Note_User_Extend = document.getElementById('note-Partners');
              partner_Note_User_Extend.style.height = 'auto';

              partner_Note_Chevron = document.getElementById('chevronDownCircle-Partners');
              partner_Note_Chevron.style.transform = 'rotate(180deg)';

              Card_Design = document.getElementById('Card-' + note.index);
              Card_Design.style.border = "solid #FFFFFF 2.5px";
              Card_Design.style.background = "#64A1BB";
              Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
            } else {
              Card_Design = document.getElementById('Card-' + note.index);
              Card_Design.style.border = "2px solid rgb(157, 181, 197)";
              Card_Design.style.background = "rgb(59, 107, 139)";
            }
          }))

          SSENUserNotes.map((function (note: any) { //Check user org array.

            let arrayOfNote = note.note_Location.split(",");

            let testValue: any;
            testValue = Number(arrayOfNote[1]);
            testValue = testValue.toFixed(3);

            let testValue2: any;
            testValue2 = Number(arrayOfNote[0]);
            testValue2 = testValue2.toFixed(3);
            // Values used to determine what note needs to be highlighted/fly to. 

            if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) { // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.

              PR_Note_User_Extend = document.getElementById('note');
              PR_Note_User_Extend.style.height = 'auto';

              chevronDirectionUser = document.getElementById('chevronDownCircle');
              chevronDirectionUser.style.transform = 'rotate(180deg)';

              SSEN_Note_User_Extend = document.getElementById('note-SSEN');
              SSEN_Note_User_Extend.style.height = 'auto';

              SSEN_Note_Chevron = document.getElementById('chevronDownCircle-SSEN');
              SSEN_Note_Chevron.style.transform = 'rotate(180deg)';

              Card_Design = document.getElementById('Card-' + note.index);
              Card_Design.style.border = "solid #FFFFFF 2.5px";
              Card_Design.style.background = "#64A1BB";
              Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
            } else {
              Card_Design = document.getElementById('Card-' + note.index);
              Card_Design.style.border = "2px solid rgb(157, 181, 197)";
              Card_Design.style.background = "rgb(59, 107, 139)";
            }
          }))


          publicNonUserNotes.map(function (note: any) {
            Card_Design = document.getElementById('Card-NonUser-' + note.index);
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";
          })

          partnersNonUserNotes.map(function (note: any) {
            Card_Design = document.getElementById('Card-NonUser-' + note.index);
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";
          })

          SSENNonUserNotes.map(function (note: any) {
            Card_Design = document.getElementById('Card-NonUser-' + note.index);
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";
          })

        } else if (noteMarkerType == "Non-User") { //Checks if note is from non-user org or not to know which array to check.

          publicNonUserNotes.map((function (note: any) { //Check non-user org array.
            let arrayOfNote = note.note_Location.split(",");
            let testValue: any;
            testValue = Number(arrayOfNote[1]);
            testValue = testValue.toFixed(3);

            let testValue2: any;
            testValue2 = Number(arrayOfNote[0]);
            testValue2 = testValue2.toFixed(3);
            // Values used to determine what note needs to be highlighted/fly to. 

            if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) {  // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.

              organisationArray.map((function (organisation: any, index: number) {
                index++
                if (note.note_Organisation === organisation) {

                  PR_Note_Non_User_Extend = document.getElementById('note-Non-User-' + index);
                  PR_Note_Non_User_Extend.style.height = 'auto';

                  chevronDirectionNonUser = document.getElementById('chevronDownCircle' + index);
                  chevronDirectionNonUser.style.transform = 'rotate(180deg)';

                  public_Note_Non_User = document.getElementById("note-Non-User-Public-" + index);
                  public_Note_Non_User.style.height = 'auto';

                  public_Note_Chevron_Non_User = document.getElementById("chevronDownCircle-Public-" + index);
                  public_Note_Chevron_Non_User.style.transform = 'rotate(180deg)';
                }
              }))
              Card_Design = document.getElementById('Card-NonUser-' + note.index);
              Card_Design.style.border = "solid #FFFFFF 2.5px";
              Card_Design.style.background = "#64A1BB";
              Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
            } else {
              Card_Design = document.getElementById('Card-NonUser-' + note.index);
              Card_Design.style.border = "2px solid rgb(157, 181, 197)";
              Card_Design.style.background = "rgb(59, 107, 139)";
            }
          }))

          partnersNonUserNotes.map((function (note: any) { //Check non-user org array.
            let arrayOfNote = note.note_Location.split(",");
            let testValue: any;
            testValue = Number(arrayOfNote[1]);
            testValue = testValue.toFixed(3);

            let testValue2: any;
            testValue2 = Number(arrayOfNote[0]);
            testValue2 = testValue2.toFixed(3);
            // Values used to determine what note needs to be highlighted/fly to. 

            if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) {  // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.

              organisationArray.map((function (organisation: any, index: number) {
                index++
                if (note.note_Organisation === organisation) {

                  PR_Note_Non_User_Extend = document.getElementById('note-Non-User-' + index);
                  PR_Note_Non_User_Extend.style.height = 'auto';

                  chevronDirectionNonUser = document.getElementById('chevronDownCircle' + index);
                  chevronDirectionNonUser.style.transform = 'rotate(180deg)';

                  partner_Note_Non_User = document.getElementById("note-Non-User-Partners-" + index);
                  partner_Note_Non_User.style.height = 'auto';

                  partner_Note_Chevron_Non_User = document.getElementById("chevronDownCircle-Partners-" + index);
                  partner_Note_Chevron_Non_User.style.transform = 'rotate(180deg)';
                }
              }))
              Card_Design = document.getElementById('Card-NonUser-' + note.index);
              Card_Design.style.border = "solid #FFFFFF 2.5px";
              Card_Design.style.background = "#64A1BB";
              Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
            } else {
              Card_Design = document.getElementById('Card-NonUser-' + note.index);
              Card_Design.style.border = "2px solid rgb(157, 181, 197)";
              Card_Design.style.background = "rgb(59, 107, 139)";
            }
          }))

          SSENNonUserNotes.map((function (note: any) { //Check non-user org array.
            let arrayOfNote = note.note_Location.split(",");
            let testValue: any;
            testValue = Number(arrayOfNote[1]);
            testValue = testValue.toFixed(3);

            let testValue2: any;
            testValue2 = Number(arrayOfNote[0]);
            testValue2 = testValue2.toFixed(3);
            // Values used to determine what note needs to be highlighted/fly to. 

            if (testValue2 == markerClickedLocation.lng.toFixed(3) && testValue == markerClickedLocation.lat.toFixed(3)) {  // Checks note coordinates saved against note coordinates clicked, if they match highlights note and scrolls to it.

              organisationArray.map((function (organisation: any, index: number) {
                index++
                if (note.note_Organisation === organisation) {

                  PR_Note_Non_User_Extend = document.getElementById('note-Non-User-' + index);
                  PR_Note_Non_User_Extend.style.height = 'auto';

                  chevronDirectionNonUser = document.getElementById('chevronDownCircle' + index);
                  chevronDirectionNonUser.style.transform = 'rotate(180deg)';

                  SSEN_Note_Non_User = document.getElementById("note-Non-User-SSEN-" + index);
                  SSEN_Note_Non_User.style.height = 'auto';

                  SSEN_Note_Chevron_Non_User = document.getElementById("chevronDownCircle-SSEN-" + index);
                  SSEN_Note_Chevron_Non_User.style.transform = 'rotate(180deg)';
                }
              }))
              Card_Design = document.getElementById('Card-NonUser-' + note.index);
              Card_Design.style.border = "solid #FFFFFF 2.5px";
              Card_Design.style.background = "#64A1BB";
              Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
            } else {
              Card_Design = document.getElementById('Card-NonUser-' + note.index);
              Card_Design.style.border = "2px solid rgb(157, 181, 197)";
              Card_Design.style.background = "rgb(59, 107, 139)";
            }
          }))
          notesArray.map(function (note: any) {
            Card_Design = document.getElementById('Card-' + note.index);
            Card_Design.style.border = "2px solid rgb(157, 181, 197)";
            Card_Design.style.background = "rgb(59, 107, 139)";
          })
        }

        setNoteMarkerType(0);
        setMarkerClickedLocation(0); // Resets values to 0, means it won't run again unless set elsewhere.

      }
    }
  },
    [markerClickedLocation, noteMarkerType]
  );


  useEffect(() => { // Handles moving of highlighted note when a new note is added. 
    if (highlightLastNote != 0) {
      Card_Design = document.getElementById('Card-' + (notesArray.length - 1));
      Card_Design.style.border = "2px solid rgb(157, 181, 197)";
      Card_Design.style.background = "rgb(59, 107, 139)";

      Card_Design = document.getElementById('Card-' + notesArray.length);
      Card_Design.style.border = "2.5px solid rgb(255, 255, 255)";
      Card_Design.style.background = "rgb(100, 161, 187)";
      Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });
      setHighlightLastNote(0);
    }
  }, [highlightLastNote])


  useEffect(() => { // useEffect that is called when updateNotes is changed, which happens whenever a note is edited, added, deleted, the LRD is changed, or the mode is changed.   

    if (updateNotes != 0) { // Makes sure updateNotes has ben set elsewhere and isn't still the default 0. 
      let pathNoteTest: any;

      if (selectedArea === "All") { // If in the dropdown menu the user selects the all value, which some partners won't have acess to as they only have one LRD.  
        let allAddress: any
        allAddress = "";
        for (let i = 0; i < userDistrictIdArray.length; i++) {
          allAddress = allAddress + userDistrictIdArray[i]; // Adds each LRD the user org has access to into a string. 
          if ((i != userDistrictIdArray.length - 1)) { // If LRD isn't last in userDistrictIdArray adds a comma to the address.
            allAddress = allAddress + ","
          }
        }
        pathNoteTest = BASE_URL + "/gridim/rotaload/partners?action=getNotes&districts=" + allAddress; // Adds created address onto end of path.

      } else {
        pathNoteTest = BASE_URL + "/gridim/rotaload/partners?action=getNotes&districts=" + selectedArea; // Adds the selected LRD onto end of path
      }

      let headers = new Headers();
      headers.append("Authorization", ACCESS_CREDENTIALS.basicAuthentication);

      // Headers for fetch auth.

      fetch(pathNoteTest, { headers: headers })
        .then(function (response) { return response.json(); })
        .then(function (notesJson) {


          if (notesJson.length < 1) {

            setPublicUserNotes([]);


            setPartnersUserNotes([]);


            setSSENUserNotes([]);

            setUpdateNotes(0);


          } else {
            const d = new Date();

            let tempPublicNotesArray: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_ID: any; note_Visability: any; }[] = []
            let tempPublicNotesArrayNonUser: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_Organisation: any; note_ID: any; note_Visability: any; }[] = []

            let tempPartnersNotesArray: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_ID: any; note_Visability: any; }[] = []
            let tempPartnersNotesArrayNonUser: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_Organisation: any; note_ID: any; note_Visability: any; }[] = []

            let tempSSENNotesArray: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_ID: any; note_Visability: any; }[] = []
            let tempSSENNotesArrayNonUser: { index: any; note_Content: any; note_Creation: string; note_Location: string; note_District: string; note_Organisation: any; note_ID: any; note_Visability: any; }[] = []



            // Make temp record arrays for the data we get from the backend, where to place notes for a local version.  

            let index = notesJson.length + 1; // How many notes there are saved. 
            let tempOrganisationArray: any[] = []
            let faultArray: any[] = [];


            const fetchData = async () => {
              const response = await fetch(liveFaultsPath) as any;
              const json = await response.json();
              for (let i = 0; i < json.Faults.length; i++) {
                faultArray.push(json.Faults[i].reference);
              }

              notesJson.forEach((note: any) => { // Goes through each note obtained through the fetch, keeps count of every note with the index.  
                index--;

                let note_Expiration = note.Expiration;
                let note_Fault = note.LinkedTo;
                let passTimeCheck: any;


                if (note_Expiration.length > 3 && note_Expiration != "undefined") {

                  const date = note_Expiration.split("T");
                  let time: any;
                  let dateTimeTemp = date[1];
                  if (dateTimeTemp.includes("+")) {
                    time = dateTimeTemp.split("+")

                  } else if (dateTimeTemp.includes("-")) {
                    time = dateTimeTemp.split("-")
                  }


                  const YMD = date[0].split("-")
                  const HMS = time[0].split(":")

                  let dateTest: any;
                  dateTest = new Date();

                  let dText: any;
                  dText = new Date(Number(YMD[0]), (Number(YMD[1]) - 1), Number(YMD[2]), Number(HMS[0]), Number(HMS[1]), Number(HMS[2]));


                  passTimeCheck = "Fail";
                  if (dText.getTime() > dateTest.getTime()) {
                    passTimeCheck = "Pass";
                  }


                  let noteFaultPass: any;
                  if (passTimeCheck === "Pass") {

                    noteFaultPass = "Fail"
                    for (let i = 0; i < faultArray.length; i++) {

                      if (note_Fault === faultArray[i] || note_Fault === "No_Fault") {
                        noteFaultPass = "Pass"
                      }
                    }

                    if (noteFaultPass === "Pass") {
                      if (note.organisation === resiliencePartner) { // Checks org of note against logged in org
                        let note_Content = note.text;
                        let note_Time__Reformatted_part_1 = note.created.split('T');
                        let note_Time__Reformatted_part_2 = note_Time__Reformatted_part_1[1].split('.');
                        let note_Time__Reformatted = note_Time__Reformatted_part_2[0] + " " + note_Time__Reformatted_part_1[0];
                        let note_locatonLongLat = String(note.longitude) + ", " + String(note.latitude);
                        let note_District = note.district;
                        let note_ID = note.id;
                        let note_Visability = note.Visability;

                        if (note_Visability === "SSEN") {
                          tempSSENNotesArray.push({
                            index: index,
                            note_Content: note_Content,
                            note_Creation: note_Time__Reformatted,
                            note_Location: note_locatonLongLat,
                            note_District: note_District,
                            note_ID: note_ID,
                            note_Visability: note_Visability
                          }
                          )

                        } else if (note_Visability === "Partners") {
                          tempPartnersNotesArray.push({
                            index: index,
                            note_Content: note_Content,
                            note_Creation: note_Time__Reformatted,
                            note_Location: note_locatonLongLat,
                            note_District: note_District,
                            note_ID: note_ID,
                            note_Visability: note_Visability
                          }
                          )
                        } else if (note_Visability === "Public") {
                          tempPublicNotesArray.push({
                            index: index,
                            note_Content: note_Content,
                            note_Creation: note_Time__Reformatted,
                            note_Location: note_locatonLongLat,
                            note_District: note_District,
                            note_ID: note_ID,
                            note_Visability: note_Visability
                          }
                          )

                        }

                      } else {

                        if (!tempOrganisationArray.includes(note.organisation)) { // Checks if temp org array has current notes org in it, if not adds it. 
                          tempOrganisationArray.push(note.organisation);
                        }

                        let note_Content = note.text;
                        let note_Time__Reformatted_part_1 = note.created.split('T');
                        let note_Time__Reformatted_part_2 = note_Time__Reformatted_part_1[1].split('.');
                        let note_Time__Reformatted = note_Time__Reformatted_part_2[0] + " " + note_Time__Reformatted_part_1[0];
                        let note_locatonLongLat = String(note.longitude) + ", " + String(note.latitude);
                        let note_District = note.district;
                        let note_Organisation = note.organisation;
                        let note_ID = note.id;
                        let note_Visability = note.Visability
                        if (note_Visability === "SSEN") {
                          tempSSENNotesArrayNonUser.push({
                            index: index,
                            note_Content: note_Content,
                            note_Creation: note_Time__Reformatted,
                            note_Location: note_locatonLongLat,
                            note_District: note_District,
                            note_Organisation: note_Organisation,
                            note_ID: note_ID,
                            note_Visability: note_Visability
                          }
                          )

                        } else if (note_Visability === "Partners") {
                          tempPartnersNotesArrayNonUser.push({
                            index: index,
                            note_Content: note_Content,
                            note_Creation: note_Time__Reformatted,
                            note_Location: note_locatonLongLat,
                            note_District: note_District,
                            note_Organisation: note_Organisation,
                            note_ID: note_ID,
                            note_Visability: note_Visability
                          }
                          )
                        } else if (note_Visability === "Public") {
                          tempPublicNotesArrayNonUser.push({
                            index: index,
                            note_Content: note_Content,
                            note_Creation: note_Time__Reformatted,
                            note_Location: note_locatonLongLat,
                            note_District: note_District,
                            note_Organisation: note_Organisation,
                            note_ID: note_ID,
                            note_Visability: note_Visability
                          }
                          )

                        }
                      }
                    }
                  }

                  tempPublicNotesArray.reverse();
                  tempPublicNotesArrayNonUser.reverse();
                  tempPartnersNotesArray.reverse();
                  tempPartnersNotesArrayNonUser.reverse();
                  tempSSENNotesArray.reverse();
                  tempSSENNotesArrayNonUser.reverse();


                  setPublicUserNotes(tempPublicNotesArray);
                  setPublicNonUserNotes(tempPublicNotesArrayNonUser);

                  setPartnersUserNotes(tempPartnersNotesArray);
                  setPartnersNonUserNotes(tempPartnersNotesArrayNonUser)

                  setSSENUserNotes(tempSSENNotesArray);
                  setSSENNonUserNotes(tempSSENNotesArrayNonUser);
                }
              });

            }

            fetchData();

            if (typeOfNoteAction === "Show Notes") { // Checks the reason for the update, "Show Notes" is for when the mode is toggled.
              notesArray.map(function (note: any) { // Goes through every note in the array and changes style back to default, gets rid of any highlighting. 
                Card_Design = document.getElementById('Card-' + note.index);
                Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                Card_Design.style.background = "rgb(59, 107, 139)";
              })
            }

            if (typeOfNoteAction === "Add Note") { // Checks the reason for the update, "Add Notes" is for when a new note is being added. 
              let highlightedCardIndex: any;
              notesArray.map(function (note: any) {
                Card_Design = document.getElementById('Card-' + note.index);
                if (Card_Design.style.border === "2.5px solid rgb(255, 255, 255)" && Card_Design.style.background === "rgb(100, 161, 187)" && highlightedCardIndex != "Highlight Moved.") {
                  highlightedCardIndex = note.index;
                  if (typeOfNoteAction === "Add Note") {
                    if (notesArray.length === highlightedCardIndex) {
                      setHighlightLastNote("Highlight Last Note")
                    } else {
                      Card_Design = document.getElementById('Card-' + highlightedCardIndex);
                      Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                      Card_Design.style.background = "rgb(59, 107, 139)";

                      Card_Design = document.getElementById('Card-' + (highlightedCardIndex + 1));
                      Card_Design.style.border = "2.5px solid rgb(255, 255, 255)";
                      Card_Design.style.background = "rgb(100, 161, 187)";
                      Card_Design.scrollIntoView({ top: Card_Design.offsetTop, behavior: 'smooth' });

                      highlightedCardIndex = "Highlight Moved."
                    }
                  }
                }
              })

            }

            if (typeOfNoteAction === "Delete Note") { // Checks the reason for the update, "Show Notes" is for when the mode is toggled.
              notesArray.map(function (note: any) { // Goes through every note in the array and changes style back to default, gets rid of any highlighting. 
                Card_Design = document.getElementById('Card-' + note.index);
                Card_Design.style.border = "2px solid rgb(157, 181, 197)";
                Card_Design.style.background = "rgb(59, 107, 139)";
              })
            }



            setOrganisationArray(tempOrganisationArray);


            setUpdateNotes(0);
            setTypeOfNoteAction(0);

          }
        });
    }
  },
    [updateNotes]
  )










  useEffect(() => {
    if (userDistrictNameArray != 0) {
      if (userDistrictNameArray.length > 1) {
        let dropdownTest: any;
        dropdownTest = document.getElementById('dropdown-Card-Style');
        dropdownTest.style.display = 'block';
        data_Cards_Style_Attempt = document.getElementById('data-Cards-Style-Attempt');
        data_Cards_Style_Attempt.style.display = 'flex';
        let dropdownText: any;
        dropdownText = document.getElementById('ion-select-ID');
        dropdownText.value = 'All';
        setSelectedArea('All');
      } else {
        setSelectedArea(userDistrictIdArray[0]);
        data_Cards_Style_Attempt = document.getElementById('data-Cards-Style-Attempt');
        data_Cards_Style_Attempt.style.display = 'flex';
      }
    }
  }, [userDistrictNameArray])

  useEffect(() => { // useEffect that runs when selectedArea changes, used to be a lot more complex but was cut down when setupdatenotes and toggledmode was added. Simply sets last values to detect when slectedarea and toggle modes changes. Also updates notes + type of action. 
    if (selectedArea != 0) {
      setLastSelectedArea(selectedArea);
      if (noteToggle === "ON") {
        setUpdateNotes("Update");
        setTypeOfNoteAction("Show Notes");
      }
    }
  },
    [selectedArea]
  )

  function refreshNotes() {
    setUpdateNotes("Update");
    setRefreshButton("Disabled");

  }










  return (
    <>
      <div id="menu-Container" onTouchStart={(e) => { setmouseDownPos(e.touches[0].clientX) }} onTouchEnd={(e) => { setmouseUpPos(e.changedTouches[0].clientX) }}>
        <IonHeader>
          <div className="menu-Title-Style">
            <img src="assets/images/SSEN_Logo_White.svg"></img>
            <IonTitle class="menu-Logo-Style">Resilience Partner Dashboard</IonTitle>
          </div>
        </IonHeader>
        <IonContent class="menu-content">

          <div className="dropdown-Card-Style" id='dropdown-Card-Style' style={{ display: 'none' }}>
            <IonSelect id="ion-select-ID" onIonChange={(e: any) => setSelectedArea(e.detail.value)} interface="popover" placeholder="Select Local Resilience District">
              <IonSelectOption value="All">All</IonSelectOption>
              {
                userDistrictIdArray.map(function (districts: any, index: any) {
                  return <IonSelectOption value={districts}>
                  {userDistrictNameArray[index]}
                  </IonSelectOption>
                })
              }
            </IonSelect>
          </div>

          <div className="data-Cards-Style-Attempt" id="data-Cards-Style-Attempt" style={{ display: 'none' }}>
            <div className="data-Card-Style">
              <IonCardTitle class="explanation-Card-Title">Number of Properties</IonCardTitle>
              <IonCardContent class="block-Letter-Text">
                {propertyNumbers}
              </IonCardContent>
            </div>
            <div className="data-Card-Style">
              <IonCardTitle class="explanation-Card-Title">Priority Customers</IonCardTitle>
              <IonCardContent class="block-Letter-Text">
                {customerNumbers}
              </IonCardContent>
            </div>
          </div>
          <div className="horizontal_dotted_line" id='horizontal-dotted-line'></div>
          <div className='scenario-Planner-Style' id='scenario-Planner-Style'>
            <span>Scenario Planner</span>
            <div>
              <div className='scenario-Style-Placement'>
                <div id="Scenario-Planner-Section" className='scenario-Planner-Section'>
                  <div id='level-Buttons' className='level-Buttons'>
                    <IonCardContent className='note-Content'>
                      Level
                    </IonCardContent>
                    <div className='scenario-Increment-Button' id='stage-Increment' onClick={() => incrementStage()}>
                      <IonIcon slot='icon-only' className='icon-Only' icon={chevronUp} style={{ color: "white" }} />
                    </div>
                    <div className='scenario-Reduction-Button' id='stage-Reduce' onClick={() => reduceStage()} >
                      <IonIcon slot='icon-only' className='icon-Only' icon={chevronDown} style={{ color: "white" }} />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <IonButton className='scenario-Planner-Buttons' id='scenario-Planner-Buttons' onClick={() => scenarioPlanner()}>Create a scenario</IonButton>
                    <IonButton className='scenario-Clear-Buttons' id='scenario-Clear-Buttons' onClick={() => scenarioClearer()} style={{ display: 'none' }}>Clear the scenario</IonButton>
                  </div>
                  <div id='time-Buttons' className='time-Buttons'>
                    <IonCardContent className='note-Content' >
                      Time
                    </IonCardContent>
                    <div className='scenario-Increment-Button' id='time-Increment' onClick={() => incrementTime()} >
                      <IonIcon slot='icon-only' className='icon-Only' icon={chevronUp} style={{ color: "white" }} />
                    </div>
                    <div className='scenario-Reduction-Button' id='time-Reduce' onClick={() => reduceTime()}>
                      <IonIcon slot='icon-only' className='icon-Only' icon={chevronDown} style={{ color: "white" }} />
                    </div>
                  </div>
                </div>

              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="scenario-Info-Card" id="scenario-Planner-Section-2">
                  <IonCardTitle class="explanation-Card-Title">{scenarioStage} • {scenarioDay} • {scenarioTime} </IonCardTitle>
                </div>
              </div>
              <div className="data-Cards-Style-Attempt" id="scenario-Planner-Section-3" style={{ display: 'none' }}>
                <div className="data-Card-Style">
                  <IonCardTitle class="explanation-Card-Title">Number of Properties Affected</IonCardTitle>
                  <IonCardContent class="block-Letter-Text-Smaller">
                    {propertyNumbersScenario}
                  </IonCardContent>
                </div>
                <div className="data-Card-Style">
                  <IonCardTitle class="explanation-Card-Title">Priority Customers Affected</IonCardTitle>
                  <IonCardContent class="block-Letter-Text-Smaller">
                    {customerNumbersScenario}
                  </IonCardContent>
                </div>
              </div>
            </div>
          </div>
          <div className='scenario-Planner-Style' id='scenario-Planner-Style-2'>
            <div className="horizontal_dotted_line" id='horizontal-dotted-line-2' ></div>
            <span>Notes Assigned To This LRD  <IonButton className='scenario-Planner-Buttons' id='refresh-Note-Button' onClick={() => refreshNotes()}>Refresh</IonButton></span>
            <div>
              <IonButton className='scenario-Planner-Buttons' id='add-Note-Button' onClick={() => handleClick()}>Add Note</IonButton>
            </div>
          </div>

          <div id='note-Container' className='note-Container'>

            <IonCard className='note' id='note' style={{ height: '72px' }}>

              <div>
                <IonCard className='note-Header'>
                  <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                    <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                    <span>{resiliencePartner}</span>
                    <IonIcon slot='icon-only' id='chevronDownCircle' onClick={() => ExtendNotes(0)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
              </div>


              <IonCard className='note' id='note-Public' style={{ height: '72px' }}>

                <div>
                  <IonCard className='note-Header'>
                    <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                      <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                      <span>Public</span>
                      <IonIcon slot='icon-only' id='chevronDownCircle-Public' onClick={() => publicNotesExtend(0)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                </div>


                {

                  publicUserNotes.map(function (note: any) {
                    let noteId: any;
                    let cardId: any;
                    let contentId: any;
                    let showButtonId: any;
                    let deleteButtonId: any;
                    let editButtonId: any;
                    let buttonNumber: any;
                    let noteContent: any;
                    noteId = "RP-Note-" + note.index;
                    cardId = "Card-" + note.index;
                    contentId = "Content-" + note.index;
                    showButtonId = "Show-Button-" + note.index;
                    deleteButtonId = "Delete-Button-" + note.index;
                    editButtonId = "Edit-Button-" + note.index;
                    buttonNumber = note.index;
                    if (note.note_Content.length > 122) {
                      noteContent = note.note_Content.substring(0, 122);
                      noteContent = noteContent + "...";
                    } else {
                      noteContent = note.note_Content;
                    }
                    return <div id={noteId}>
                      <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteUser(buttonNumber)}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '375px' }}>
                          <IonCardContent className='note-Content-Text' id={contentId}>
                            {renderNoteContent(noteContent)}
                          </IonCardContent>

                          {/* <IonCardContent>
                          {renderNoteype(note)}
                        </IonCardContent> */}

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                          <div className='note-Delete-Edit-Button-Placement'>
                            <IonButton className='note-Button-Delete' id={deleteButtonId} shape="round" onClick={() => deletePublicNote(buttonNumber)} >Delete</IonButton>
                            <IonButton className='note-Button-Edit' id={editButtonId} shape="round" onClick={() => editPublicNote(buttonNumber)} >Edit</IonButton>
                            <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => showPublicUserNote(buttonNumber)} >View</IonButton>
                          </div>
                          <IonCardContent className='note-Content-Time'>
                            {renderNoteCreation(note)}
                          </IonCardContent>
                        </div>
                      </IonCard>
                    </div>
                    function renderNoteContent(noteContent: any) {
                      return <div>{noteContent}</div>;
                    }
                    function renderNoteCreation(noteContent: any) {
                      return <div>{noteContent.note_Creation}</div>;
                    }
                  })
                }

              </IonCard>

              <IonCard className='note' id='note-Partners' style={{ height: '72px' }}>

                <div>
                  <IonCard className='note-Header'>
                    <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                      <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                      <span>Partners</span>
                      <IonIcon slot='icon-only' id='chevronDownCircle-Partners' onClick={() => partnerNotesExtend(0)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                </div>


                {
                  partnersUserNotes.map(function (note: any) {

                    let noteId: any;
                    let cardId: any;
                    let contentId: any;
                    let showButtonId: any;
                    let deleteButtonId: any;
                    let editButtonId: any;
                    let buttonNumber: any;
                    let noteContent: any;
                    noteId = "RP-Note-" + note.index;
                    cardId = "Card-" + note.index;
                    contentId = "Content-" + note.index;
                    showButtonId = "Show-Button-" + note.index;
                    deleteButtonId = "Delete-Button-" + note.index;
                    editButtonId = "Edit-Button-" + note.index;
                    buttonNumber = note.index;
                    if (note.note_Content.length > 122) {
                      noteContent = note.note_Content.substring(0, 122);
                      noteContent = noteContent + "...";
                    } else {
                      noteContent = note.note_Content;
                    }
                    return <div id={noteId}>
                      <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteUser(buttonNumber)}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '375px' }}>
                          <IonCardContent className='note-Content-Text' id={contentId}>
                            {renderNoteContent(noteContent)}
                          </IonCardContent>

                          {/* <IonCardContent>
            {renderNoteype(note)}
          </IonCardContent> */}

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                          <div className='note-Delete-Edit-Button-Placement'>
                            <IonButton className='note-Button-Delete' id={deleteButtonId} shape="round" onClick={() => deletePartnerNote(buttonNumber)} >Delete</IonButton>
                            <IonButton className='note-Button-Edit' id={editButtonId} shape="round" onClick={() => editPartnersNote(buttonNumber)} >Edit</IonButton>
                            <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => showPartnerUserNote(buttonNumber)} >View</IonButton>
                          </div>
                          <IonCardContent className='note-Content-Time'>
                            {renderNoteCreation(note)}
                          </IonCardContent>
                        </div>
                      </IonCard>
                    </div>
                    function renderNoteContent(noteContent: any) {
                      return <div>{noteContent}</div>;
                    }
                    function renderNoteCreation(noteContent: any) {
                      return <div>{noteContent.note_Creation}</div>;
                    }
                  })
                }

              </IonCard>
              <IonCard className='note' id='note-SSEN' style={{ height: '72px' }}>

                <div>
                  <IonCard className='note-Header'>
                    <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                      <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                      <span>SSEN</span>
                      <IonIcon slot='icon-only' id='chevronDownCircle-SSEN' onClick={() => SSENNotesExtend(0)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                </div>


                {

                  SSENUserNotes.map(function (note: any) {
                    let noteId: any;
                    let cardId: any;
                    let contentId: any;
                    let showButtonId: any;
                    let deleteButtonId: any;
                    let editButtonId: any;
                    let buttonNumber: any;
                    let noteContent: any;
                    noteId = "RP-Note-" + note.index;
                    cardId = "Card-" + note.index;
                    contentId = "Content-" + note.index;
                    showButtonId = "Show-Button-" + note.index;
                    deleteButtonId = "Delete-Button-" + note.index;
                    editButtonId = "Edit-Button-" + note.index;
                    buttonNumber = note.index;
                    if (note.note_Content.length > 122) {
                      noteContent = note.note_Content.substring(0, 122);
                      noteContent = noteContent + "...";
                    } else {
                      noteContent = note.note_Content;
                    }
                    return <div id={noteId}>
                      <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteUser(buttonNumber)}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '375px' }}>
                          <IonCardContent className='note-Content-Text' id={contentId}>
                            {renderNoteContent(noteContent)}
                          </IonCardContent>

                          {/* <IonCardContent>
                          {renderNoteype(note)}
                        </IonCardContent> */}

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                          <div className='note-Delete-Edit-Button-Placement'>
                            <IonButton className='note-Button-Delete' id={deleteButtonId} shape="round" onClick={() => deleteSSENNote(buttonNumber)} >Delete</IonButton>
                            <IonButton className='note-Button-Edit' id={editButtonId} shape="round" onClick={() => editSSENNote(buttonNumber)} >Edit</IonButton>
                            <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => showSSENUserNote(buttonNumber)} >View</IonButton>
                          </div>
                          <IonCardContent className='note-Content-Time'>
                            {renderNoteCreation(note)}
                          </IonCardContent>
                        </div>
                      </IonCard>
                    </div>
                    function renderNoteContent(noteContent: any) {
                      return <div>{noteContent}</div>;
                    }
                    function renderNoteCreation(noteContent: any) {
                      return <div>{noteContent.note_Creation}</div>;
                    }
                  })
                }

              </IonCard>

            </IonCard>

            {
              organisationArray.map(function (organisation: any, index: number) {
                index++;

                let id = "note-Non-User-" + index
                let iconID = "chevronDownCircle" + index

                let Pub_id = "note-Non-User-Public-" + index
                let pub_iconID = "chevronDownCircle-Public-" + index

                let Partners_id = "note-Non-User-Partners-" + index
                let Partners_iconID = "chevronDownCircle-Partners-" + index

                let SSEN_id = "note-Non-User-SSEN-" + index
                let SSEN_iconID = "chevronDownCircle-SSEN-" + index

                return <IonCard className='note' id={id} style={{ height: '72px' }}>

                  <div>
                    <IonCard className='note-Header'>
                      <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                        <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                        <span>{organisation}</span>
                        <IonIcon slot='icon-only' id={iconID} onClick={() => ExtendNotes(index)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                  </div>

                  <IonCard className='note' id={Pub_id} style={{ height: '72px' }}>

                    <div>
                      <IonCard className='note-Header'>
                        <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                          <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                          <span>Public</span>
                          <IonIcon slot='icon-only' id={pub_iconID} onClick={() => publicNotesExtend(index)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                    </div>

                    {
                      publicNonUserNotes.map(function (note: any) {
                        if (note.note_Organisation === organisation) {
                          let noteId: any;
                          let cardId: any;
                          let showButtonId: any;
                          let buttonNumber: any;
                          let noteContent: any;
                          noteId = "RP-Note-NonUser-" + note.index;
                          cardId = "Card-NonUser-" + note.index;
                          showButtonId = "Button-Show-NonUser-" + note.index;
                          buttonNumber = note.index
                          if (note.note_Content.length > 82) {
                            noteContent = note.note_Content.substring(0, 82);
                            noteContent = noteContent + "...";
                          } else {
                            noteContent = note.note_Content;
                          }
                          return <div id={noteId}>
                            <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteNonUser(buttonNumber)}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <IonCardContent className='note-Content-Text'>
                                  {renderNoteContent(noteContent)}
                                </IonCardContent>

                                {/* <IonCardContent>
                                {renderNoteype(note)}
                              </IonCardContent> */}
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => showPublicNoteNonUser(buttonNumber)} >View</IonButton>
                                <IonCardContent className='note-Content-Time-NonUser'>
                                  {renderNoteCreation(note)}
                                </IonCardContent>
                              </div>
                            </IonCard>
                          </div>
                        }
                        function renderNoteContent(noteContent: any) {
                          return <div>{noteContent}</div>;
                        }
                        function renderNoteCreation(noteContent: any) {
                          return <div>{noteContent.note_Creation}</div>;
                        }
                      })
                    }


                  </IonCard>
                  <IonCard className='note' id={Partners_id} style={{ height: '72px' }}>

                    <div>
                      <IonCard className='note-Header'>
                        <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                          <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                          <span>Partner</span>
                          <IonIcon slot='icon-only' id={Partners_iconID} onClick={() => partnerNotesExtend(index)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                    </div>

                    {
                      partnersNonUserNotes.map(function (note: any) {
                        if (note.note_Organisation == organisation) {
                          let noteId: any;
                          let cardId: any;
                          let showButtonId: any;
                          let buttonNumber: any;
                          let noteContent: any;
                          noteId = "RP-Note-NonUser-" + note.index;
                          cardId = "Card-NonUser-" + note.index;
                          showButtonId = "Button-Show-NonUser-" + note.index;
                          buttonNumber = note.index
                          if (note.note_Content.length > 82) {
                            noteContent = note.note_Content.substring(0, 82);
                            noteContent = noteContent + "...";
                          } else {
                            noteContent = note.note_Content;
                          }
                          return <div id={noteId}>
                            <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteNonUser(buttonNumber)}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <IonCardContent className='note-Content-Text'>
                                  {renderNoteContent(noteContent)}
                                </IonCardContent>

                                {/* <IonCardContent>
                                {renderNoteype(note)}
                              </IonCardContent> */}
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => showPartnerNoteNonUser(buttonNumber)} >View</IonButton>
                                <IonCardContent className='note-Content-Time-NonUser'>
                                  {renderNoteCreation(note)}
                                </IonCardContent>
                              </div>
                            </IonCard>
                          </div>
                        }
                        function renderNoteContent(noteContent: any) {
                          return <div>{noteContent}</div>;
                        }
                        function renderNoteCreation(noteContent: any) {
                          return <div>{noteContent.note_Creation}</div>;
                        }
                      })
                    }


                  </IonCard>

                  <IonCard className='note' id={SSEN_id} style={{ height: '72px' }}>

                    <div>
                      <IonCard className='note-Header'>
                        <h1 className='note-Header-Text' style={{ display: 'flex' }}>
                          <IonIcon slot='icon-only' className='icon-Only' icon={personCircleOutline} style={{ color: "white" }} />
                          <span>SSEN</span>
                          <IonIcon slot='icon-only' id={SSEN_iconID} onClick={() => SSENNotesExtend(index)} className='icon-Only' icon={chevronDownCircle} /></h1></IonCard>
                    </div>

                    {
                      SSENNonUserNotes.map(function (note: any) {
                        if (note.note_Organisation == organisation) {
                          let noteId: any;
                          let cardId: any;
                          let showButtonId: any;
                          let buttonNumber: any;
                          let noteContent: any;
                          noteId = "RP-Note-NonUser-" + note.index;
                          cardId = "Card-NonUser-" + note.index;
                          showButtonId = "Button-Show-NonUser-" + note.index;
                          buttonNumber = note.index
                          if (note.note_Content.length > 82) {
                            noteContent = note.note_Content.substring(0, 82);
                            noteContent = noteContent + "...";
                          } else {
                            noteContent = note.note_Content;
                          }
                          return <div id={noteId}>
                            <IonCard id={cardId} className='note-Card' onClick={() => HighlightNoteNonUser(buttonNumber)}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <IonCardContent className='note-Content-Text'>
                                  {renderNoteContent(noteContent)}
                                </IonCardContent>

                                {/* <IonCardContent>
                                  {renderNoteype(note)}
                                </IonCardContent> */}
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <IonButton className='note-Button-Show' id={showButtonId} shape="round" onClick={() => showSSENNoteNonUser(buttonNumber)} >View</IonButton>
                                <IonCardContent className='note-Content-Time-NonUser'>
                                  {renderNoteCreation(note)}
                                </IonCardContent>
                              </div>
                            </IonCard>
                          </div>
                        }
                        function renderNoteContent(noteContent: any) {
                          return <div>{noteContent}</div>;
                        }
                        function renderNoteCreation(noteContent: any) {
                          return <div>{noteContent.note_Creation}</div>;
                        }
                      })
                    }


                  </IonCard>

                </IonCard>
              })
            }
          </div>
        </IonContent>
        <div className='menu-Toggle' onClick={() => setSidebarExpanded(sidebarExpanded === SidebarState.EXPANDED ? SidebarState.COLLAPSED : SidebarState.EXPANDED)}>
          <IonIcon src={chevronForwardCircle} className={sidebarExpanded === SidebarState.EXPANDED ? "" : "rotate180deg"}></IonIcon>
        </div>

        <div id='modal-Container' className='modal-Container' style={{ display: "none" }}>
          <div id="modal" className="modal-Create" style={{ display: "none" }}>
            <div className="modal_content">
              <div className='note-Input'>
                <IonCard className='add-Note-Style'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Create Note</h1></IonCard>
                <div className='note-Layout-Input'>
                  <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Area: {addNoteAllLocation}</IonCardContent></IonCard>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Note Coordinates: {XCoordConfirmed.valueOf().toFixed(3)}, {YCoordConfirmed.valueOf().toFixed(3)} </IonCardContent></IonCard>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* <div className="add-Note-Dropdown-Style " id='dropdown-Card-Style'>
                      <IonSelect interface="popover" placeholder="Miscellaneous" onIonChange={(e: any) => setNoteType(e.detail.value)}>
                        <IonSelectOption value="Miscellaneous">Miscellaneous</IonSelectOption>
                        <IonSelectOption value="Care Home">Care Home</IonSelectOption>
                        <IonSelectOption value="Hospital">Hospital</IonSelectOption>
                        <IonSelectOption value="Power Generation">Power Generation</IonSelectOption>
                        <IonSelectOption value="Prison">Prison</IonSelectOption>
                        <IonSelectOption value="Retirement Facility">Retirement Facility</IonSelectOption>
                        <IonSelectOption value="School">School</IonSelectOption>
                        <IonSelectOption value="Super Market">Super Market</IonSelectOption>
                      </IonSelect>
                    </div> */}
                    <IonCard className='note-Card-Style-Small'>
                      <IonCardContent className='note-Card-Content'>Resilience Partner: {resiliencePartner}</IonCardContent>
                    </IonCard>


                    <div className='note-card-btn-container' id="note-card-btn-container">
                      {/* <span className="tooltiptext">Expiry Date/Time</span> */}

                      <IonCard className='note-Card-Style-Small-Datetime-Button' id="datetime-Button">

                        <IonCardContent className='note-Card-Content'>
                          <label>Expiry Date/Time:</label>

                          <IonDatetimeButton datetime="datetime" >
                          </IonDatetimeButton>

                        </IonCardContent>
                      </IonCard>
                    </div>



                    <IonModal keepContentsMounted={true} style={{ "--border-radius": '25px' }}>
                      <IonDatetime showDefaultButtons={true} id="datetime" color={"light"} style={{ "--background": 'var(--Brand-Colour-Primary)' }} onIonChange={e => setNoteExpiryDate(e.target.value)} ></IonDatetime>
                    </IonModal>

                    <IonCard className='note-Card-Style-Small' id='link-to-Fault-Reference' style={{ display: 'none' }}  >
                      <IonCardContent className='note-Card-Content'>
                        <IonSelect id="reference-Select-ID" onIonChange={(e: any) => setFaultReferenceToBeSaved(e.detail.value)} interface="popover" placeholder="Please select an fault reference">
                          {
                            faultArrayForSave.map(function (faults: any) {
                              return <IonSelectOption value={faults}>{faults}</IonSelectOption>
                            })
                          }
                        </IonSelect>
                      </IonCardContent>
                    </IonCard>

                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                      <IonLabel style={{ fontFamily: 'SSENBold', fontSize: '0.95rem' }}>Link to Fault</IonLabel>
                      <IonCheckbox id="fault-Checkbox" onIonChange={(e: any) => setLinkedToFault(e.detail.checked)}></IonCheckbox>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                      <IonLabel style={{ fontFamily: 'SSENBold', fontSize: '0.95rem' }}>Make Permanent</IonLabel>
                      <IonCheckbox id="perm-Checkbox" onIonChange={(e: any) => setPermNote(e.detail.checked)}></IonCheckbox>
                    </div>

                  </div>
                  <IonTextarea placeholder="Enter note here..." required={true} autoGrow={true} className='note-Input-Line' id='note-Input-Line'></ IonTextarea>
                  <div className='note-Button-Placement'>
                    <IonButton className='note-Button-Red' shape="round" onClick={() => handleClick()}>Cancel</IonButton>
                    <IonButton className='note-Button-Green' shape="round" onClick={() => saveNote()}>Publish</IonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id='modal-Container' className='modal-Container' style={{ display: "none" }}>

          </div>
          <div id="modal-Edit" className="modal-Edit" style={{ display: "none" }}>
            <div className="modal_content">
              <div className='note-Input'>
                <IonCard className='add-Note-Style'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Edit This Note</h1></IonCard>
                <div className='note-Layout-Input'>
                  <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Area: {deleteDistrictReference}</IonCardContent></IonCard>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Note Coordinates: {deleteLocationReferenceX}, {deleteLocationReferenceY} </IonCardContent></IonCard>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Partner: {resiliencePartner}</IonCardContent></IonCard>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Created:  {deleteDateReference}</IonCardContent></IonCard>
                  </div>

                  {/* <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small' style={{ width: '100%' }}><IonCardContent className='note-Card-Content'>Note Type: {} </IonCardContent></IonCard>
                  </div>  */}

                  <IonTextarea placeholder="Enter text" autoGrow={true} className='note-Input-Line' id='note-Edit-Line' readonly={false}>
                  </ IonTextarea>
                  <div className='note-Button-Placement'>
                    <IonButton className='note-Button-Red' shape="round" onClick={() => editPublicNote(0)}>Cancel</IonButton>
                    <IonButton className='note-Button-Green' shape="round" onClick={() => saveEditNote()}>Publish</IonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="modal-Delete" className="modal-Delete" style={{ display: "none" }}>
            <div className="modal_content">
              <div className='note-Input'>
                <IonCard className='add-Note-Style'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Delete This Note</h1></IonCard>
                <div className='note-Layout-Input'>
                  <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Area:  {deleteDistrictReference}</IonCardContent></IonCard>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Note Coordinates: {deleteLocationReferenceX}, {deleteLocationReferenceY} </IonCardContent></IonCard>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Partner: {resiliencePartner}</IonCardContent></IonCard>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Created:  {deleteDateReference}</IonCardContent></IonCard>
                  </div>
                  {/* <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small' style={{ width: '100%' }}><IonCardContent className='note-Card-Content'>Note Type: {} </IonCardContent></IonCard>
                  </div>  */}

                  <IonTextarea placeholder="Enter text" className='note-Input-Line' id='note-Delete-Line' autoGrow={true} readonly={true}>
                  </ IonTextarea>
                  <div className='note-Button-Placement'>
                    <IonButton className='note-Button-Red' shape="round" onClick={() => deletePublicNote(0)} >Cancel</IonButton>
                    <IonButton className='note-Button-Green' shape="round" onClick={() => deleteNoteClick()} >Delete</IonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="modal-Show" className="modal-Show" style={{ display: "none" }}>
            <div className="modal_content">
              <div className='note-Input'>
                <IonCard className='add-Note-Style'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  View This Note</h1></IonCard>
                <div className='note-Layout-Input'>
                  <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Area: {deleteDistrictReference}</IonCardContent></IonCard>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Note Coordinates: {deleteLocationReferenceX}, {deleteLocationReferenceY} </IonCardContent></IonCard>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Partner: {resiliencePartner}</IonCardContent></IonCard>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Created:  {deleteDateReference}</IonCardContent></IonCard>
                  </div>

                  {/* <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small' style={{ width: '100%' }}><IonCardContent className='note-Card-Content'>Note Type: {} </IonCardContent></IonCard>
                  </div>  */}

                  <IonTextarea placeholder="Enter text" autoGrow={true} className='note-Input-Line' id='note-Show-Line' readonly={true}>
                  </ IonTextarea>
                  <div className='note-Button-Placement'>
                    <IonButton className='note-Button-Red' shape="round" onClick={() => showPublicUserNote(0)}>Close</IonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="modal-Show-NonUser" className="modal-Show" style={{ display: "none" }}>
            <div className="modal_content">
              <div className='note-Input'>
                <IonCard className='add-Note-Style'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={create} style={{ color: "white" }} />  Show This Note</h1></IonCard>
                <div className='note-Layout-Input'>
                  <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Area: {deleteDistrictReference}</IonCardContent></IonCard>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Note Coordinates: {deleteLocationReferenceX}, {deleteLocationReferenceY} </IonCardContent></IonCard>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Resilience Partner: {noteOrg}</IonCardContent></IonCard>
                    <IonCard className='note-Card-Style-Small'><IonCardContent className='note-Card-Content'>Created:  {deleteDateReference}</IonCardContent></IonCard>
                  </div>

                  {/* <div style={{ display: 'flex' }}>
                    <IonCard className='note-Card-Style-Small' style={{ width: '100%' }}><IonCardContent className='note-Card-Content'>Note Type: {} </IonCardContent></IonCard>
                  </div>  */}

                  <IonTextarea placeholder="Enter text" autoGrow={true} className='note-Input-Line' id='note-Show-Line-NonUser' readonly={true}>
                  </ IonTextarea>
                  <div className='note-Button-Placement'>
                    <IonButton className='note-Button-Red' shape="round" onClick={() => showPublicNoteNonUser(0)}>Close</IonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="modal-Scenario" className="modal-Scenario" style={{ display: "none" }}>
            <div className="modal_content">
              <div className='note-Input'>
                <IonCard className='scenario-Style'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={timeOutline} style={{ color: "white" }} />  Scenario Planner</h1></IonCard>
                <div className='note-Layout-Input'>
                  <div className="data-Cards-Style-Attempt" id="data-Cards-Style-Attempt" >
                    <div className='scenario-Planner-Selection-Style-Stage'>
                      <IonSelect id='stage-Selection' interface="popover" placeholder="Level" value={scenarioStageView} onIonChange={(e: any) => setScenarioStageView(e.detail.value)} >
                        <IonSelectOption value="Level 1">Level 1</IonSelectOption>
                        <IonSelectOption value="Level 2">Level 2</IonSelectOption>
                        <IonSelectOption value="Level 3">Level 3</IonSelectOption>
                        <IonSelectOption value="Level 4">Level 4</IonSelectOption>
                        <IonSelectOption value="Level 5">Level 5</IonSelectOption>
                        <IonSelectOption value="Level 6">Level 6</IonSelectOption>
                        <IonSelectOption value="Level 7">Level 7</IonSelectOption>
                        <IonSelectOption value="Level 8">Level 8</IonSelectOption>
                        <IonSelectOption value="Level 9">Level 9</IonSelectOption>
                        <IonSelectOption value="Level 10">Level 10</IonSelectOption>
                        <IonSelectOption value="Level 11">Level 11</IonSelectOption>
                        <IonSelectOption value="Level 12">Level 12</IonSelectOption>
                        <IonSelectOption value="Level 13">Level 13</IonSelectOption>
                        <IonSelectOption value="Level 14">Level 14</IonSelectOption>
                        <IonSelectOption value="Level 15">Level 15</IonSelectOption>
                        <IonSelectOption value="Level 16">Level 16</IonSelectOption>
                        <IonSelectOption value="Level 17">Level 17</IonSelectOption>
                        <IonSelectOption value="Level 18">Level 18</IonSelectOption>
                      </IonSelect>
                    </div>
                    <div className='scenario-Planner-Selection-Style-Stage'>
                      <IonSelect id='day-Selection' interface="popover" placeholder="Day" value={scenarioDayView} onIonChange={(e: any) => setScenarioDayView(e.detail.value)} >
                        <IonSelectOption value="Monday">Monday</IonSelectOption>
                        <IonSelectOption value="Tuesday">Tuesday</IonSelectOption>
                        <IonSelectOption value="Wednesday">Wednesday</IonSelectOption>
                        <IonSelectOption value="Thursday">Thursday</IonSelectOption>
                        <IonSelectOption value="Friday">Friday</IonSelectOption>
                        <IonSelectOption value="Saturday">Saturday</IonSelectOption>
                        <IonSelectOption value="Sunday">Sunday</IonSelectOption>
                      </IonSelect>
                    </div>
                    <div className='scenario-Planner-Selection-Style-Stage'>
                      <IonSelect id='time-Selection' interface="popover" placeholder="Time" value={scenarioTimeView} onIonChange={(e: any) => setScenarioTimeView(e.detail.value)} >
                        <IonSelectOption value="00:30 - 03:30">00:30 - 03:30</IonSelectOption>
                        <IonSelectOption value="03:30 - 06:30">03:30 - 06:30</IonSelectOption>
                        <IonSelectOption value="06:30 - 09:30">06:30 - 09:30</IonSelectOption>
                        <IonSelectOption value="09:30 - 12:30">09:30 - 12:30</IonSelectOption>
                        <IonSelectOption value="12:30 - 15:30">12:30 - 15:30</IonSelectOption>
                        <IonSelectOption value="15:30 - 18:30">15:30 - 18:30</IonSelectOption>
                        <IonSelectOption value="18:30 - 21:30">18:30 - 21:30</IonSelectOption>
                        <IonSelectOption value="21:30 - 00:30">21:30 - 00:30</IonSelectOption>
                      </IonSelect>
                    </div>
                  </div>
                  <div className='scenario-Buttons-Style'>
                    <IonButton className='note-Button-Red' shape="round" onClick={() => scenarioPlanner()} >Cancel</IonButton>
                    <IonButton className='note-Button-Green' shape="round" onClick={() => scenarioPlanChosen()} >View Scenario</IonButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="modal-OutOfBounds" className="modal-OutOfBounds" style={{ display: "none" }}> {/* Need to improve how this looks, is functional but looks a bit scruffy, maybe also move where the modal pops up? */}
            <div className="modal_content">
              <div className='note-Input'>
                <IonCard className='Out_Of_Bounds_Card'><h1 className='modal-Header'> <IonIcon slot='icon-only' className='icon-Only' icon={closeCircleOutline} style={{ color: "red" }} /> Out Of Bounds</h1></IonCard>

              </div>
              <div className='note-Delete-Edit-Button-Placement' style={{ justifyContent: 'center' }}>
                <IonButton className='note-Button-Understood' shape="round" onClick={() => OutOfBoundsClick()}>Understood</IonButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
